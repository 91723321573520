<template>
  <div
      class="w-full"
      style="
      padding: 16px 16px;
      height: calc(100vh - 100px);
      background-color: rgb(0, 10, 50);
      overflow-y: scroll;
      padding-bottom: 30px;
      background-color: #000a32;
    "
  >
    <div class="w-full h-full flex">
      <!-- 左侧设备列表 -->
      <div style="min-width: 250px; margin-top: 31px">
        <div style="width: 250px; position: fixed" :style="{ top: scrollTop }">
          <machineListLeftComponent @selectMachine="selectMachine" :type="1">
          </machineListLeftComponent>
        </div>
      </div>

      <!-- 右侧内容 -->
      <div class="flex-1 flex ml-4" style="color: #fff">
        <!-- 设备内容 -->
        <div class="flex-1">
          <div class="w-full flex items-center">
            <div class="size20">{{ machineInfo?.machineName }}</div>
            <poweroff-outlined
                class="ml-2"
                :style="{ 'background-color': machineInfo?.status?.color }"
                style="border-radius: 50%; padding: 2px; font-size: 14px"
            />
            <div
                :style="{ 'background-color': machineInfo?.status?.color }"
                style="
                font-size: 12px;
                height: 13px;
                padding: 0 2px;
                transform: translateX(-2.5px);
                border-radius: 2px;
              "
            >
              <div style="transform: translateY(-3px)">
                {{ langObj[machineInfo?.status?.label] }}
              </div>
            </div>

            <smile-outlined
                class="ml-2"
                :style="{ 'background-color': machineInfo?.condition?.color }"
                style="border-radius: 50%; padding: 2px; font-size: 14px"
            />
            <div
                :style="{ 'background-color': machineInfo?.condition?.color }"
                style="
                font-size: 12px;
                height: 13px;
                padding: 0 2px;
                transform: translateX(-2.5px);
                border-radius: 2px;
              "
            >
              <div style="transform: translateY(-3px)">
                {{ langObj[machineInfo?.condition?.label] }}
              </div>
            </div>

            <div class="flex-1"></div>
            <div
                v-if="
                machineInfo &&
                machineInfo.workStatus &&
                machineInfo.workStatus.onLine
              "
            >
              {{ langObj["累计运行"] }} {{ machineInfo.workStatus.onLine }}小时
            </div>
          </div>

          <div class="w-full flex">
            <!-- 雷达图 -->
            <div class="flex-1 content">
              <div class="w-full flex items-center">
                <div class="flex-1"></div>
                <div
                    @click="conditionChange(true)"
                    class="btn1"
                    :class="{ 'btn1-select': changeCondition }"
                >
                  {{ langObj["综合状态"] }}
                </div>
                <div
                    @click="conditionChange(false)"
                    class="ml-4 btn1"
                    :class="{ 'btn1-select': !changeCondition }"
                >
                  {{ langObj["当前状态"] }}
                </div>
                <div class="flex-1"></div>
              </div>

              <div class="w-full flex items-center">
                <div style="font-size: 22px">
                  {{ langObj["设备健康状态"] }}：<span
                    :style="{
                      color: changeCondition
                        ? machineInfo?.condition?.color
                        : machineInfo?.conditionC?.color,
                    }"
                >{{
                    changeCondition
                        ? langObj[machineInfo?.condition?.label]
                        : langObj[machineInfo?.conditionC?.label]
                  }}</span
                >
                </div>
                <div class="flex-1"></div>
                <a-select
                    ref="select"
                    v-model:value="selectSensorRandar"
                    style="min-width: 150px"
                    @change="selectRandarSensor"
                >
                  <a-select-option
                      v-for="(item, index) in sensorListAll"
                      :key="index"
                      :value="item.id"
                  >
                    {{ item.sensorPlace }}
                  </a-select-option>
                </a-select>
              </div>

              <div class="w-full flex items-center">
                <div class="flex-1"></div>
                <div v-show="isShowHealthRandar">
                  <div
                      id="chartAover"
                      style="width: 342px; height: 322px"
                  ></div>
                  <div
                      class="opt8 w-full text-center size12"
                      v-if="
                      machineInfo &&
                      machineInfo.workStatus &&
                      machineInfo.workStatus.loseSensorExpert
                    "
                  >
                    {{ langObj["有测点数据未传回，因此本条分数数据仅供参考"] }}
                  </div>
                  <div class="w-full text-center">
                    {{ langObj["健康指标"] }}
                  </div>
                </div>
                <div class="flex-1" v-show="isShowHealthRandar"></div>
                <div>
                  <div
                      id="chartBover"
                      style="width: 342px; height: 322px"
                  ></div>
                  <div
                      class="opt8 w-full text-center size12"
                      v-if="
                      machineInfo &&
                      machineInfo.workStatus &&
                      machineInfo.workStatus.loseSensor
                    "
                  >
                    {{ langObj["有测点数据未传回，因此本条分数数据仅供参考"] }}
                  </div>
                  <div class="w-full text-center">
                    {{ langObj["振动指标"] }}
                  </div>
                </div>
                <div class="flex-1"></div>
              </div>
            </div>

            <!-- 设备信息 -->
            <div style="width: 325px" class="ml-4 content">
              <div class="w-full flex items-center">
                <div class="size20">{{ langObj["设备基本信息"] }}</div>
                <div class="flex-1"></div>
                <div
                    @click="machineMore"
                    class="flex items-center cursor-pointer opt8"
                >
                  <span>{{ langObj["查看更多"] }}</span>
                  <right-outlined class="ml-2"/>
                </div>
              </div>
              <div class="w-full">
                <pictureListComponent :type="2" :picList="picListAll">
                </pictureListComponent>
              </div>

              <div class="w-full flex items-center mt-2">
                <div class="flex-1">
                  <div class="opt6">{{ langObj["设备名称"] }}</div>
                  <div>{{ machineInfo.machineName }}</div>
                </div>
                <div class="flex-1">
                  <div class="opt6">{{ langObj["设备区域"] }}</div>
                  <div>{{ getGroupName(machineInfo.groupId) }}</div>
                </div>
              </div>

              <div class="w-full flex items-center mt-2">
                <div class="flex-1">
                  <div class="opt6">{{ langObj["设备位置"] }}</div>
                  <div>{{ machineInfo.position }}</div>
                </div>
                <div class="flex-1">
                  <div class="opt6">{{ langObj["设备型号"] }}</div>
                  <div>{{ machineInfo.model }}</div>
                </div>
              </div>

              <div class="w-full flex items-center mt-2">
                <div class="flex-1">
                  <div class="opt6">{{ langObj["设备厂家"] }}</div>
                  <div>{{ machineInfo.supplier }}</div>
                </div>
                <div class="flex-1">
                  <div class="opt6">{{ langObj["设备编号"] }}</div>
                  <div>{{ machineInfo.machineCode }}</div>
                </div>
              </div>

              <div class="w-full mt-2">
                <div class="opt6">{{ langObj["设备描述"] }}</div>
                <div>{{ machineInfo.remark }}</div>
              </div>
            </div>
          </div>

          <!-- 分数趋势 -->
          <div class="content mt-4">
            <div class="w-full flex items-center flex-wrap">
              <div class="size20">{{ langObj["智能诊断分数趋势"] }}</div>
              <div
                  class="flex items-center ml-4 cursor-pointer opt8"
                  style="font-size: 14px"
              >
                <div
                    @click="histrendSearchModel.isShow = false"
                    class="flex items-center"
                    v-if="histrendSearchModel.isShow"
                >
                  <div>{{ langObj["收起"] }}</div>
                  <caret-up-outlined class="ml-1"/>
                </div>

                <div
                    @click="histrendSearchModel.isShow = true"
                    class="flex items-center"
                    v-if="!histrendSearchModel.isShow"
                >
                  <div>{{ langObj["展开"] }}</div>
                  <caret-down-outlined class="ml-1"/>
                </div>
              </div>
              <div class="flex-1"></div>
              <div
                  class="flex items-center"
                  v-show="histrendSearchModel.isShow"
              >
                <a-switch
                    v-model:checked="fiveRefresh"
                    @change="refresh($event)"
                    :checked-children="langObj['开启']"
                    :un-checked-children="langObj['关闭']"
                />
                <span class="ml-1">{{ langObj["自动刷新"] }}</span>

                <div class="flex ml-2">
                  <div
                      class="btn2 px-3 py-1"
                      :class="{ 'text-white': histrendSearchModel.type == '1' }"
                      :style="{
                      background:
                        histrendSearchModel.type == '1' ? '#072498' : '',
                    }"
                      style="border-radius: 5px 0 0 5px"
                      @click="(histrendSearchModel.type = '1'), getHistrendData()"
                  >
                    {{ langObj["近一周"] }}
                  </div>
                  <div
                      class="btn2 px-3 py-1"
                      :class="{ 'text-white': histrendSearchModel.type == '2' }"
                      :style="{
                      background:
                        histrendSearchModel.type == '2' ? '#072498' : '',
                    }"
                      @click="(histrendSearchModel.type = '2'), getHistrendData()"
                  >
                    {{ langObj["近一月"] }}
                  </div>
                  <div
                      class="btn2 px-3 py-1"
                      :class="{ 'text-white': histrendSearchModel.type == '3' }"
                      :style="{
                      background:
                        histrendSearchModel.type == '3' ? '#072498' : '',
                    }"
                      @click="(histrendSearchModel.type = '3'), getHistrendData()"
                  >
                    {{ langObj["近两月"] }}
                  </div>
                  <div
                      class="btn2 px-3 py-1"
                      :style="{
                      background:
                        histrendSearchModel.type == '0' ? '#072498' : '',
                    }"
                      :class="{ 'text-white': histrendSearchModel.type == '0' }"
                      @click="histrendSearchModel.type = '0'"
                      style="border-radius: 0 5px 5px 0"
                  >
                    {{ langObj["自定义"] }}
                  </div>
                </div>

                <div class="ml-2" v-if="histrendSearchModel.type == '0'">
                  <a-range-picker
                      v-model:value="histrendSearchModel.date"
                      :disabled-date="disabledDate2"
                      @calendarChange="onCalendarChange"
                  />
                </div>

                <a-select
                    ref="select"
                    v-model:value="histrendSearchModel.sensorId"
                    style="min-width: 150px"
                    @change="getHistrendData"
                >
                  <a-select-option
                      v-for="(item, index) in sensorListAll"
                      :key="index"
                      :value="item.id"
                  >
                    {{ item.sensorPlace }}
                  </a-select-option>
                </a-select>

                <div @click="getHistrendData" class="btn3 ml-2">
                  {{ langObj["筛选"] }}
                </div>
                <div class="btn3 ml-2" @click="downLoad1">
                  {{ langObj["数据下载"] }}
                </div>
              </div>
            </div>

            <div class="w-full" v-show="histrendSearchModel.isShow">
              <div
                  class="mt-5 text-center size18"
                  style="padding-right: 120px"
                  v-if="timeLineList.length"
              >
                {{ langObj["设备健康事件时间轴"] }}
              </div>
              <div
                  class="w-full flex px-16 mt-8 sjz"
                  v-if="timeLineList.length"
              >
                <div
                    class="flex-1"
                    v-for="(item, index) in timeLineList"
                    :key="index"
                    :style="{ background: item.color }"
                    style="height: 20px; position: relative"
                >
                  <div
                      v-if="item.isShowDate"
                      style="
                      position: absolute;
                      left: -50%;
                      bottom: -17px;
                      font-size: 10px;
                      width: 53px;
                    "
                  >
                    {{ item.showDate }}
                  </div>

                  <div
                      v-if="item.order"
                      :title="item.date"
                      style="position: absolute; left: -50%; bottom: 23px"
                  >
                    <img
                        class="imgShow"
                        v-if="item.order.type === 1"
                        src="@/assets/zhenduan.png"
                    />
                    <img
                        class="imgShow"
                        v-if="item.order.type === 2 || item.order.type === 3"
                        src="@/assets/weixiu.png"
                    />
                    <img
                        class="imgShow"
                        v-if="item.order.type === 4"
                        src="@/assets/menxian.png"
                    />
                  </div>
                </div>
              </div>

              <div v-show="isShowHealthHistrend" class="mt-16 size18 flex items-center px-12">
                <div class="text-center flex-1">
                  {{ langObj["健康指标诊断分数趋势图"] }}
                </div>
                <a-switch
                    v-model:checked="isShowHealthRemark"
                    @change="initChartC()"
                    :checked-children="langObj['显示']"
                    :un-checked-children="langObj['隐藏']"
                />
                <div style="font-size: 14px">{{ langObj["开关机线"] }}</div>
              </div>
              <div v-show="isShowHealthHistrend" id="chartCover" style="height: 340px" class="w-full"></div>
              <div class="mt-5 text-center size18 flex items-center px-12">
                <div class="text-center flex-1">
                  {{ langObj["振动指标诊断分数趋势图"] }}
                </div>
                <a-switch
                    v-model:checked="isShowVibrationRemark"
                    @change="initChartD()"
                    :checked-children="langObj['显示']"
                    :un-checked-children="langObj['隐藏']"
                />
                <div style="font-size: 14px">{{ langObj["开关机线"] }}</div>
              </div>
              <div id="chartDover" style="height: 340px" class="w-full"></div>
            </div>
          </div>

          <!-- 运行时间趋势 -->
          <div class="content mt-4" v-if="isShowTimeLine">
            <div class="w-full flex items-center">
              <div class="size20">{{ langObj["运行时间趋势"] }}</div>
              <div
                  class="flex items-center ml-4 cursor-pointer opt8"
                  style="font-size: 14px"
              >
                <div
                    @click="statusSearchModel.isShow = false"
                    class="flex items-center"
                    v-if="statusSearchModel.isShow"
                >
                  <div>{{ langObj["收起"] }}</div>
                  <caret-up-outlined class="ml-1"/>
                </div>

                <div
                    @click="statusSearchModel.isShow = true"
                    class="flex items-center"
                    v-if="!statusSearchModel.isShow"
                >
                  <div>展开</div>
                  <caret-down-outlined class="ml-1"/>
                </div>
              </div>
              <div class="flex-1"></div>
              <div class="flex items-center" v-show="statusSearchModel.isShow">
                <div class="flex ml-2">
                  <div
                      class="btn2 px-3 py-1"
                      :class="{ 'text-white': statusSearchModel.type == '1' }"
                      :style="{
                      background:
                        statusSearchModel.type == '1' ? '#072498' : '',
                    }"
                      style="border-radius: 5px 0 0 5px"
                      @click="
                      (statusSearchModel.type = '1'), getMachineWKstatus()
                    "
                  >
                    {{ langObj["近一周"] }}
                  </div>
                  <div
                      class="btn2 px-3 py-1"
                      :class="{ 'text-white': statusSearchModel.type == '2' }"
                      :style="{
                      background:
                        statusSearchModel.type == '2' ? '#072498' : '',
                    }"
                      @click="
                      (statusSearchModel.type = '2'), getMachineWKstatus()
                    "
                  >
                    {{ langObj["近一月"] }}
                  </div>
                  <div
                      class="btn2 px-3 py-1"
                      :class="{ 'text-white': statusSearchModel.type == '3' }"
                      :style="{
                      background:
                        statusSearchModel.type == '3' ? '#072498' : '',
                    }"
                      @click="
                      (statusSearchModel.type = '3'), getMachineWKstatus()
                    "
                  >
                    {{ langObj["近两月"] }}
                  </div>
                  <div
                      class="btn2 px-3 py-1"
                      :style="{
                      background:
                        statusSearchModel.type == '0' ? '#072498' : '',
                    }"
                      :class="{ 'text-white': statusSearchModel.type == '0' }"
                      @click="statusSearchModel.type = '0'"
                      style="border-radius: 0 5px 5px 0"
                  >
                    {{ langObj["自定义"] }}
                  </div>
                </div>

                <div class="ml-2" v-if="statusSearchModel.type == '0'">
                  <a-range-picker v-model:value="statusSearchModel.date"/>
                </div>

                <div @click="getMachineWKstatus" class="btn3 ml-2">
                  {{ langObj["筛选"] }}
                </div>
              </div>
            </div>

            <div class="w-full" v-show="statusSearchModel.isShow">
              <div class="w-full h-96" id="chartStatus"></div>
            </div>
          </div>

          <!-- 指标监测趋势 -->
          <div class="content mt-4">
            <div class="w-full flex items-center">
              <div class="size20">{{ langObj["指标监测趋势"] }}</div>
              <div
                  class="flex items-center ml-4 cursor-pointer opt8"
                  style="font-size: 14px"
              >
                <div
                    @click="sensorSearchModel.isShow = false"
                    class="flex items-center"
                    v-if="sensorSearchModel.isShow"
                >
                  <div>{{ langObj["收起"] }}</div>
                  <caret-up-outlined class="ml-1"/>
                </div>

                <div
                    @click="sensorSearchModel.isShow = true"
                    class="flex items-center"
                    v-if="!sensorSearchModel.isShow"
                >
                  <div>{{ langObj["展开"] }}</div>
                  <caret-down-outlined class="ml-1"/>
                </div>
              </div>
              <div class="flex-1"></div>
              <div class="flex items-center" v-show="sensorSearchModel.isShow">
                <a-switch
                    v-model:checked="value1"
                    :checked-children="langObj['开启']"
                    :un-checked-children="langObj['关闭']"
                />
                <span class="ml-1">{{ langObj["自动刷新"] }}</span>

                <div class="flex ml-2">
                  <div
                      class="btn2 px-3 py-1"
                      :class="{ 'text-white': sensorSearchModel.type == '1' }"
                      :style="{
                      background:
                        sensorSearchModel.type == '1' ? '#072498' : '',
                    }"
                      style="border-radius: 5px 0 0 5px"
                      @click="
                      (sensorSearchModel.type = '1'), getSensorTrendData()
                    "
                  >
                    {{ langObj["近一天"] }}
                  </div>
                  <div
                      class="btn2 px-3 py-1"
                      :class="{ 'text-white': sensorSearchModel.type == '2' }"
                      :style="{
                      background:
                        sensorSearchModel.type == '2' ? '#072498' : '',
                    }"
                      @click="
                      (sensorSearchModel.type = '2'), getSensorTrendData()
                    "
                  >
                    {{ langObj["近一周"] }}
                  </div>
                  <div
                      class="btn2 px-3 py-1"
                      :class="{ 'text-white': sensorSearchModel.type == '3' }"
                      :style="{
                      background:
                        sensorSearchModel.type == '3' ? '#072498' : '',
                    }"
                      @click="
                      (sensorSearchModel.type = '3'), getSensorTrendData()
                    "
                  >
                    {{ langObj["近一月"] }}
                  </div>
                  <div
                      class="btn2 px-3 py-1"
                      :style="{
                      background:
                        sensorSearchModel.type == '0' ? '#072498' : '',
                    }"
                      :class="{ 'text-white': sensorSearchModel.type == '0' }"
                      @click="sensorSearchModel.type = '0'"
                      style="border-radius: 0 5px 5px 0"
                  >
                    {{ langObj["自定义"] }}
                  </div>
                </div>

                <div class="ml-2" v-if="sensorSearchModel.type == '0'">
                  <a-range-picker
                      v-model:value="sensorSearchModel.date"
                      :disabled-date="disabledDate"
                      @calendarChange="onCalendarChange"
                  />
                </div>

                <div @click="getSensorTrendData" class="btn3 ml-2">
                  {{ langObj["筛选"] }}
                </div>
              </div>
            </div>

            <div v-show="sensorSearchModel.isShow">
              <div v-for="(item, index) in sensorList" :key="index">
                <div class="mt-5 text-center size18">
                  {{ item.sensorPlace }}
                </div>
                <div class="w-full flex">
                  <div style="width: 260px">
                    <div class="w-full flex items-center">
                      <div>
                        <div>{{ langObj["测点指标值"] }}</div>
                        <div>{{ item.sensorDate }}</div>
                      </div>

                      <div class="flex-1"></div>

                      <!-- <div style="font-size: 14px" class="flex items-center">
                                                <caret-left-outlined />
                                                <span>收起</span>
                                            </div> -->
                    </div>

                    <div
                        class="content w-full"
                        style="padding: 10px 0px; background-color: #020f46"
                    >
                      <div class="pl-2">{{ item.sensorPlace }}</div>

                      <div
                          class="w-full flex items-center mt-3 pl-2"
                          style="opacity: 0.6"
                      >
                        <div style="width: 60%; text-align: center">
                          {{ langObj["指标名称"] }}
                        </div>
                        <div style="width: 40%; text-align: center">
                          {{ langObj["指标值"] }}
                        </div>
                      </div>
                      <div v-if="item.sensorData">
                        <div
                            style="padding: 5px 5px"
                            :class="[getRowClassName(index2)]"
                            class="w-full flex items-center"
                            v-for="(data, index2) in item.sensorData"
                            :key="index2"
                        >
                          <div style="width: 60%; text-align: center">
                            {{ data.name }}
                          </div>
                          <div style="width: 40%; text-align: center">
                            {{ data.value }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="flex-1">
                    <a-spin :spinning="sensorLoading">
                      <div
                          class="w-full h-96"
                          :id="'chartSensor' + index"
                      ></div>
                    </a-spin>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 设备工单 -->
        <div style="width: 310px; margin-top: 23px" class="ml-4">
          <OrderListComponent
              ref="orderC"
              :machineInfo="machineInfo"
          ></OrderListComponent>
        </div>
      </div>
    </div>

    <a-modal
        :width="1400"
        v-model:visible="downLoadVisible"
        :title="langObj['下载']"
        :maskClosable="false"
        :footer="false"
    >
      <DownLoadComponent
          @downLoadCancel="downLoadCancel"
          :type="1"
          :dataList="downLoadList"
      ></DownLoadComponent>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import {
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {
  transformDate,
  transformDate2,
  getGroupName,
  inIframe
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import OrderListComponent from "./orderList.vue";
import machineListLeftComponent from "../components/machine-list-left.vue";
import * as echarts from "echarts";
import { message } from "ant-design-vue";
import pictureListComponent from "../components/picture-list.vue";
import DownLoadComponent from "../components/downLoad.vue";
import { Dayjs } from "dayjs";
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";

const router = useRouter();
let langObj: any = ref({});
let language: any = ref("Chinese");
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
getLang();

let picList: any = ref([]);
let value = ref("");
let value1 = ref(false);
let machine_id = ref("");
let machineInfo: any = ref({});
let statusInfo: any = ref({});
let sensorList: any = ref([]);
let sensorListAll: any = ref([]);
let changeCondition = ref(true); //true 稳定   false 实时
let selectSensorRandar = ref("");
let orderC = ref();

let conditionList = [
  { label: "健康", value: [0], color: "#61c08f" },
  { label: "可用", value: [1, 2], color: "#d2de49" },
  { label: "警戒", value: [3, 4, 5], color: "#cd5f3b" },
  { label: "故障", value: [6, 7], color: "#921e37" },
];

let statusList = [
  { label: "停机", value: 0, color: "#ADADAD" },
  { label: "停机", value: false, color: "#ADADAD" },
  { label: "运行", value: 1, color: "#4EFAEE" },
  { label: "运行", value: true, color: "#4EFAEE" },
];

let scrollTop = ref("120px");
let isShowHealthRandar: any = ref(true)
let isShowHealthHistrend: any = ref(true)
let iframe = ref(false);
iframe.value = inIframe();
if (iframe.value) {
  scrollTop.value = '10px'
}

let isShowTimeLine: any = ref(false)
onMounted(() => {
  if (JSON.parse(localStorage.getItem("userName") || '"admin"') == 'freqx_yszh') {
    isShowTimeLine.value = false
  } else {
    isShowTimeLine.value = true
  }

})

const selectMachine = (ev: any) => {
  orderC.value && orderC.value.closeAll();
  histrendSearchModel.value.isShow = true;
  sensorSearchModel.value.isShow = true;
  selectSensorRandar.value = "";
  histrendSearchModel.value.sensorId = "";
  machine_id.value = ev;
  setTimeout(() => {
    init();
  }, 200);
};

const init = () => {
  myChartC && myChartC.clear();
  myChartD && myChartD.clear();
  histrendSearchModel.value = {
    type: 1,
    date: null,
    sensorId: "",
    isShow: true,
  };
  sensorSearchModel.value = {
    date: null,
    type: "1",
    isShow: true,
  };
  statusSearchModel.value = {
    date: null,
    type: "1",
    isShow: true,
  };

  picList.value = [];
  getMachineInfo();
};

const handleEvent = (e: any) => {
  if (e.target.localName == "body") {
    if (e.srcElement.scrollTop >= 120) {
      scrollTop.value = "5px";
    } else {
      scrollTop.value = "120px";
    }
  }
};

window.addEventListener("scroll", handleEvent, true);

onUnmounted(() => {
  getLang();
  window.removeEventListener("scroll", handleEvent, true);
});

// 获取设备详情
const getMachineInfo = async () => {
  let config: any = {
    params: {
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/info", config);

  if (result) {
    machineInfo.value = result.data;
    if (
        machineInfo.value &&
        machineInfo.value.workStatus &&
        machineInfo.value.workStatus.stableCondition
    ) {
      machineInfo.value.condition = conditionList.find((p: any) =>
          p.value.includes(machineInfo.value.workStatus.stableCondition)
      );
    } else {
      machineInfo.value.condition = conditionList[0];
    }

    if (
        machineInfo.value &&
        machineInfo.value.workStatus &&
        machineInfo.value.workStatus.scoreCondition
    ) {
      machineInfo.value.conditionC = conditionList.find((p: any) =>
          p.value.includes(machineInfo.value.workStatus.scoreCondition)
      );
    } else {
      machineInfo.value.conditionC = conditionList[0];
    }

    if (
        machineInfo.value &&
        machineInfo.value.workStatus) {
      console.log(machineInfo.value.workStatus.quotaWkStatus, "quotaStatus")
      console.log(machineInfo.value.workStatus.scoreWkStatus, "scoreStatus")
      machineInfo.value.status = statusList.find(
          (p: any) => p.value === (machineInfo.value.workStatus.quotaWkStatus ?? machineInfo.value.workStatus.scoreWkStatus ?? 0)
      );
    } else {
      machineInfo.value.status = statusList[0];
    }
  }
  if (machineInfo.value.pictures?.length > 0) {
    machineInfo.value.pictures.map((d: any) => {
      picList.value.push({
        name: "设备",
        url: d.url,
      });
    })
  }
  getSensorList();
  getFaultName();
  getMachineWKstatus();
};

// 获取测点列表\
let picListAll: any = ref([]);
const getSensorList = async () => {
  let senSearch = {
    params: { machineId: machine_id.value },
    headers: {
      requestId: uuidv4(),
    },
  };

  let senList = await request.get("/api/sensors", senSearch);
  senList.data &&
  senList.data.map((d: any) => {
    if (d.pictures) {
      d.pictures.map((p: any) => {
        picList.value.push({
          name: d.sensorPlace,
          url: p.url,
        });
      });
    }
  });

  picListAll.value = picList.value;
  sensorList.value = senList.data;
  sensorListAll.value = [
    { sensorPlace: langObj.value["全设备测点"], url: "", id: "" },
  ].concat(senList.data);
  getSensorTrendData();
};

// 获取指标数据
const getFaultName = async () => {
  let faultnamesSearch = {
    params: { machineId: machine_id.value },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result2 = await request.get("/api/machines/faultnames", faultnamesSearch);
  if (result2 && result2.data) {
    faultName = result2.data.faultNames;
    faultNameII = result2.data.faultNameExpects;
    machineInfo.value.vibrationLabel = {};
    machineInfo.value.healthLabel = {};
    result2.data.faultNames.map((d: any) => {
      machineInfo.value.vibrationLabel[d.column] =
          langObj.value[d.title] || d.title;
    });
    result2.data.faultNameExpects.map((d: any) => {
      machineInfo.value.healthLabel[d.column] =
          langObj.value[d.title] || d.title;
    });
  }
  selectWhichShow();
  getHistrendData();
};

//选择综合/当前状态
const conditionChange = (ev: any) => {
  changeCondition.value = ev;
  selectWhichShow();
};

// 选择测点
const selectRandarSensor = (ev: any) => {
  selectWhichShow();
};

// 判断展示稳定分还是实时分
const selectWhichShow = () => {
  let isShowStable = false;
  let isShowCurrent = false;
  let statusInfo = machineInfo.value;
  if (
      statusInfo.workStatus &&
      statusInfo.workStatus.stableScores &&
      Object.keys(statusInfo.workStatus.stableScores).length
  ) {
    isShowStable = true;
  }
  if (
      statusInfo.workStatus &&
      statusInfo.workStatus.scores &&
      Object.keys(statusInfo.workStatus.scores).length
  ) {
    isShowCurrent = true;
  }

  if (changeCondition.value) {
    if (!isShowStable && isShowCurrent) {
      changeCondition.value = false;
    }
  } else {
    if (isShowStable && !isShowCurrent) {
      changeCondition.value = true;
    }
  }

  getRandarData();
};

// 获取雷达图数据
const getRandarData = () => {
  let statusInfo = machineInfo.value;
  let healthLabel = statusInfo.healthLabel;
  let vibrationLabel = statusInfo.vibrationLabel;
  let scoreList: any = {};
  let Slist: any = [];
  let Tlist: any = [];
  if (changeCondition.value) {
    // 稳定
    if (selectSensorRandar.value) {
      // cedia
      let stableSensor = statusInfo.workStatus.stableSensors.find(
          (p: any) => p.id === selectSensorRandar.value
      );
      if (stableSensor) {
        scoreList = stableSensor;
      }
    } else {
      // quanbu
      if (statusInfo.workStatus && statusInfo.workStatus.stableScores) {
        scoreList = statusInfo.workStatus.stableScores;
      }
    }
  } else {
    // 实时
    if (selectSensorRandar.value) {
      // cefdian
      let stableSensor = statusInfo.workStatus.scoreSensors.find(
          (p: any) => p.id === selectSensorRandar.value
      );
      if (stableSensor) {
        scoreList = stableSensor;
      }
    } else {
      // quanbu
      if (statusInfo.workStatus && statusInfo.workStatus.scores) {
        scoreList = statusInfo.workStatus.scores;
      }
    }
  }

  // 处理数据
  for (let index in scoreList) {
    const item = scoreList[index];
    if (index.includes("S")) {
      Slist.push({
        quota: index,
        score: item,
        text: vibrationLabel[index] || "",
      });
    }

    if (index.includes("T")) {
      Tlist.push({
        quota: index,
        score: item,
        text: healthLabel[index] || "",
      });
    }
  }

  if (Tlist?.length > 0) {
    isShowHealthRandar.value = true
  } else {
    isShowHealthRandar.value = false
  }

  initChart(Tlist, "chartAover");
  initChart(Slist, "chartBover");
};

// 雷达图绘制
const initChart = (dataList: any, id: any) => {
  const ec = echarts as any;
  if (!document.getElementById(id)) return;
  let myChart = ec.init(document.getElementById(id));
  myChart.clear();

  if (!dataList.length) return;

  let data: any = [];
  let error: any = [];
  let indicator: any = [];
  dataList.map((d: any) => {
    data.push(d.score);
    error.push(60);
    let color = "";
    if (d.score >= 0 && d.score < 60) {
      color = "#CD5F3B";
    }
    if (d.score >= 60 && d.score < 80) {
      color = "#D2DE49";
    }
    if (d.score >= 80 && d.score <= 100) {
      color = "#fff";
    }

    let name = "";
    if (language.value == "Chinese" && d.text) {
      if (d.text.length > 8) {
        name =
            d.text.substring(0, 4) +
            "\n" +
            d.text.substring(4, 8) +
            "\n" +
            d.text.substring(8, d.text.length);
      } else if (d.text.length > 4 && d.text.length <= 8) {
        name =
            d.text.substring(0, 4) + "\n" + d.text.substring(4, d.text.length);
      } else {
        name = d.text;
      }
    }

    if (language.value == "English" && d.text) {
      let list1 = d.text.split(" ");

      let str: any = "";
      let num = 0;
      let sy = "";
      list1.map((n: any, i: any) => {
        if (i == 0) {
          if (n.length <= 12) {
            num = n.length;
            str = n;
          } else {
            num = 12;
            let a1 = n.substring(0, 12);
            sy = n.slice(12);
            str = a1;
          }
        } else {
          if (sy) {
            str = str + "\n";
            let n2 = sy.length + n.length;
            if (n2 <= 12) {
              num = n2;
              str = str + sy + " " + n;
              sy = "";
            } else {
              num = 12;
              str = str + sy;
              let n2 = 12 - sy.length >= 0 ? 12 - sy.length : 0;

              let a1 = n.substring(0, n2);
              sy = n.slice(12);
              str = str + a1;
            }
          } else {
            if (num + n.length <= 12) {
              str = str + " " + n;
              num = num + n.length;
            } else {
              str = str + "\n" + n;
              num = n.length;
            }
          }
        }
      });
      name = str;
    }

    indicator.push({
      color,
      max: 100,
      text: name,
    });
  });

  const option = {
    title: {
      text: "",
    },
    tooltip: {},
    radar: [
      {
        indicator: indicator,
        center: ["50%", "50%"],
        radius: 90,
        startAngle: 90,
        splitNumber: 5,
        axisName: {
          formatter: "{value}",
          fontSize: 10,
        },
        name: {
          formatter: "{value}",
          textStyle: {
            color: "rgba(46, 214, 157, 1)",
            fontSize: 14,
          },
        },
        splitArea: {
          areaStyle: {
            color: ["#605969", "#453A47"],
          },
        },
        axisLine: {
          lineStyle: {
            color: "rgba(255, 255, 255, 0.1)",
          },
        },
        splitLine: {
          lineStyle: {
            color: ["rgb(241,243,250)"],
            width: 0,
          },
        },
      },
    ],
    series: [
      {
        name: "当前值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 4,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: data,
            lineStyle: {
              width: 1,
              color: "rgba(247,220,225,0.1)",
              normal: {
                type: "dotted",
              },
            },
            itemStyle: {
              color: "#FF4E13",
              borderWidth: 5,
            },
            areaStyle: {
              color: "#F7DCE1",
            },
          },
        ],
      },
      {
        name: "预警值",
        type: "radar",
        emphasis: {
          lineStyle: {
            width: 1,
          },
        },
        symbolSize: 2, // 拐点的大小
        data: [
          {
            value: error,
            name: "",
            lineStyle: {
              width: 2,
              color: "rgba(255, 25, 59, 0.2)",
            },
            itemStyle: {
              color: "rgba(255, 25, 59, 0.2)",
              borderWidth: 0,
            },
          },
        ],
      },
    ],
  };

  option && myChart.setOption(option);
};

//设备信息查看更多
const machineMore = () => {
  router.push("/equipment/list");
};

// 分数趋势图
// 五分钟自动刷新
let intervalTimer: any = null;
let fiveRefresh: any = ref(null);
let histrendSearchModel: any = ref({
  type: 1,
  date: null,
  sensorId: "",
  isShow: true,
});
let scoreExpertMarkLine: any = null;
let scoreMarkLine: any = null;
let faultName: any = [];
let faultNameII: any = [];
let myChartC: any = null;
let myChartD: any = null;
let isShowHealthRemark: any = ref(true);
let isShowVibrationRemark = ref(true);
let isHideHealth = ref(false);
let isHideVibration = ref(false);
let faultChartSelectlenged: any = {};
let healthChartSelectlenged: any = {};

const refresh = (ev: any) => {
  if (ev) {
    intervalTimer = setInterval(() => {
      getHistrendData();
    }, 1000 * 60 * 5);
  } else {
    clearInterval(intervalTimer);
  }
};

onBeforeUnmount(() => {
  clearInterval(intervalTimer);
});

// 获取趋势图
const getHistrendData = async () => {
  faultName.map((item: any) => {
    healthChartSelectlenged[langObj.value[item.title] || item.title] = true;
  });

  faultNameII.map((item: any) => {
    faultChartSelectlenged[langObj.value[item.title] || item.title] = true;
  });

  let beginDate: any = "";
  let endDate: any = "";

  let machine_date = machineInfo.value.workStatus?.scoreDate;

  if (histrendSearchModel.value.type == "0") {
    if (
        histrendSearchModel.value.date &&
        histrendSearchModel.value.date.length === 2
    ) {
      let start = histrendSearchModel.value.date[0];
      let end = histrendSearchModel.value.date[1];
      beginDate = start.$d;
      endDate = end.$d;
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    let time = new Date(machine_date);
    let date = time.getTime();
    if (histrendSearchModel.value.type == "1") {
      beginDate = date - 1000 * 60 * 60 * 24 * 7;
    }
    if (histrendSearchModel.value.type == "2") {
      beginDate = date - 1000 * 60 * 60 * 24 * 30;
    }
    if (histrendSearchModel.value.type == "3") {
      beginDate = date - 1000 * 60 * 60 * 24 * 60;
    }
    endDate = machine_date;
  }
  let begin = transformDate2(beginDate, 1);
  let end = transformDate2(endDate, 2);

  let search = {
    params: {
      begin,
      end,
      sensorId: "",
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  getTimeLineData(begin, end);

  // 获取全设备数据(开关机数据)
  let scoreA = await request.get("/api/external/machines/score-full", search);
  let scoreAll = scoreA.data;

  let scoreExpertAll = scoreA.data;

  // 获取开关机线
  //健康
  if (scoreExpertAll.length > 0) {
    let markLine: any = {
      data: [],
      silent: true,
    };
    let wkStatus = 0;
    let x = "";
    scoreExpertAll.map((item: any, index: any) => {
      let time = new Date(item.date);
      let month =
          time.getMonth() + 1 > 9
              ? time.getMonth() + 1
              : "0" + (time.getMonth() + 1);
      let day = time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
      let hours = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
      let minutes =
          time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
      let seconds =
          time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
      x = month + "/" + day + " " + hours + ":" + minutes + ":" + seconds;
      if (index == 0) {
        wkStatus = item.wkStatus;
      } else {
        if (item.wkStatus != wkStatus) {
          if (item.wkStatus == 1) {
            markLine.data.push({
              name: "开",
              xAxis: x,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "green" },
            });
          } else {
            markLine.data.push({
              name: "关",
              xAxis: x,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "rgb(173, 38, 64)" },
            });
          }
          wkStatus = item.wkStatus;
        }
      }
    });
    scoreExpertMarkLine = markLine;
  }

  //振动
  if (scoreAll.length > 0) {
    let markLine: any = {
      data: [],
      silent: true,
    };
    let wkStatus = 0;
    let x = "";
    scoreAll.map((item: any, index: any) => {
      let time = new Date(item.date);
      let month =
          time.getMonth() + 1 > 9
              ? time.getMonth() + 1
              : "0" + (time.getMonth() + 1);
      let day = time.getDate() > 9 ? time.getDate() : "0" + time.getDate();
      let hours = time.getHours() > 9 ? time.getHours() : "0" + time.getHours();
      let minutes =
          time.getMinutes() > 9 ? time.getMinutes() : "0" + time.getMinutes();
      let seconds =
          time.getSeconds() > 9 ? time.getSeconds() : "0" + time.getSeconds();
      x = month + "/" + day + " " + hours + ":" + minutes + ":" + seconds;
      if (index == 0) {
        wkStatus = item.wkStatus;
      } else {
        if (item.wkStatus != wkStatus) {
          if (item.wkStatus == 1) {
            markLine.data.push({
              name: "开",
              xAxis: x,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "green" },
            });
          } else {
            markLine.data.push({
              name: "关",
              xAxis: x,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "rgb(173, 38, 64)" },
            });
          }
          wkStatus = item.wkStatus;
        }
      }
    });
    scoreMarkLine = markLine;
  }

  if (histrendSearchModel.value.sensorId) {
    search.params.sensorId = histrendSearchModel.value.sensorId;
    let scoreA = await request.get("/api/external/machines/score-full", search);
    scoreAll = scoreA.data;
  }

  faultName.map((d: any) => {
    d.data = [];
    d.dateList = [];
  });
  faultNameII.map((d: any) => {
    d.data = [];
    d.dateList = [];
  });

  scoreAll.map((item: any) => {
    faultName.map((fault: any) => {
      fault.dateList.push(item.date);
      let date = item.date.slice(5, 19);
      fault.data.push([date, item[fault.column] || null]);
    });

    faultNameII.map((fault: any) => {
      fault.dateList.push(item.date);
      let date = item.date.slice(5, 19);
      fault.data.push([date, item[fault?.column] || null]);
    });
  });

  scoreExpertAll.map((item: any) => {});

  faultNameData.value = {
    scoreAll,
    scoreExpertAll,
  };

  isShowHealthHistrend.value = false
  if (faultNameII?.length > 0) {
    isShowHealthHistrend.value = true
  } else {
    isShowHealthHistrend.value = false
  }

  setTimeout(() => {
    initChartC();
    initChartD();
  }, 200);
};

// 健康分数曲线图
// 健康
const initChartC = () => {
  let data = faultNameII;

  let xData = data && data.length ? data[0].data.map((p: any) => p[0]) : [];

  const ec = echarts as any;
  if (!document.getElementById("chartCover")) return;
  myChartC = ec.init(document.getElementById("chartCover"));
  myChartC.resize();

  let min = 0,
      max = 100,
      colors: any[] = [];

  let option: any = null;
  let LengData: any[] = [],
      SelectedData = {},
      YaData,
      SeriesData,
      DataInfo: any[] = [];
  colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
  ];

  data.map((item: any, index: any) => {
    if (item.data && item.data.length) {
      LengData.push(langObj.value[item.title] || item.title);
      if (isShowHealthRemark.value == true) {
        DataInfo.push({
          name: langObj.value[item.title] || item.title,
          type: "line",
          data: item.data,
          smooth: false,
          symbolSize: 3,
          markLine: scoreExpertMarkLine,
          connectNulls: true,
        });
      } else if (isShowHealthRemark.value == false) {
        DataInfo.push({
          name: langObj.value[item.title] || item.title,
          type: "line",
          yAxisIndex: 0,
          data: item.data,
          smooth: false,
          symbolSize: 3,
          connectNulls: true,
        });
      }
    }
  });

  if (!DataInfo || !DataInfo.length) {
    isHideHealth.value = true;
  }

  function Init(sel: { [x: string]: any }, dataInfo: any[]) {
    SelectedData = sel || {};
    (YaData = []), (SeriesData = []);
    let Datas = JSON.parse(JSON.stringify(dataInfo));
    for (let n = 0; n < LengData.length; n++) {
      const ydata = Datas[n].data;
      let max = Math.max(...ydata) / 0.8 / 5;
      if (max > 0 && max < 0.5) {
        max = 1;
      }
      const ymax = Math.round(max) * 5;

      // 如果该图例状态为false时，则跳过 不push
      if (sel[LengData[n]]) {
        YaData.push({
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        });
      } else {
        Datas[n].data = [];
      }
      Datas[n].yAxisIndex = 0;
      SeriesData.push(Datas[n]);
    }
    if (YaData.length == 0) {
      YaData = [{ type: "value" }];
    }
    option = {
      color: colors,
      tooltip: {
        hideDelay: 100,
        transitionDuration: 0,
        animation: false,
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      grid: {
        right: "5%",
        left: "7%",
      },
      toolbox: {
        trigger: "axis",
      },
      dataZoom: [{ type: "inside" }],
      legend: {
        data: LengData,
        selected: SelectedData,
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: [
        {
          show: true,
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: xData,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.3,
            },
          },
          axisLabel: {
            color: "#fff",
            fontSize: 16,
            showMaxLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",

              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            color: "#fff",
          },
        },
      ],
      series: SeriesData,
    };
  }

  Init(faultChartSelectlenged, DataInfo);

  if (option && typeof option === "object") {
    myChartC && myChartC.clear();
    myChartC && myChartC.setOption(option, true);
  }

  myChartC
      .off("legendselectchanged")
      .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        faultChartSelectlenged = params.selected;
        Init(faultChartSelectlenged, DataInfo);
        if (option && typeof option === "object") {
          myChartC?.clear();
          myChartC?.setOption(option, true);
        }
      });
};

// 振动
const initChartD = () => {
  let data = faultName;
  let xData = data && data.length ? data[0].data.map((p: any) => p[0]) : [];

  const ec = echarts as any;
  if (!document.getElementById("chartDover")) return;
  myChartD = ec.init(document.getElementById("chartDover"));
  myChartD.resize();

  let min = 0,
      max = 100,
      colors: any[] = [];

  let option: any = null;
  let LengData: any[] = [],
      SelectedData = {},
      YaData,
      SeriesData,
      DataInfo: any[] = [];
  colors = [
    "rgb(87,147,243)",
    "rgb(255,165,0)",
    "rgb(22,58,255)",
    "rgb(0,255,0)",
    "rgb(255,51,51)",
    "rgb(0,255,255)",
    "rgb(0,128,0)",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
  ];

  data.map((item: any, index: any) => {
    if (item.data && item.data.length) {
      LengData.push(langObj.value[item.title] || item.title);
      if (isShowVibrationRemark.value == true) {
        DataInfo.push({
          name: langObj.value[item.title] || item.title,
          type: "line",
          data: item.data,
          smooth: false,
          symbolSize: 3,
          markLine: scoreMarkLine,
          connectNulls: true,
        });
      } else if (isShowVibrationRemark.value == false) {
        DataInfo.push({
          name: langObj.value[item.title] || item.title,
          type: "line",
          yAxisIndex: 0,
          data: item.data,
          smooth: false,
          symbolSize: 3,
          connectNulls: true,
        });
      }
    }
  });

  if (!DataInfo || !DataInfo.length) {
    isHideVibration.value = true;
  }

  function Init(sel: { [x: string]: any }, dataInfo: any[]) {
    SelectedData = sel || {};
    (YaData = []), (SeriesData = []);
    let Datas = JSON.parse(JSON.stringify(dataInfo));
    for (let n = 0; n < LengData.length; n++) {
      const ydata = Datas[n].data;
      let max = Math.max(...ydata) / 0.8 / 5;
      if (max > 0 && max < 0.5) {
        max = 1;
      }
      const ymax = Math.round(max) * 5;

      // 如果该图例状态为false时，则跳过 不push
      if (sel[LengData[n]]) {
        YaData.push({
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        });
      } else {
        Datas[n].data = [];
      }
      Datas[n].yAxisIndex = 0;
      SeriesData.push(Datas[n]);
    }
    if (YaData.length == 0) {
      YaData = [{ type: "value" }];
    }
    option = {
      color: colors,
      tooltip: {
        hideDelay: 100,
        transitionDuration: 0,
        animation: false,
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      grid: {
        right: "5%",
        left: "7%",
      },
      toolbox: {
        trigger: "axis",
      },
      dataZoom: [{ type: "inside" }],
      legend: {
        data: LengData,
        selected: SelectedData,
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: [
        {
          show: true,
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: xData,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.3,
            },
          },
          axisLabel: {
            color: "#fff",
            fontSize: 16,
            showMaxLabel: true,
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          scale: true,
          min: 0,
          max: 100,
          splitNumber: 5,
          splitLine: {
            lineStyle: {
              color: [
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgb(117, 116, 116)",
                "rgba(250, 60, 88, 1)",
                "rgba(254, 178, 7, 1)",
                "rgb(117, 116, 116)",
              ],
            },
          },
          position: "left",
          offset: "-10",
          axisLine: {
            color: "#fff",
          },
        },
      ],
      series: SeriesData,
    };
  }

  Init(healthChartSelectlenged, DataInfo);

  if (option && typeof option === "object") {
    myChartD && myChartD.clear();
    myChartD && myChartD.setOption(option, true);
  }

  myChartD
      .off("legendselectchanged")
      .on("legendselectchanged", function (params: { selected: any }) {
        // 得到当前的图例显示隐藏状态分别有哪些
        healthChartSelectlenged = params.selected;
        Init(healthChartSelectlenged, DataInfo);
        if (option && typeof option === "object") {
          myChartD?.clear();
          myChartD?.setOption(option, true);
        }
      });
};

//数据下载
let faultNameData: any = ref({ scoreAll: [], scoreExpertAll: [] });
let downLoadList: any = ref([]);
let downLoadVisible = ref(false);
const downLoad1 = () => {
  downLoadList.value = [];
  let faultNameS = JSON.parse(JSON.stringify(faultName));
  let faultNameIIS = JSON.parse(JSON.stringify(faultNameII));
  let dataList = [...faultNameS, ...faultNameIIS];
  let scoreAll = JSON.parse(JSON.stringify(faultNameData.value.scoreAll));
  let scoreExpertAll = JSON.parse(
      JSON.stringify(faultNameData.value.scoreExpertAll)
  );
  let dateList: any = [];
  dataList &&
  dataList.map((d: any) => {
    dateList = dateList.concat(d.dateList);
  });

  dateList = [...new Set(dateList)].sort();
  let list: any = [];
  let nameList = dataList.map((p: any) => ({
    title: p.title,
    column: p.column,
  }));

  dateList.map((date: any, index: any) => {
    let score = scoreAll.find((p: any) => p.date === date);
    let scoreExpert = scoreExpertAll.find((p: any) => p.date === date);
    if (score) {
      delete score.date;
      delete score.wkStatus;
    }

    if (scoreExpert) {
      delete scoreExpert.date;
      delete scoreExpert.wkStatus;
    }

    for (let i in score) {
      let name = nameList.find((p: any) => p.column === i)?.title;
      list.push({
        date: date,
        machineName: machineInfo.value.machineName,
        title: name,
        score: score[i],
      });
    }

    for (let i in scoreExpert) {
      let name = nameList.find((p: any) => p.column === i)?.title;
      list.push({
        date: date,
        machineName: machineInfo.value.machineName,
        title: name,
        score: scoreExpert[i],
      });
    }
  });
  downLoadList.value = list;
  downLoadVisible.value = true;
};

const downLoadCancel = () => {
  downLoadVisible.value = false;
};

// 运行时间趋势
let statusSearchModel: any = ref({
  date: null,
  type: "1",
  isShow: true,
});

const getMachineWKstatus = async () => {
  let startDate = "";
  let endDate: any = "";
  if (statusSearchModel.value.type == "0") {
    if (
        statusSearchModel.value.date &&
        statusSearchModel.value.date.length === 2
    ) {
      startDate = transformDate(statusSearchModel.value.date[0]);
      endDate = transformDate(statusSearchModel.value.date[1]);
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    endDate = new Date();
    let num = 7;
    if (statusSearchModel.value.type == "2") {
      num = 30;
    }
    if (statusSearchModel.value.type == "3") {
      num = 60;
    }
    startDate = transformDate(
        new Date(
            new Date(transformDate(endDate)).getTime() - 1000 * 60 * 60 * 24 * num
        )
    );
  }

  // startSave.value = transformDate2(startDate, 1)
  // endSave.value = transformDate2(endDate, 2)

  let search = {
    params: {
      begin: transformDate2(startDate, 1),
      end: transformDate2(endDate, 2),
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/external/machines/onlines", search);
  let list: any = [];

  function compare() {
    return function (a: any, b: any) {
      var value1 = a["date"];
      var value2 = b["date"];
      return value1 > value2 ? 1 : -1;
    };
  }

  if (result && result.data) {
    list = result.data;
  }
  list.sort(compare());
  let xData = list.map((p: any) => p.date);
  let yData = list.map((p: any) =>
      Math.round(p.online / 3600) > 24 ? 24 : Math.round(p.online / 3600)
  );

  initStatusChart(xData, yData);
};

const initStatusChart = (xData: any, yData: any) => {
  if (!document.getElementById("chartStatus")) return;
  const ec = echarts as any;
  let myChart = ec.init(document.getElementById("chartStatus"));
  let option = {
    color: ["#00FFF4"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [
      {
        type: "inside",
      },
    ],
    xAxis: {
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        showMaxLabel: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3,
        },
      },
      data: xData,
    },
    yAxis: {
      name: "h",
      type: "value",
    },
    series: [
      {
        data: yData,
        type: "line",
      },
    ],
  };

  myChart && myChart.setOption(option);
};

// 测点趋势
let sensorSearchModel: any = ref({
  date: null,
  type: "1",
  isShow: true,
});

let sensorLoading = ref(false);

let dataTypeList: any = [];
let sensorChartLength: any = [];
let startSave: any = ref("");
let endSave: any = ref("");

const getSensorTrendData = async () => {
  sensorLoading.value = true;
  let result = await request.get("/api/sensors/datatype");
  dataTypeList = result.data;
  // dataTypeList.map((d: any, i: any) => {

  //   d.name = langObj.value[d.name]
  // })

  let machine_date = transformDate(new Date());
  let startDate = "";
  let endDate = "";
  if (sensorSearchModel.value.type == "0") {
    if (
        sensorSearchModel.value.date &&
        sensorSearchModel.value.date.length === 2
    ) {
      startDate = transformDate(sensorSearchModel.value.date[0]);
      endDate = transformDate(sensorSearchModel.value.date[1]);
      if (endDate > machine_date) {
        endDate = machine_date;
      }
    } else {
      message.warn("请选择时间");
      return;
    }
  } else {
    endDate = machine_date;
    let num = 1;
    if (sensorSearchModel.value.type == "2") {
      num = 7;
    }
    if (sensorSearchModel.value.type == "3") {
      num = 30;
    }
    startDate = transformDate(
        new Date(
            new Date(transformDate(machine_date)).getTime() -
            1000 * 60 * 60 * 24 * num
        )
    );
  }

  startSave.value = transformDate2(startDate, 1);
  endSave.value = transformDate2(endDate, 2);
  try {
    for (let index in sensorList.value) {
      let item = sensorList.value[index];
      if (item.dataType && item.dataType.length) {
        if (item.dataType.includes("高频加速度")) {
          item.dataType.map((d: any, i: any) => {
            if (d === "高频加速度") {
              item.dataType.unshift(item.dataType.splice(i, 1)[0]);
            }
          });
        }
      }
      await getSensorDataInfo(item, index, startSave.value, endSave.value);
    }
  } catch (error) {
    sensorLoading.value = false;
  }
  sensorLoading.value = false;
};

const getSensorDataInfo = async (ev: any, index: any, start: any, end: any) => {
  let senLength = sensorChartLength.find((p: any) => p.sensorId === ev.id);
  let typeList = [];

  if (senLength && senLength.length && Object.keys(senLength.length).length) {
    for (let i in senLength.length) {
      let d = senLength.length[i];
      if (d) {
        let type = ev.dataType.find((p: any) => i.includes(p));
        typeList.push(type);
      }
    }
  } else {
    ev.dataType &&
    ev.dataType.length &&
    ev.dataType.map((d: any, i: any) => {
      let datatype = dataTypeList.find((p: any) => p.name === d) || {
        unit: "",
      };

      let tt = ''
      // if(d === '高频加速度' || d === '温度') {
      //   tt = `${d}(${datatype.unit})`
      // }
      if (d === '低频加速度X') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'X')?.sensorGroupName || '' : ''
        tt = `${nameX}-低频加速度X(${datatype.unit})`
      } else if (d === '低频加速度Y') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'Y')?.sensorGroupName || '' : ''
        tt = `${nameX}-低频加速度Y(${datatype.unit})`
      } else if (d === '低频加速度Z') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'Z')?.sensorGroupName || '' : ''
        tt = `${nameX}-低频加速度Z(${datatype.unit})`
      } else if (d === '速度X') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'X')?.sensorGroupName || '' : ''
        tt = `${nameX}-速度X(${datatype.unit})`
      } else if (d === '速度Y') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'Y')?.sensorGroupName || '' : ''
        tt = `${nameX}-速度Y(${datatype.unit})`
      } else if (d === '速度Z') {
        let nameX = ev.sensorGroup ? ev.sensorGroup.find((p: any) => p.coordinate == 'Z')?.sensorGroupName || '' : ''
        tt = `${nameX}-速度Z(${datatype.unit})`
      } else {
        tt = `${d}(${datatype.unit})`
      }


      if (i === 0) {
        typeList.push(d);
      }
      let senLength = sensorChartLength.find(
          (p: any) => p.sensorId === ev.id
      );
      if (senLength && senLength.length) {
        // senLength.length[`${d}(${datatype.unit})`] = i === 0 ? true : false;
        senLength.length[tt] = i === 0 ? true : false;
      } else {
        sensorChartLength.push({
          sensorId: ev.id,
          // length: { [`${d}(${datatype.unit})`]: i === 0 ? true : false },
          length: { [tt]: i === 0 ? true : false },
        });
      }
    });
  }
  let senLengthA = sensorChartLength.find((p: any) => p.sensorId === ev.id);
  if (!typeList.length) return;
  senLengthA.loadLength = typeList;

  let search = {
    sensorId: ev.id,
    type: typeList,
    begin: startSave.value,
    end: endSave.value,
  };

  let result = await request.post("/api/external/sensors/quota", search);
  sensorList.value[index].dataList = result.data;
  initSensorDataChart(ev, index);
};

const reloadSensorInfo = async (sensor: any, index: any, length: any) => {

  let senLength = sensorChartLength.find((p: any) => p.sensorId === sensor.id);
  let obj: any = {};
  // if (language.value == "English") {
  //   for (let i in length) {
  //     let d = length[i];
  //     obj[langObj.value[i]] = d;
  //   }
  //   senLength.length = obj;
  //   length = obj;
  // } else {
  //   senLength.length = length;
  // }
  // senLength.length = length
  if (language.value == "English") {
    for (let i in length) {
      let d = length[i];
      let list1 = i.split('-')
      if (list1.length == 1) {
        let list2 = list1[0].split('(')
        let tt = `${getLanguage(list2[0], language.value)}(${list2[1]}`
        obj[tt] = d;
      } else {
        let list2 = list1[1].split('(')
        let tt1 = `${getLanguage(list2[0], language.value)}(${list2[1]}`
        let tt = `${list1[0]}-${tt1}`
        obj[tt] = d;
      }
    }

    senLength.length = obj
    length = obj
  } else {
    senLength.length = length;
  }

  let typeList: any = [];
  for (let i in length) {
    let d = length[i];
    if (d) {
      let type = sensor.dataType.find((p: any) => i.includes(p));
      if (type) {
        typeList.push(type);
      }
    }
  }

  senLength.loadLength = typeList;

  let search = {
    sensorId: sensor.id,
    type: typeList,
    // type: ["高频速度", "高频加速度"],
    begin: startSave.value,
    end: endSave.value,
  };

  let result = await request.post("/api/external/sensors/quota", search);
  sensorList.value[index].dataList = result.data;


  initSensorDataChart(sensor, index);
};

const initSensorDataChart = (sensor: any, index: any) => {
  let dataList = sensor.dataList;
  let selectLength =
      sensorChartLength.find((p: any) => p.sensorId === sensor.id)?.length || {};

  let obj: any = {};
  for (let i in selectLength) {
    let d = selectLength[i];
    let list1 = i.split('-')
    if (list1.length == 1) {
      let list2 = i.split('(')
      let tt = `${getLanguage(list2[0], language.value)}(${list2[1]}`
      obj[tt] = d;
    } else {
      let list2 = list1[1].split('(')
      let tt1 = `${getLanguage(list2[0], language.value)}(${list2[1]}`
      let tt = `${list1[0]}-${tt1}`
      obj[tt] = d;
    }
  }

  selectLength = obj;

  const ec = echarts as any;
  if (!document.getElementById("chartSensor" + index)) return;
  let myChart = ec.init(document.getElementById("chartSensor" + index));
  myChart.clear();

  let allDate: any = [];
  let xData: any = [];

  let option: any = null;

  let colors: any,
      LengData: any = [],
      YaData,
      SeriesData,
      DataInfo: any = [];
  colors = [
    "#5793f3",
    "orange",
    "#163AFF",
    "#00ff00",
    "#ff3333",
    "#00ffff",
    "green",
    "rgb(32, 157, 179)",
    "rgb(120, 32, 179)",
    "rgb(214, 55, 188)",
  ];
  dataList.map((item: any, i: any) => {
    item.dateSimple = item.date.map((d: any) => d.substring(5));
    allDate = allDate.concat(item.date);
    xData = xData.concat(item.dateSimple);
    item.dataShow = item.dateSimple.map((d: any, i: any) => [d, item.value[i]]);
  });
  LengData = Object.keys(selectLength);

  LengData.map((item: any, i: any) => {
    let data = dataList.find((p: any) => {
      let list1 = item.split('-')

      if (list1.length == 1) {
        let list2 = item.split('(')
        return list2[0] === getLanguage(p.type, language.value)
      } else {
        let list2 = list1[1].split('(')
        return list2[0] === getLanguage(p.type, language.value)
      }
    });

    DataInfo.push({
      name: item,
      type: "line",
      yAxisIndex: i,
      data: data ? data.dataShow : [],
      symbolSize: 2,
      smooth: false,
      connectNulls: true,
    });
  });

  allDate = [...new Set(allDate)].sort();
  xData = allDate.map((p: any) => p.substring(5));

  function Init(sel: { [x: string]: any }, dataInfo: any) {
    let selectedData = sel || {};
    (YaData = []), (SeriesData = []);
    let Datas = JSON.parse(JSON.stringify(dataInfo));
    for (let n = 0; n < LengData.length; n++) {
      const ydata = Datas[n].data;
      let max = Math.max(...ydata) / 0.8 / 5;
      if (max > 0 && max < 0.5) {
        max = 1;
      }
      const ymax = Math.round(max) * 5;

      // 如果该图例状态为false时，则跳过 不push
      if (sel[LengData[n]]) {
        YaData.push({
          type: "value",
          name: LengData[n],
          splitLine: {
            show: false,
          },
          position: YaData.length % 2 == 0 ? "left" : "right",
          offset:
              YaData.length + 1 <= 2
                  ? 0
                  : (Math.ceil((YaData.length + 1) / 2) - 1) * 50,
          axisLine: {
            lineStyle: {
              color: colors[n],
            },
          },
          axisLabel: {
            formatter: function (value: any) {
              if (n < 2) {
                return value;
              } else {
                return value;
              }
            },
          },
          nameLocation: "center",
          nameTextStyle: {
            align: "left",
            padding: [25, 0, 25, -90]
          },
        });
      } else {
        Datas[n].data = [];
      }
      Datas[n].yAxisIndex = YaData.length - 1 < 0 ? 0 : YaData.length - 1;
      SeriesData.push(Datas[n]);
    }
    if (YaData.length == 0) {
      YaData = [{ type: "value" }];
    }

    let left = YaData.length > 1 ? Math.ceil(YaData.length / 2) * 60 : 60
    let right = YaData.length > 1 ? Math.floor(YaData.length / 2) * 60 : 60

    option = {
      color: colors,
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      grid: {
        top: "70px",
        // right: YaData.length ? `${(YaData.length / 2) * 8}%` : "5%",
        // left: YaData.length > 1 ? `${(YaData.length / 2) * 8}%` : "7%",
        right: right,
        left: left
      },
      toolbox: {
        trigger: "axis",
      },
      dataZoom: [{ type: "inside" }],
      legend: {
        y: "-3px",
        data: LengData,
        selected: selectedData,
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: [
        {
          type: "category",
          axisTick: {
            alignWithLabel: true,
          },
          data: xData,
          splitLine: {
            show: true,
            lineStyle: {
              opacity: 0.3,
            },
          },
          axisLabel: {
            color: "#fff",
            fontSize: 12,
            showMaxLabel: true,
          },
        },
      ],
      yAxis: YaData,
      series: SeriesData,
    };
  }

  Init(selectLength, DataInfo);
  if (option && typeof option === "object") {
    myChart.setOption(option, true);
  }

  myChart.off("legendselectchanged");
  myChart.on("legendselectchanged", function (params: { selected: any }) {
    // 查看是否需要请求数据
    let select1: any = [];
    for (let i in params.selected) {
      let d = params.selected[i];
      if (d) {
        let list1 = i.split('-')
        if (list1.length == 1) {
          let list2 = i.split('(')
          select1.push(list2[0]);
        } else {
          let list2 = list1[1].split('(')
          select1.push(list2[0]);
        }
      }
    }
    let select2 =
        sensorChartLength.find((p: any) => p.sensorId === sensor.id)
            ?.loadLength || [];
    let isReload = false;

    select1.map((d: any) => {
      // let a = select2.find((p: any) => d === p)
      let a = select2.find((p: any) => {d === getLanguage(p, language.value)});
      if (!a) {
        isReload = true;
      }
    });

    if (isReload) {
      reloadSensorInfo(sensor, index, params.selected);
    } else {
      selectLength = params.selected;
      Init(selectLength, DataInfo);
      if (option && typeof option === "object") {
        if (myChart) {
          myChart?.clear();
        }
        myChart.setOption(option, true);
      }
    }
  });

  // 获取表格数据
  let dateValue = allDate[allDate.length - 1];
  let date = xData[xData.length - 1];
  let list: any = [];

  // LengData.map((d:any) => {
  //     let info = dataList.find((p:any) => `${p.type}(${p.unit})` === d)
  //     if(info) {
  //         let s = info.dataShow.find( (p:any) => p[0] === date)
  //         list.push({
  //             name: d,
  //             value: s ? s[1].toFixed(2) : '--',
  //         })
  //     } else {
  //         list.push({
  //             name: d,
  //             value: '--',
  //         })
  //     }
  // })

  if (machineInfo.value.workStatusRms) {
    let info = machineInfo.value.workStatusRms.find(
        (p: any) => p.sensorId === sensorList.value[index].id
    );

    LengData.map((d: any) => {
      let name = JSON.parse(JSON.stringify(d));
      let n = name.replace(" (", "(").split("(")[0];
      if (n.includes('-')) {
        n = n.split("-")[1]
      }
      if (language.value == 'English') {
        n = langObj.value[n]
      }
      let rmsInfo = info && info.values && info.values.find((p: any) => p.type === n);

      if (rmsInfo) {
        list.push({
          name: d,
          value: rmsInfo.value ? rmsInfo.value.toFixed(2) : "--",
        });
      } else {
        list.push({
          name: d,
          value: "--",
        });
      }
    });
  }

  sensorList.value[index].sensorData = list;
  sensorList.value[index].sensorDate = dateValue;

  // myChart.getZr().off('click');
  // myChart.getZr().on('click', (params: { offsetX: any; offsetY: any; }) => {
  //   var pointInPixel = [params.offsetX, params.offsetY];
  //   if (myChart.containPixel('grid', pointInPixel)) {
  //     /*此处添加具体执行代码*/
  //     var pointInGrid = myChart.convertFromPixel({ seriesIndex: 0 }, pointInPixel);
  //     //X轴序号
  //     var xIndex = pointInGrid[0];
  //     //获取当前图表的option
  //     var op = myChart.getOption();
  //     //获得图表中我们想要的数据
  //     let nowDate = op.xAxis[0].data[xIndex];

  //     sensorList.value[index].sensorDate = allDate[xData.indexOf(nowDate)]

  //     let list:any = []
  //     LengData.map((d:any) => {
  //         let info = dataList.find((p:any) => `${p.type}(${p.unit})` === d)
  //         if(info) {
  //             let s = info.dataShow.find( (p:any) => p[0] === nowDate)
  //             list.push({
  //                 name: d,
  //                 value: s ? s[1].toFixed(2) : '--',
  //             })
  //         } else {
  //             list.push({
  //                 name: d,
  //                 value: '--',
  //             })
  //         }
  //     })
  //     sensorList.value[index].sensorData = list
  // }
  // });
};

// 获取时间轴数据
// getTimeLineData(begin, end)
let timeLineList: any = ref([]);
const getTimeLineData = async (begin: any, end: any) => {
  let search = {
    params: {
      begin,
      end,
      machineId: machine_id.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get("/api/machines/workstatus/stables", search);
  let stableList: any = [];
  if (result && result.data) {
    stableList = result.data;
  }

  let warnSearch = {
    params: {
      begin,
      end,
      machineId: machine_id.value,
      skip: 1,
      take: 5000,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let warnResult = await request.get("/api/workflows", warnSearch);
  let orderList: any = [];
  if (warnResult && warnResult.data) {
    orderList = warnResult.data.list;
  }

  let conditionListA = [
    { value: 1, valueL: [0], color: "#61c08f" },
    { value: 2, valueL: [1, 2], color: "#d2de49" },
    { value: 3, valueL: [3, 4, 5], color: "#cd5f3b" },
    { value: 4, valueL: [6, 7], color: "#921e37" },
  ];

  let conditionA: any = 0;
  stableList.map((d: any, i: any) => {
    if (stableList[i + 1]) {
      let order = orderList.find(
          (p: any) => d.date <= p.date && stableList[i + 1].date > p.date
      );
      d.order = order;
    } else {
      let order = orderList.find((p: any) => d.date === p.date);
      d.order = order;
    }
    d.isShowDate = false;
    let color = conditionListA.find((p: any) =>
        p.valueL.includes(d.condition)
    )?.color;
    d.color = color;
    if (i == 0) {
      d.isShowDate = true;
      d.showDate = d.date.substring(5, 16);
      conditionA = conditionListA.find((p: any) =>
          p.valueL.includes(d.condition)
      )?.value;
    } else if (i == stableList.length - 1) {
      d.isShowDate = true;
      d.showDate = d.date.substring(5, 16);
    } else {
      let conditionB = conditionListA.find((p: any) =>
          p.valueL.includes(d.condition)
      )?.value;
      if (conditionB !== conditionA) {
        d.isShowDate = false;
        d.showDate = d.date.substring(5, 16);
      }
      conditionA = conditionB;
    }
  });
  timeLineList.value = stableList;
};

const getRowClassName = (index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "odd-row" : "even-row";
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const disabledDate2 = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 180;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 180;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};
</script>

<style lang="less" scoped>
.sjz {
  margin-left: 4rem;
  margin-right: 4rem;
  width: calc(100% - 8rem);
  height: 20px;
  background: #999;
  padding: 0;
}

/deep/ .ant-input-affix-wrapper {
  background: #021768;
  border: 1px solid #0d53b7cc;
  color: #fff;
}

/deep/ .ant-input {
  background: #021768;
  color: #fff;
}

/deep/
.ant-select-single.ant-select-sm:not(.ant-select-customize-input)
.ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-select-arrow {
  color: #fff;
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #04165d;
  border: 1px solid #0d53b7;
  color: #fff;
}

/deep/ .ant-switch {
  background-color: #07259a;
}

/deep/ .ant-switch-checked {
  background-color: #07259a !important;
}

/deep/ .ant-picker {
  background-color: #021768;
  border: 1px solid #0d53b7;
}

/deep/ .ant-picker-suffix {
  color: #fff;
  opacity: 0.5;
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  width: 250px;
  padding: 20px 10px;
}

.machine-select {
  border-radius: 2px;
  background: #072499;
}

.content {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  padding: 20px 10px;
}

.btn1 {
  border: 1px solid #0d53b7;
  border-radius: 2px;
  padding: 5px 10px;
  cursor: pointer;
}

.btn1-select {
  background: #072499;
  padding: 5px 10px;
  cursor: pointer;
}

.btn2 {
  border: 1px solid #0d53b7;
  cursor: pointer;
}

.btn3 {
  border: 1px solid #0d53b7;
  background-color: #072498;
  padding: 0.25rem 0.75rem;
  cursor: pointer;
}

.opt6 {
  opacity: 0.6;
}

.opt8 {
  opacity: 0.8;
}

.size20 {
  font-size: 20px;
}

.size18 {
  font-size: 18px;
}

.size12 {
  font-size: 12px;
}

.imgShow {
  max-width: 15px;
  width: 15px;
  height: 15px;
}
</style>
