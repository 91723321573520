<template>
    <!-- 厂级大屏-区域 -->
    <div v-if="selectModel === 1" class="w-full flex px-4 py-2"
        style="background-color: #000A31; color: #fff; min-height: 92vh;">
        <!-- 左侧 -->
        <div class="flex flex-col">
            <div class="title flex items-center">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">{{ langObj['监测范围'] }}</span>

                <!-- <question-circle-outlined  class="ml-3"/> -->
                <a-tooltip placement="topLeft">
                    <template #title>
                        <div style="background: #fff">
                            <div>{{ langObj['监控设备数：用户所选组织下的监测设备数统计'] }}</div>
                            <div>{{ langObj['监控测点数：用户所选组织下的监测测点数统计'] }}</div>
                            <div>{{ langObj['运行设备数：用户所选组织下的监测设备中运行设备数统计'] }}</div>
                            <div>{{ langObj['运行率：用户所选组织下的监测设备中运行设备数/监测设备总数'] }}</div>
                        </div>
                    </template>
                    <question-circle-outlined style="font-size:14px" class="ml-2" />
                </a-tooltip>
            </div>

            <div class="flex items-center mt-2" style=" color: #06DEF5;">
                <div class="flex items-center">
                    <img src="@/assets/cliqueD3.png" style="width: 40px">
                    <div class="ml-2 enW">
                        <div class="flex items-center">
                            <span style="font-size: 20px">{{ machineList.length }}</span>
                            <!-- <div class="flex-1"></div> -->
                            <span style="font-size: 14px">{{getLanguage("台", language)}}</span>
                        </div>
                        <div style="color: #fff;">{{ langObj['监控设备数'] }}</div>
                    </div>
                </div>

                <div class="flex items-center" style="color: #5B9BFF">
                    <img src="@/assets/cliqueD1.png" style="width: 40px">
                    <div class="ml-2 enW">
                        <div class="flex items-center">
                            <span style="font-size: 20px">{{ sensorList.length }}</span>
                            <!-- <div class="flex-1"></div> -->
                            <span style="font-size: 14px">{{getLanguage("个", language)}}</span>
                        </div>
                        <div style="color: #fff">{{ langObj['监控测点数'] }}</div>
                    </div>
                </div>

                <div class="flex items-center" style="color: #5BFEB7">
                    <img src="@/assets/cliqueD2.png" style="width: 40px">
                    <div class="ml-2 enW">
                        <div class="flex items-center">
                            <span style="font-size: 20px">{{ getRunMachine(machineList).num }}</span>
                            <!-- <div class="flex-1"></div> -->
                            <span style="font-size: 14px">{{getLanguage("台", language)}}({{ getRunMachine(machineList).rate }}%)</span>
                        </div>
                        <div style="color: #fff">{{ langObj['运行设备数(运行率)'] }}</div>
                    </div>
                </div>

            </div>

            <div class="title flex items-center mt-7">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">{{ langObj['全厂运行情况'] }}</span>
            </div>

            <div class="flex items-center mt-5">
                <div class="box1">
                    <img class="w-full h-full" src='@/assets/cliqueD4.png' alt="">
                    <div class="content">
                        <div class="flex w-full items-center" style="justify-content: center">
                            <div style="font-size:20px">{{ totalTime }}</div>
                            <div class="ml-2" style="font-size:14px">{{getLanguage("小时", language)}}</div>
                            <a-tooltip placement="topLeft">
                                <template #title>
                                    <div style="background: #fff">
                                        {{ langObj['显示该系统为工厂上线运行的时间'] }}
                                    </div>
                                </template>
                                <question-circle-outlined style="font-size:14px" class="ml-2" />
                            </a-tooltip>
                        </div>
                        <div class="w-full" style="text-align: center; color: #fff">
                            {{ langObj['系统持续服务运行 '] }}
                        </div>
                    </div>
                </div>

                <div class="box1 ml-5">
                    <img class="w-full h-full" src='@/assets/cliqueD4.png' alt="">
                    <div class="content">
                        <div class="flex w-full items-center" style="justify-content: center">
                            <div style="font-size:20px">{{ weightedAverage }}</div>
                            <div class="ml-3" style="font-size:14px">{{getLanguage("分", language)}}</div>
                            <a-tooltip placement="topLeft">
                                <template #title>
                                    <div style="background: #fff">
                                        {{ langObj['针对运行中的设备, 状态得分的加权平均数计算为全厂健康分数, 健康设备记100分, 可用设备记80分, 警戒设备记60分, 故障设备记30分'] }}
                                    </div>
                                </template>
                                <question-circle-outlined style="font-size:14px" class="ml-2" />
                            </a-tooltip>
                        </div>
                        <div class="w-full" style="text-align: center; color: #fff">
                            {{ langObj['全厂运行设备健康分数'] }}
                        </div>
                    </div>
                </div>

            </div>


            <div class="title flex items-center mt-7">
                <div class="shu mr-3"></div>
                <span style="font-size:20px"> {{ langObj['设备健康状态统计'] }}</span>
            </div>

            <div class="w-full">
                <div class="w-full flex ">
                    <div class="flex-1"></div>
                    <div class="mr-5">
                        <a-select ref="select" v-model:value="conditionSelectFac" style="width: 120px" size="small"
                            @change="getConditionChartData" :dropdownMatchSelectWidth="false">
                            <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
        item.factoryName
    }}</a-select-option>
                        </a-select>
                    </div>
                </div>

                <div class="w-full flex items-center">
                    <div v-if="visibleChart1" style="width: 200px; height: 200px" id="chartA"></div>
                    <div class="ml-4">
                        <div class="flex items-center" @click="selectMachineCondition(1)">
                            <div style="width:14px; height:14px; background-color: #61c08f"></div>
                            <div class="ml-2"> {{ langObj['健康'] }}：</div>
                            <div>{{ conditionChartData[0].num }}{{getLanguage("台", language)}}</div>
                        </div>
                        <div class="flex items-center mt-3" @click="selectMachineCondition(2)">
                            <div style="width:14px; height:14px; background-color: #d2de49"></div>
                            <div class="ml-2"> {{ langObj['可用'] }}：</div>
                            <div>{{ conditionChartData[1].num }}{{getLanguage("台", language)}}</div>
                        </div>
                        <div class="flex items-center mt-3" @click="selectMachineCondition(3)">
                            <div style="width:14px; height:14px; background-color: #cd5f3b"></div>
                            <div class="ml-2"> {{ langObj['警戒'] }}：</div>
                            <div>{{ conditionChartData[2].num }}{{getLanguage("台", language)}}</div>
                        </div>
                        <div class="flex items-center mt-3" @click="selectMachineCondition(4)">
                            <div style="width:14px; height:14px; background-color: #921e37"></div>
                            <div class="ml-2"> {{ langObj['故障'] }}：</div>
                            <div>{{ conditionChartData[3].num }}{{getLanguage("台", language)}}</div>
                        </div>
                    </div>
                </div>



            </div>

            <div class="w-full flex items-center mt-7">
                <div class="w-full title flex items-center">
                    <div class="shu mr-3"></div>
                    <span style="font-size:20px">{{ langObj['设备报警记录'] }}</span>
                </div>
                <div class="flex-1"></div>
                <div @click="viewMore" class="flex items-center cursor-pointer" style="color: #ffffffe6;">
                    <span>{{ langObj['查看更多'] }}</span>
                    <right-outlined />
                </div>
            </div>

            <div class="w-full">
                <div class="w-full flex items-center" style="background-color: #020F46;">
                    <div class="table-info" style="opacity: 0.6">{{ langObj['设备名称'] }}</div>
                    <div class="table-info" style="opacity: 0.6">{{ langObj['报警类型'] }}</div>
                    <div class="table-info" style="opacity: 0.6">{{ langObj['严重程度'] }}</div>
                    <div class="table-info" style="opacity: 0.6">{{ langObj['报警时间'] }}</div>
                </div>

                <div class="w-full flex items-center" v-for="(item, index) in machineWarningData" :key="index"
                    :style="{ 'background-color': index % 2 == 0 ? '#03155C' : '#020F46' }">
                    <div class="table-info" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
                        :title="item.machineName">{{  item.machineName }}</div>
                    <div class="table-info">{{  langObj[warningTypeList.find(p => p.value === item.type)?.label] }}</div>
                    <div class="table-info" :style="{ 'color': getMachineWarningName(item.condition)?.color }">{{
                        langObj[getMachineWarningName(item.condition)?.name]
         }}</div>
                    <div class="table-info">{{ item.date }}</div>
                </div>

            </div>

        </div>

        <!-- 右侧 -->
        <div class="flex-1 ml-4">
            <div class="w-full flex items-center">
                <div class="title flex items-center ">
                    <div class="shu mr-3"></div>
                    <span style="font-size:20px">{{ langObj['区域清单'] }}</span>
                </div>

                <span>{{ langObj['显示单位'] }}:</span>

                <a-select ref="select" v-model:value="listSelectFac" style="width: 120px; margin-left: 20px;"
                    @change="getGroupListDetail" :dropdownMatchSelectWidth="false">
                    <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
        item.factoryName
    }}</a-select-option>
                </a-select>

                <div class="mx-4">></div>

                <a-select ref="select" v-model:value="listSelectGroup" style="width: 120px;" @change="selectGroup">
                    <a-select-option v-for="(item, index) in groupList2" :key="index" :value="item.sonID">{{ item.name
                        }}</a-select-option>
                </a-select>

                <div class="flex-1"></div>

                <!-- <a-input v-model:value="value" style="width: 150px" placeholder="请输入区域名称">
                  <template #prefix>
                      <search-outlined style="color: #fff" />
                  </template>
              </a-input> -->

            </div>

            <div class="w-full flex items-center flex-wrap ">
                <div v-for="(item, index) in groupListData" @click="selectGroup2(item)" :key="index"
                    class="box2 mt-4 mr-4">
                    <div class="w-full flex items-center">
                        <div class="shu"></div>
                        <div class="size18 ml-2" :title="item.name"
                            style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{ item.name }}
                        </div>
                    </div>

                    <div class="heng"></div>

                    <div class="content flex items-center w-full">
                        <div>
                            <div class="w-full flex items-center" style="text-align: left;">
                                <div style="width: 160px;">
                                    <div class="size14 opc8">{{ langObj['监测设备'] }}</div>
                                    <div class="size18" style="color: #06DEF5;">{{ item.machineNum }}{{getLanguage("台", language)}}</div>
                                </div>
                                <div style="width: 160px;">
                                    <div class="size14 opc8">{{ langObj['监测测点'] }}</div>
                                    <div class="size18" style="color: #06DEF5;">{{ item.sensorNum }}{{getLanguage("个", language)}}</div>
                                </div>
                            </div>

                            <div class="w-full flex items-center" style="text-align: left;">
                                <div style="width: 160px;">
                                    <div class="size14 opc8">{{ langObj['运行设备'] }}</div>
                                    <div class="size18" style="color: #5BFEB7;">{{ item.machineRun }}{{getLanguage("台", language)}}</div>
                                </div>
                                <div style="width: 160px;">
                                    <div class="size14 opc8">{{ langObj['运行率'] }}</div>
                                    <div class="size18" style="color: #5BFEB7;">{{ item.machineRate }}</div>
                                </div>
                            </div>

                            <div class="w-full flex items-center" style="text-align: left;">
                                <div style="width: 160px;">
                                    <div class="size14 opc8">{{ langObj['报警设备'] }}</div>
                                    <div class="size18" style="color: #B81212;">{{ item.warningMachineNum }}{{getLanguage("台", language)}}</div>
                                </div>
                                <div style="width: 160px;">
                                    <div class="size14 opc8">{{ langObj['未处理报警'] }}</div>
                                    <div class="size18" style="color: #DDD246;">{{ item.noHandleNum }}{{getLanguage("条", language)}}</div>
                                </div>
                            </div>
                        </div>

                        <img src="@/assets/factory-group.png">

                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 厂级大屏-设备 -->
    <div v-if="selectModel === 2" class="w-full flex px-3 py-2"
        style="background-color: #000A31; color: #fff; min-height: 92vh;">
        <!-- 左侧 -->
        <div class="flex flex-col">
            <div class="title flex items-center">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">{{langObj['设备诊断']}}</span>
            </div>

            <div class="mt-4 flex items-center">
                <div>
                    <div v-if="visibleChart2" id="chart2A" style="width: 240px; height: 240px;"></div>
                    <div class="mt-4" style="text-align: center;">{{langObj['健康指标']}}</div>
                </div>
                <div>
                    <div v-if="visibleChart2" id="chart2B" style="width: 240px; height: 240px;"></div>
                    <div class="mt-4" style="text-align: center;">{{langObj['振动指标']}}</div>
                </div>
            </div>

            <div class="title flex items-center mt-7">
                <div class="shu mr-3"></div>
                <span style="font-size:20px">{{langObj['设备照片']}}</span>
            </div>


            <pictureListComponent :type="4" :picList="picListAll"></pictureListComponent>

            <div class="w-full flex items-center mt-7">
                <div class="w-full title flex items-center">
                    <div class="shu mr-3"></div>
                    <span style="font-size:20px">{{langObj['设备报警记录']}}</span>
                </div>
                <div class="flex-1"></div>
                <div @click="viewMore" class="flex items-center cursor-pointer" style="color: #ffffffe6;">
                    <span>{{langObj['查看更多']}}</span>
                    <right-outlined />
                </div>
            </div>

            <div class="w-full">
                <div class="w-full flex items-center" style="background-color: #020F46;">
                    <div class="table-info" style="opacity: 0.6">{{langObj['设备名称']}}</div>
                    <div class="table-info" style="opacity: 0.6">{{langObj['报警类型']}}</div>
                    <div class="table-info" style="opacity: 0.6">{{langObj['严重程度']}}</div>
                    <div class="table-info" style="opacity: 0.6">{{langObj['报警时间']}}</div>
                </div>

                <div class="w-full flex items-center" v-for="(item, index) in machineWarningData2" :key="index"
                    :style="{ 'background-color': index % 2 == 0 ? '#03155C' : '#020F46' }">
                    <div class="table-info" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">{{
        item.machineName }}</div>
                    <div class="table-info">{{ warningTypeList.find(p => p.value === item.type)?.label }}</div>
                    <div class="table-info" :style="{ 'color': getMachineWarningName(item.condition)?.color }">{{
        getMachineWarningName(item.condition)?.name }}</div>
                    <div class="table-info">{{ item.date }}</div>
                </div>

            </div>


        </div>

        <!-- 右侧 -->
        <div class="flex-1 ml-4">
            <div class="w-full flex items-center">
                <div class="title flex items-center ">
                    <div class="shu mr-3"></div>
                    <span style="font-size:20px">{{langObj['设备清单']}}</span>
                </div>

                <div class="ml-6">{{langObj['显示单位']}}:</div>
                <a-select ref="select" v-model:value="listSelectFac" style="width: 120px; margin-left: 20px;"
                    @change="getGroupListDetail" :dropdownMatchSelectWidth="false">
                    <a-select-option v-for="(item, index) in factoryList" :key="index" :value="item._id">{{
        item.factoryName
    }}</a-select-option>
                </a-select>

                <div class="mx-4">></div>

                <a-select ref="select" v-model:value="listSelectGroup" style="width: 120px;" @change="selectGroup"
                    :dropdownMatchSelectWidth="false">
                    <a-select-option v-for="(item, index) in groupList2" :key="index" :value="item.sonID">{{ item.name
                        }}</a-select-option>
                </a-select>

                <div class="flex-1"></div>

                <a-select ref="select" v-model:value="showSearchModel.follow" style="min-width: 120px;"
                    @change="getMachineListShow">
                    <a-select-option :value="null">
                        <div class="flex items-center">
                            <img class="picIcon" src="@/assets/factory-machine-1.png" alt="">
                            <div class="ml-2">{{langObj['全部']}}</div>
                        </div>
                    </a-select-option>
                    <a-select-option :value="true">
                        <div class="flex items-center">
                            <img class="picIcon" src="@/assets/factory-machine-1.png" alt="">
                            <div class="ml-2">{{langObj['关注']}}</div>
                        </div>
                    </a-select-option>
                    <a-select-option :value="false">
                        <div class="flex items-center">
                            <img class="picIcon" src="@/assets/factory-machine-2.png" alt="">
                            <div class="ml-2">{{langObj['未关注']}}</div>
                        </div>
                    </a-select-option>
                </a-select>

                <!-- <a-select
                  ref="select"
                  v-model:value="value"
                  style="width: 120px; margin-left:10px;"
              >
                  <a-select-option value="">
                      <div class="flex items-center">
                          <img class="picIcon" src="@/assets/factory-machine-3.png" alt=""><div class="ml-2">内容</div>
                      </div>
                  </a-select-option>
              </a-select> -->

                <!-- <a-select
                  ref="select"
                  v-model:value="value"
                  style="width: 120px; margin-left:10px;"
              >
                  <a-select-option value="">
                      <div class="flex items-center">
                          <img class="picIcon" src="@/assets/factory-machine-4.png" alt=""><div class="ml-2">行列</div>
                      </div>
                  </a-select-option>
              </a-select>

              <a-select
                  ref="select"
                  v-model:value="value"
                  style="width: 120px; margin-left:10px;"
              >
                  <a-select-option value="">
                      <div class="flex items-center">
                          <img class="picIcon" src="@/assets/factory-machine-5.png" alt=""><div class="ml-2">排序</div>
                      </div>
                  </a-select-option>
              </a-select> -->

                <a-select ref="select" v-model:value="showSearchModel.status" style="width: 120px; margin-left:10px;"
                    @change="getMachineListShow">
                    <a-select-option :value="null">{{langObj['运行状态']}}</a-select-option>
                    <a-select-option :value="1">{{langObj['运行']}}</a-select-option>
                    <a-select-option :value="2">{{langObj['停机']}}</a-select-option>
                </a-select>

                <a-select ref="select" v-model:value="showSearchModel.codnition" style="width: 120px; margin-left:10px;"
                    @change="getMachineListShow">
                    <a-select-option :value="null">{{langObj['健康状态']}}</a-select-option>
                    <a-select-option :value="1">{{langObj['健康']}}</a-select-option>
                    <a-select-option :value="2">{{langObj['可用']}}</a-select-option>
                    <a-select-option :value="3">{{langObj['警戒']}}</a-select-option>
                    <a-select-option :value="4">{{langObj['故障']}}</a-select-option>
                </a-select>


            </div>

            <div class="w-ful mt-5 box3">
                <div class="w-full flex items-center">
                    <environment-outlined style="color: #06DEF5; font-size: 14px;" />
                    <div class="ml-2" style="font-size: 16px;">{{ groupInfo.name }}</div>
                    <div class="ml-2" style="font-size: 12px;">({{langObj['设备总数']}}:{{ groupInfo.machineNum }}{{langObj['台']}} | {{langObj['运行数']}}:{{
        groupInfo.machineRun }}{{langObj['台']}} | {{langObj['报警设备']}}:{{ groupInfo.warningMachineNum }}{{langObj['台']}})</div>
                    <div class="flex-1"></div>
                    <!-- <div style="font-size: 12px;">收起</div> -->
                </div>

                <div class="flex items-center flex-wrap ">
                    <div @click="selectMachine(item)" v-for="(item, index) in machineListShow" :key="index"
                        :style="{ 'background': item.id === machine_id ? '#001672' : '#020F46', 'border-color': item?.workStableStatus?.condition ? getMachineWarningName(item?.workStableStatus?.condition).color : '#0d53b7cc' }"
                        class="box4 cursor-pointer mt-3 mr-3">
                        <div class="w-full flex items-center">
                            <div class="shu"></div>
                            <div class="size14 ml-2" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; " :title="item.machineName">{{ item.machineName }}</div>
                            <div class="flex-1"></div>
                            <img class="picIcon" @click="follow(item, false)" v-if="item.follow"
                                src="@/assets/factory-machine-1.png" alt="">
                            <img class="picIcon" @click="follow(item, true)" v-if="!item.follow"
                                src="@/assets/factory-machine-2.png" alt="">
                        </div>

                        <div class="heng"></div>

                        <div class="w-full flex items-center" style="margin-top: 5px;">
                            <div class="flex-1 flex" style="justify-content: center;">
                                <img style="height: 72px;" :src="item.url" alt="">
                            </div>

                            <div style="margin-left: 15px;">
                                <div class="flex items-center" v-if="item.workStatus && item.workStatus.wkStatus">
                                    <div class="line">
                                        <div class="line animation"></div>
                                    </div>
                                    <span class="text-gray-50 pl-2">{{langObj['运行']}}</span>
                                </div>
                                <div class="flex items-center" v-else>
                                    <div class="down">
                                        <div class="down animation"></div>
                                    </div>
                                    <span class="text-gray-50 pl-2">{{langObj['停机']}}</span>
                                </div>

                                <div class="flex items-center mt-2">
                                    <div class="line"
                                        :style="{ 'background-color': getMachineWarningName(item?.workStableStatus?.condition).color }">
                                        <div class="line animation"
                                            :style="{ 'background-color': getMachineWarningName(item?.workStableStatus?.condition).color }">
                                        </div>
                                    </div>
                                    <span class="text-gray-50 pl-2">{{
                                        langObj[getMachineWarningName(item?.workStableStatus?.condition).name ]}}</span>
                                </div>

                                <!-- <div class="flex items-center">
                                  <div class="down">
                                  </div>
                                  <span class="text-gray-50 pl-2">停机</span>
                              </div> -->
                            </div>
                        </div>

                        <div class="w-full items-center flex" style="margin-top: 5px">
                            <span v-if="machine_info?.workStatus?.onLine">{{langObj['累计运行']}}</span>
                            <div class="ml-2" style="color: #4EFAEE;" v-if="machine_info?.workStatus?.onLine">{{
                                machine_info.workStatus.onLine }}{{langObj['小时']}}</div>
                            <div class="flex-1"></div>
                            <div style="max-width: 110px; overflow: hidden;white-space: nowrap; text-overflow: ellipsis;"
                                :title="item.faultTitle">{{ langObj[item.faultTitle]?langObj[item.faultTitle]:item.faultTitle }}</div>
                        </div>

                        <div class="w-full items-center flex">
                            <span>{{langObj['速度均方根']}}</span>
                            <div class="ml-2" style="color: #4EFAEE;">{{ Number(item.sz)?.toFixed(2) }}mm/s</div>
                            <div class="flex-1"></div>
                            <span>{{langObj['温度']}}</span>
                            <div class="ml-2" style="color: #4EFAEE;">{{ item.temp }}℃</div>
                        </div>




                    </div>
                </div>
            </div>


        </div>

    </div>


</template>

<script lang="ts" setup>
import { defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import pictureListComponent from "../components/picture-list.vue";
import { langList } from "../../common/lang";
import { getRealFactoryIdListById } from "../../common/tools";
import { getLanguage } from "../../common/translate"
const router = useRouter()
// 区域或者设备大屏显示     1:区域  / 2:设备
let selectModel = ref(1)

let factory_id = ''
let factoryList: any = ref([])
let totalTime = ref(0)
let weightedAverage: any = ref(0)
let groupList: any = ref([])
let machineList: any = ref([])
let sensorList: any = ref([])
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang()

let conditionSelectFac: any = ref('')
let conditionChartData: any = ref([      //设备健康状态统计 数据
    { label: langObj.value['健康'], num: 0, color: '#61c08f' },
    { label: langObj.value['可用'], num: 0, color: '#d2de49' },
    { label: langObj.value['警戒'], num: 0, color: '#cd5f3b' },
    { label: langObj.value['故障'], num: 0, color: '#921e37' },
])
let machineWarningData: any = ref([])

let listSelectFac: any = ref('')
let listSelectGroup: any = ref('')

let value = ref('')

let visibleChart1 = ref(true)
let visibleChart2 = ref(true)



onMounted(() => {
    getLang()
    init()
})

const init = () => {
    factory_id = localStorage.getItem('factory_id') || ''
    conditionSelectFac.value = factory_id
    listSelectFac.value = factory_id
    loadFactory()
}

// 获取工厂
const loadFactory = async () => {
    let groups: any = []
    factoryList.value = []
    const config = {
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get("/api/factories", config)
    if (result.data && result.data.length) {

        factoryList.value = result.data.filter((p: any) => p._id === factory_id || p.parentId === factory_id)


        //获取运行时间
        totalTime.value = 0
        let facL = factoryList.value.filter((p: any) => p.parentId === factory_id)
        if (facL && facL.length) {
            let time: any = 0
            facL.map((d: any) => {
                let targetTime: any = new Date(d.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
                let currentTime: any = new Date(); // 当前时间
                let time2 = Math.floor(
                    (currentTime - targetTime) / (1000 * 60 * 60)
                );
                if (time2 > time) {
                    time = time2
                }
            })
            totalTime.value = time

        } else {
            let info = factoryList.value.find((p: any) => p._id === factory_id)
            let targetTime: any = new Date(info.createAt); // 假设目标时间是 2023 年 7 月 16 日 12:00:00 UTC
            let currentTime: any = new Date(); // 当前时间
            totalTime.value = Math.floor(
                (currentTime - targetTime) / (1000 * 60 * 60)
            );
        }

        result.data.forEach((element: any) => {
            element.groups = element.groups.map((t: any) => ({
                ...t,
                Name: element.factoryName + "-" + t.name,
            }));
            groups = groups.concat(element.groups);
        });

        getMain(groups);
    }

}


// 获取区域列表
const getMain = async (groups: any) => {
    const config = {
        params: {
            factoryIds: getRealFactoryIdListById(factory_id) 
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/external/machines/workstatus", config)

    weightedAverage.value = 0
    groupList.value = [];
    machineList.value = [];
    sensorList.value = [];

    if (result && result.data) {
        let faultResult = await request.post('/api/faultinfos', { FeatureType: '' })
        result.data.map((d: any) => {
            let sz = 100000000000
            let temp = 100000000000
            d.quota && d.quota.map((q: any) => {
                if (q.values && q.values.length) {
                    let szInfo = q.values.find((p: any) => p.type === '速度Z')
                    if (szInfo) {
                        if (szInfo.value <= sz) {
                            sz = szInfo.value
                        }
                    }
                    let tempInfo = q.values.find((p: any) => p.type === '温度')
                    if (tempInfo) {
                        if (tempInfo.value <= temp) {
                            temp = tempInfo.value
                        }
                    }
                }
            })

            d.temp = temp == 100000000000 ? 0.00 : temp?.toFixed(2)
            d.sz = sz == 100000000000 ? 0 : sz

            let faultTitle = '设备运行正常'
            if (d.workStableStatus && d.workStableStatus.scores && Object.values(d.workStableStatus.scores).length) {
                let score = 100
                let name = ''
                for (let i in d.workStableStatus.scores) {
                    let value = d.workStableStatus.scores[i]
                    if (value <= score) {
                        score = value
                        name = i
                    }
                }
                if(score < 80) {
                    faultTitle = faultResult.data.find((p: any) => p.scoreName === name)?.segmentFault
                }
                
            }
            d.faultTitle = faultTitle
        })



        let weightAll = 0
        let count = 0
        groups.forEach((item2: any) => {
            item2.machines = result.data.filter(
                (t2: any) => t2.groupId == item2.sonID
            );
            item2.machines.map((d: any) => {
                sensorList.value = sensorList.value.concat(d.sensors)
                if (!d.workStatus) {
                    d.workStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }
                if (!d.workStableStatus) {
                    d.workStableStatus = {
                        condition: 0,
                        wkStatus: 0
                    }
                }

                // if (d.workStatus.wkStatus) {
                    if (d.workStableStatus.condition === 0) {
                        weightAll = weightAll + 100
                    }
                    if (d.workStableStatus.condition >= 1 && d.workStableStatus.condition <= 2) {
                        weightAll = weightAll + 80
                    }
                    if (d.workStableStatus.condition >= 3 && d.workStableStatus.condition <= 5) {
                        weightAll = weightAll + 60
                    }
                    if (d.workStableStatus.condition >= 6 && d.workStableStatus.condition <= 7) {
                        weightAll = weightAll + 30
                    }
                    count++
                // }
            })
            machineList.value = machineList.value.concat(item2.machines)
            groupList.value.push(item2)
        })
        weightedAverage.value = count ? (weightAll / count).toFixed(2) : 0
    }


    setTimeout(() => {
        getConditionChartData()
        getMachineWarningChartData()
        getGroupListDetail()
    }, 500);

}

// 获取运行设备数及运行率
const getRunMachine = (ev: any) => {
    let num: any = 0
    let rate: any = 0
    if (ev && ev.length) {
        ev.map((d: any) => {
            if (d.workStatus.wkStatus) {
                num++
            }
        })
        rate = ((num / ev.length) * 100).toFixed(2)
    }

    return { num, rate }
}

// 获取设备健康状态统计/设备运行状态统计 数据
const getConditionChartData = () => {
    let n1 = 0, n2 = 0, n3 = 0, n4 = 0
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === conditionSelectFac.value)
    if (facList.length) {
        facList.map((f: any) => {
            f.groups && f.groups.map((g: any) => {
                g.machines && g.machines.map((m: any) => {
                    if (m.workStableStatus.condition === 0) {
                        n1++
                    }
                    if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                        n2++
                    }
                    if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                        n3++
                    }
                    if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                        n4++
                    }
                })
            })
        })
    } else {
        let f = factoryList.value.find((p: any) => p._id === conditionSelectFac.value)
        f?.groups && f.groups.map((g: any) => {
            g.machines && g.machines.map((m: any) => {
                if (m.workStableStatus.condition === 0) {
                    n1++
                }
                if (m.workStableStatus.condition >= 1 && m.workStableStatus.condition <= 2) {
                    n2++
                }
                if (m.workStableStatus.condition >= 3 && m.workStableStatus.condition <= 5) {
                    n3++
                }
                if (m.workStableStatus.condition >= 6 && m.workStableStatus.condition <= 7) {
                    n4++
                }
            })
        })
    }


    conditionChartData.value = [
        { label: langObj.value['健康'], num: n1, color: '#61c08f' },
        { label: langObj.value['可用'], num: n2, color: '#d2de49' },
        { label: langObj.value['警戒'], num: n3, color: '#cd5f3b' },
        { label: langObj.value['故障'], num: n4, color: '#921e37' },
    ]

    initConditionChart()
}

// 绘制设备健康状态统计图
const initConditionChart = () => {
    if (!document.getElementById("chartA")) return
    let myChart = echarts.init(document.getElementById("chartA") as HTMLDivElement);

    let legendData = conditionChartData.value.map((t: any) => t.label)
    let DData = conditionChartData.value.map((t: any) => ({
        value: t.num,
        name: t.label + langObj.value["设备数"]+"：" + t.num
    }))

    let options = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
            orient: "vertical",
            right: 10,
            top: 30,
            textStyle: {
                color: "#ffffff",
                fontSize: 12,
            },
            data: legendData,
            selected: {
                健康: true,
            },
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            style: {
                text: langObj.value["总设备数"] +":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num),
                textAlign: "center",
                fontSize: 16,
                fill: "#ffffff",
            },
            tooltip: {
                show: false,
            },
        },
        series: [
            {
                name: "",
                type: "pie",
                radius: ["65%", "85%"],
                center: ["50%", "50%"],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: "center",
                },
                emphasis: {
                    label: {
                        show: false,
                        fontSize: 12,
                        fontWeight: "bold",
                        formatter: "{b}\n{c} ({d}%)",
                    },
                },
                labelLine: {
                    show: false,
                },
                data: DData,
            },
        ],
        color: ['rgb(28, 196, 139)', 'rgb(206, 224, 8)', 'rgb(223, 87, 45)', 'rgb(162, 3, 53)']
    };

    myChart.setOption(options);
    myChart.on("mouseover", (params: any) => {
        const { name } = params;
        // options.graphic.style.text = name;
        if(name){
            let nameArr = name.split("：");
            options.graphic.style.text = langObj.value[nameArr[0]] +"："+ nameArr[1];
        }
        myChart.setOption(options);
    });
    myChart.on("mouseout", () => {
        options.graphic.style.text = langObj.value["总设备数"]+":" + (conditionChartData.value[0].num + conditionChartData.value[1].num + conditionChartData.value[2].num + conditionChartData.value[3].num)
        myChart.setOption(options);
        myChart.setOption(options);
    });

}

// 获取设备报警记录
let warningTypeList = [
    { label: '智能诊断', value: 1 },
    { label: '门限报警', value: 4 },
]
const getMachineWarningChartData = async () => {
    machineWarningData.value = []
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === factory_id)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [factory_id]
    }
    let config: any = {
        params: {
            skip: 1,
            take: 7,
            factoryIds,
            typeList: [1, 4]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)

    if (result && result.data) {
        machineWarningData.value = result.data.list
        machineWarningData.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
    }
}

// 获取严重程度名称颜色
const getMachineWarningName = (condition: any) => {
    let name = '健康'
    let color = '#61c08f'
    if (condition >= 1 && condition <= 2) {
        name = '可用'
        color = '#d2de49'
    }
    if (condition >= 3 && condition <= 5) {
        name = '警戒'
        color = '#cd5f3b'
    }
    if (condition >= 6 && condition <= 7) {
        name = '故障'
        color = '#921e37'
    }

    return { name, color }
}

// 获取区域清单
let groupListData: any = ref([])
let groupList2: any = ref([{ name: langObj.value['全部区域'], sonID: '' }])
const getGroupListDetail = async () => {
    let facList = factoryList.value.filter((p: any) => p.parentId && p.parentId === listSelectFac.value)
    let factoryIds = []
    if (facList && facList.length) {
        factoryIds = facList.map((p: any) => p._id)
    } else {
        factoryIds = [listSelectFac.value]
    }
    groupList2.value = [{ name: langObj.value['全部区域'], sonID: '' }]
    //获取未处理事件
    let config: any = {
        params: {
            skip: 1,
            take: 1000000,
            factoryIds,
            typeList: [1],
            handleStatus: 1,
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    let noHandeleList: any = []
    if (result && result.data && result.data.list) {
        noHandeleList = result.data.list
    }

    let groList: any = []
    factoryIds.map((facId: any) => {
        let facInfo = factoryList.value.find((p: any) => p._id === facId)
        let list1: any = []
        groupList2.value = groupList2.value.concat(facInfo.groups)
        list1 = facInfo.groups

        list1 && list1.map((g: any) => {
            let machineNum = 0
            let sensorNum = 0
            let machineRun = 0
            machineNum = machineNum + (g.machines ? g.machines.length : 0)

            g.machines && g.machines.map((m: any) => {
                sensorNum = sensorNum + (m.sensors ? m.sensors.length : 0)
                if (m.workStatus && m.workStatus.wkStatus) {
                    machineRun++
                }
            })

            g.machineNum = machineNum
            g.sensorNum = sensorNum
            g.machineRun = machineRun
            g.machineRate = machineNum ? (((machineRun / machineNum) * 100).toFixed(0) + '%') : '0%'


            let noHandleNum = 0
            let warningMachineNum = 0
            g.machines && g.machines.map((m: any) => {
                let noL = noHandeleList.filter((p: any) => p.machineId === m.id)
                if (noL && noL.length) {
                    noHandleNum = noHandleNum + noL.length
                    let conditionL = [
                        { value: 0, list: [0] },
                        { value: 1, list: [1, 2] },
                        { value: 2, list: [3, 4, 5] },
                        { value: 3, list: [6, 7] },
                    ]

                    if (m.workStableStatus && m.workStableStatus.condition) {
                        let c1: any = conditionL.find((p: any) => p.list.includes(m.workStableStatus.condition))?.value
                        let c2: any = conditionL.find((p: any) => p.list.includes(noL[0].condition))?.value
                        if (c1 >= c2) {
                            warningMachineNum++
                        }
                    }
                }
            })
            g.noHandleNum = noHandleNum
            g.warningMachineNum = warningMachineNum
        })
        groList = groList.concat(list1)
    })

    groupListData.value = groList
}

// 选择区域
let groupInfo: any = ref({ machines: [] })
let machineListShow: any = ref([])
let showSearchModel: any = ref({
    follow: null,
    codnition: null,
    status: null
})
let machine_id = ref('')
const selectGroup = async (ev: any) => {
    if (listSelectGroup.value) {
        selectModel.value = 2
        visibleChart2.value = false
        visibleChart2.value = true

        groupInfo.value = groupListData.value.find((p: any) => p.sonID === listSelectGroup.value)

        if (groupInfo.value.machines?.length > 0) {
            getSensorList(groupInfo.value.machines[0])
            getRandarData(groupInfo.value.machines[0])
            getMachineWarningData(groupInfo.value.machines[0])
            machine_id.value = groupInfo.value.machines[0].id
        }

        getMachineListShow()
    } else {
        selectModel.value = 1
        visibleChart1.value = false
        visibleChart1.value = true
        setTimeout(() => {
            getConditionChartData()
        }, 500);
    }

}

const selectGroup2 = (ev: any) => {
    listSelectGroup.value = ev.sonID
    selectGroup(listSelectGroup.value)
}

//筛选显示设备
const getMachineListShow = () => {
    let list = JSON.parse(JSON.stringify(groupInfo.value.machines))

    if (showSearchModel.value.follow == true) {
        list = list.filter((p: any) => p.follow === showSearchModel.value.follow)
    }
    if(showSearchModel.value.follow == false) {
        list = list.filter((p: any) => !p.follow)
    }


    if (showSearchModel.value.status) {
        if (showSearchModel.value.status === 1) {
            list = list.filter((p: any) => p.workStatus && p.workStatus.wkStatus === 1)
        }
        if (showSearchModel.value.status === 2) {
            list = list.filter((p: any) => !p.workStatus || p.workStatus.wkStatus === 0)
        }
    }
    if (showSearchModel.value.codnition) {
        if (showSearchModel.value.codnition === 1) {
            list = list.filter((p: any) => !p.workStableStatus || p.workStableStatus.condition === 0)
        }
        if (showSearchModel.value.codnition === 2) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 1 && p.workStableStatus.condition <= 2)
        }
        if (showSearchModel.value.codnition === 3) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 3 && p.workStableStatus.condition <= 5)
        }
        if (showSearchModel.value.codnition === 4) {
            list = list.filter((p: any) => p.workStableStatus && p.workStableStatus.condition >= 6 && p.workStableStatus.condition <= 7)
        }
    }

    list.map((p: any) => {
        if (p.pictures?.length > 0) {
            p.url = p.pictures[0].url;
        }
    });

    machineListShow.value = list
}


// 获取雷达图数据
const getRandarData = async (machine: any) => {
    let faultnamesSearch = {
        params: { machineId: machine.id },
        headers: {
            requestId: uuidv4(),
        },
    }
    let result = await request.get('/api/machines/faultnames', faultnamesSearch)
    let faultName = result.data.faultNames
    let faultNameII = result.data.faultNameExpects

    let Slist: any = []
    let Tlist: any = []

    if (machine.workStableStatus && machine.workStableStatus.scores) {
        for (let index in machine.workStableStatus.scores) {
            const item = machine.workStableStatus.scores[index];
            if (index.includes('S')) {
                Slist.push({
                    quota: index,
                    score: item,
                    text: langObj.value[faultName.find((p: any) => p.column === index)?.title] || faultName.find((p: any) => p.column === index)?.title
                })
            }

            if (index.includes('T')) {
                Tlist.push({
                    quota: index,
                    score: item,
                    text: langObj.value[faultNameII.find((p: any) => p.column === index)?.title] || faultNameII.find((p: any) => p.column === index)?.title,
                })
            }
        }
    }

    setTimeout(() => {
        initRandarChart(Tlist, 'chart2A')
        initRandarChart(Slist, 'chart2B')
    }, 500);
}

// 雷达图绘制
const initRandarChart = (dataList: any, id: any) => {
    const ec = echarts as any;
    if (!document.getElementById(id)) return
    let myChart = ec.init(document.getElementById(id));
    myChart.clear()

    if (!dataList.length) return

    let data: any = []
    let error: any = []
    let indicator: any = []
    dataList.map((d: any) => {
        data.push(d.score)
        error.push(60)
        let color = ''
        if (d.score >= 0 && d.score < 60) {
            color = '#CD5F3B'
        }
        if (d.score >= 60 && d.score < 80) {
            color = '#D2DE49'
        }
        if (d.score >= 80 && d.score <= 100) {
            color = '#fff'
        }
        let name = ''
        if(language.value == "Chinese" && d.text) {
        if (d.text.length > 8) {
            name = (d.text.substring(0, 4) + '\n' + d.text.substring(4, 8) + '\n' + d.text.substring(8, d.text.length))
        } else if (d.text.length > 4 && d.text.length <= 8) {
            name = d.text.substring(0, 4) + '\n' + d.text.substring(4, d.text.length)
        } else {
            name = d.text
        }
        } 

        if(language.value == "English" && d.text) {
        let list1 = d.text.split(' ')

        let str:any = ''
        let num = 0
        let sy = ''
        list1.map((n:any, i:any) => {
            if(i==0) {
            if(n.length <= 10) {
                num = n.length
                str = n
            } else {
                num = 10
                let a1 = n.substring(0,10)
                sy = n.slice(10)
                str = a1
            }
            } else {

            if(sy) {
                str = str + '\n' ;
                let n2 = sy.length + n.length
                if(n2 <= 10) {
                num = n2
                str = str + sy + ' ' + n
                sy = ''
                } else {
                num = 10
                str = str + sy
                let n2 = 10 - sy.length >=0 ? 10 - sy.length : 0

                let a1 = n.substring(0,n2)
                sy = n.slice(10)
                str = str + a1
                }
            } else {
                if(num + n.length <= 10) {
                str = str + ' ' + n
                num = num + n.length
                } else {
                str = str + '\n'  + n
                num = n.length
                }
            }
            }
        })
        name = str
        }

        indicator.push({
            color,
            max: 100,
            text:name
        })
    })

    const option = {
        title: {
            text: ''
        },
        tooltip: {
            position: { right: '-50px' }
        },
        radar: [
            {
                indicator: indicator,
                center: ['50%', '50%'],
                radius: 50,
                startAngle: 90,
                splitNumber: 5,
                axisName: {
                    formatter: '{value}',
                    fontSize: 10,
                },
                name: {
                    formatter: '{value}',
                    textStyle: {
                        color: 'rgba(46, 214, 157, 1)',
                        fontSize: 11
                    }
                },
                splitArea: {
                    areaStyle: {
                        color: [
                            '#605969',
                            '#453A47',
                        ]
                    }
                },
                axisLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.1)'
                    }
                },
                splitLine: {
                    lineStyle: {
                        color: [
                            'rgb(241,243,250)'
                        ],
                        width: 0
                    }
                }
            }
        ],
        series: [
            {
                name: '当前值',
                type: 'radar',
                emphasis: {
                    lineStyle: {
                        width: 4
                    }
                },
                symbolSize: 2, // 拐点的大小
                data: [
                    {
                        value: data,
                        lineStyle: {
                            width: 1,
                            color: 'rgba(247,220,225,0.1)',
                            normal: {
                                type: 'dotted'
                            }
                        },
                        itemStyle: {
                            color: '#FF4E13',
                            borderWidth: 5
                        },
                        areaStyle: {
                            color: '#F7DCE1'
                        }
                    }
                ]
            },
            {
                name: '预警值',
                type: 'radar',
                emphasis: {
                    lineStyle: {
                        width: 1
                    }
                },
                symbolSize: 2, // 拐点的大小
                data: [
                    {
                        value: error,
                        name: '',
                        lineStyle: {
                            width: 2,
                            color: 'rgba(255, 25, 59, 0.2)'
                        },
                        itemStyle: {
                            color: 'rgba(255, 25, 59, 0.2)',
                            borderWidth: 0
                        }
                    }
                ]
            }
        ]
    }

    option && myChart.setOption(option)
}

// 获取设备报警记录
let machineWarningData2: any = ref([])
const getMachineWarningData = async (machine: any) => {
    let config: any = {
        params: {
            skip: 1,
            take: 7,
            machineId: machine.id,
            typeList: [1, 4]
        },

        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/workflows', config)
    if (result && result.data) {
        machineWarningData2.value = result.data.list
        machineWarningData2.value.map((d: any) => {
            d.date = d.date.substring(5, 16)
        })
    }
}


//选择设备
let picList: any = ref([])
let picListAll: any = ref([])
let machine_info: any = ref({})
const selectMachine = (ev: any) => {
    machine_info.value = ev
    machine_id.value = ev.id
    getSensorList(ev)
    getRandarData(ev)
    getMachineWarningData(ev)
}

const getSensorList = async (machine: any) => {
    picList.value = [];
    if (machine.pictures?.length > 0) {
        picList.value.push({ name: machine.machineName, url: machine.pictures[0].url });
    }

    let config: any = {
        params: {
            machineId: machine.id,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get("/api/sensors", config);

    if (result && result.status === 200) {
        result.data.map((d: any) => {
            if (d.pictures) {
                d.pictures.map((p: any) => {
                    picList.value.push({
                        name: d.sensorPlace,
                        url: p.url
                    })
                })

            }
        })
    }

    picListAll.value = picList.value

};



//关注、取关
const follow = async (ev: any, value: any) => {
    ev.follow = value
    let form = {
        machineId: ev.id,
        follow: value
    }

    let result = await request.post('/api/machines/follow', form)
    if(result?.data) {
        let info = groupInfo.value.machines.find((p:any) => p.id === ev.id)
        if(info) {
            info.follow = value
        }
    }
}


// 查看更多
const viewMore = () => {
    router.push('/warning-manage/warning-list')
}

</script>

<style lang="less" scoped>
.enW{
    width: 150px;
}
/deep/ .ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-select-arrow {
    color: #fff
}

/deep/ .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-input-affix-wrapper {
    background-color: #04165D;
    border: 1px solid #0D53B7;
    color: #fff;
}

/deep/ .ant-input {
    background-color: #04165D;
    color: #fff;
}


.title {
    width: 350px;
    height: 38px;
    font-size: 20px;
    color: #06DEF5;
    background: linear-gradient(90deg, #1c39c88f 0%, #00148d00 100%);

    .shu {
        width: 2px;
        height: 20px;
        background-color: #fff;
    }
}

.box1 {
    width: 230px;
    height: 80px;
    position: relative;

    .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 10px;
        color: #06DEF5;
    }
}

.table-info {
    flex: 1;
    height: 40px;
    line-height: 40px;
    text-align: center;
}

.box2 {
    width: 480px;
    height: 225px;
    background: #07249933;
    border: 1px solid #0d53b7cc;
    padding: 15px 10px 0 10px;

    .shu {
        width: 2px;
        height: 15px;
        background-color: #13DCEA;
    }

    .heng {
        margin-top: 15px;
        width: 100%;
        border: 1px solid #ffffff4d;
    }

    .content {
        margin-top: 9px;
        padding-left: 10px;

        img {
            width: 120px;
            height: 142px;
            // margin-left: 40px;
        }
    }
}

.box3 {
    border: 1px solid #0d53b7;
    border-radius: 2px;
    padding: 16px 10px;

    .box4 {
        width: 320px;
        height: 168px;
        border: 1.5px solid #0d53b7cc;
        padding: 10px;

        .shu {
            width: 2px;
            height: 10px;
            background-color: #13DCEA;
        }

        .heng {
            margin-top: 5px;
            width: 100%;
            border: 1px solid #ffffff4d;
        }

    }
}



.size18 {
    font-size: 18px;
}

.size14 {
    font-size: 14px;
}

.opc8 {
    opacity: 0.8
}

.picIcon {
    width: 14px;
    height: 14px;
}

.line {
    width: 10px;
    height: 10px;
    background-color: #4efaee;
    border-radius: 5px;
}

.down {
    width: 10px;
    height: 10px;
    background-color: #29314d;
    border-radius: 5px;
}

.animation {
    -webkit-animation: twinkling 5s infinite ease-in-out;
    animation: twinkling 5s infinite ease-in-out;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@keyframes twinkling {
    0% {
        transform: scale(1);
        opacity: 0.2;
        filter: alpha(opacity=20);
    }

    50% {
        transform: scale(2);
        opacity: 0.5;
        filter: alpha(opacity=50);
    }

    100% {
        transform: scale(1);
        opacity: 0.2;
        filter: alpha(opacity=20);
    }
}
</style>