<template>
    <div class="w-full">
        <div class="flex justify-center items-center mb-7">
            <div class="btn1" :style="{'background': menuSelect === 1 ? '#072499' : ''}" @click="selectMenu(1)">{{ getLanguage('智能报警工单',language) }}</div>
            <div class="btn1" :style="{'background': menuSelect === 2 ? '#072499' : ''}" @click="selectMenu(2)">{{ getLanguage('门限报警工单',language) }}</div>
        </div>

        <div v-if="menuSelect === 1">
            <EventOrderComponent></EventOrderComponent>
        </div>

        <div v-if="menuSelect === 2">
            <ThresholdAlarmComponent></ThresholdAlarmComponent>
        </div>

    </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, ref } from "vue";
import { getLanguage } from "../../../common/translate";

import EventOrderComponent from "./EventOrder.vue";
import ThresholdAlarmComponent from "./ThresholdAlarm.vue";
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
}
getLang()

let menuSelect = ref(1)

const selectMenu = (ev:any) => {
    menuSelect.value = ev
}

</script>

<style scoped lang="less">
.btn1 {
    padding: 6px 10px;
    border-radius: 2px;
    border: 1px solid #072499;
    cursor: pointer;
}
</style>