<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
  <div class="w-full flex" style="color: #fff">
    <!-- 左侧 -->
    <div style="width: 270px">
      <div class="w-full box p-2" style="width: 270px">
        <a-radio-group v-model:value="type" style="width: 100%" @change="init">
          <a-radio
            v-for="(item, index) in typeList"
            :key="index"
            style="display: block"
            :value="item.value"
            >{{ item.label }}</a-radio
          >
        </a-radio-group>
      </div>

      <div class="w-full mt-4">
        <a-range-picker
          style="width: 100%"
          v-model:value="date"
          @change="init"
          :disabled-date="disabledDate"
          @calendarChange="onCalendarChange"
        />
      </div>

      <div class="box mt-4" style="width: 270px">
        <a-table
          style="width: 100%"
          size="small"
          :row-class-name="getRowClassName"
          :columns="columns"
          :data-source="dataList"
          :pagination="pagination"
          @change="handleTableChange"
          :customRow="details"
        >
          <template #bodyCell="{ column, text, record }">
            <template v-if="column.dataIndex === 'opt'">
              <a @click="downFile(record)" style="color: #00fff4 !important"
                >{{langObj['下载']}}</a
              >
            </template>

            <template v-if="column.dataIndex === 'value'">
              <span v-if="text || text == 0"> {{ text.toFixed(2) }}</span>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <!-- 右侧 -->
    <div class="flex-1 box ml-4 p-3">
      <div class="w-full flex items-center">
        <span>{{langObj['趋势图']}}</span>
        <div class="flex-1"></div>
        <!-- <div class="btn1" @click="getTrendList" style="background-color: #06259A;">新增标记</div>
                <div class="btn1 ml-4" style="background-color: #FF5A5B;">删除标记</div> -->
      </div>

      <div v-if="visible" id="chart1A" style="width: 100%; height: 350px"></div>

      <div class="flex items-center mt-4">
        <div>{{langObj['时域图']}}</div>
        <div class="ml-2">
          {{langObj['有效值']}}：{{ trms }} {{ typeList.find((p) => p.value === type)?.unit }}
        </div>

        <div class="ml-2">{{langObj['时间']}}：{{ markDate }}</div>
      </div>
      <div>
        {{langObj['分析工具']}}：<a-radio-group v-model:value="typeB" button-style="solid">
          <!-- <a-radio :value="1">{{langObj['倍频']}}</a-radio> -->
          <a-radio :value="2">{{langObj['边频']}}</a-radio>
        </a-radio-group>
        <a-button type="link" @click="clearMark('B')">{{langObj['清空标注']}}</a-button>
      </div>
      <div id="chart1B" style="width: 100%; height: 350px"></div>

      <div class="flex items-center mt-4">
        <div>{{langObj['幅值谱图']}}</div>
      </div>
      <div>
        {{langObj['分析工具：']}}<a-radio-group v-model:value="typeC" button-style="solid">
          <a-radio :value="1">{{langObj['倍频']}}</a-radio>
          <a-radio :value="2">{{langObj['边频']}}</a-radio>
        </a-radio-group>
        <a-button type="link" @click="clearMark('C')">{{langObj['清空标注']}}</a-button>
      </div>
      <div id="chart1C" style="width: 100%; height: 350px" class="mb-4"></div>
    </div>
  </div>
</a-config-provider>
</template>

<script lang="ts" setup>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../../common/request";
import { transformDate2, transformDate, getChartDataTool } from "../../../common/tools";
import { v4 as uuidv4 } from "uuid";
import * as echarts from "echarts";
import { message } from "ant-design-vue";
import { Dayjs } from "dayjs";
import { langList } from "../../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
 getLang()


let type = ref(1);
let date: any = ref(null);
let visible = ref(true);
let markDate = ref("");

let myChartA: any = ref(null);
let myChartB: any = ref(null);
let myChartC: any = ref(null);

let typeList = [
  { label: langObj.value["高频加速度(长)"], value: 2, type: "TW", unit: "m/s²" },
  { label: langObj.value["高频加速度"], value: 1, type: "HZ", unit: "m/s²" },
  // {label : '温度', value: 2},
  { label: langObj.value["低频加速度X"], value: 3, type: "LX", unit: "m/s²" },
  { label: langObj.value["低频加速度Y"], value: 4, type: "LY", unit: "m/s²" },
  { label: langObj.value["低频加速度Z"], value: 5, type: "LZ", unit: "m/s²" },
  { label: langObj.value["速度X"], value: 6, type: "SX", unit: "mm/s" },
  { label: langObj.value["速度Y"], value: 7, type: "SY", unit: "mm/s" },
  { label: langObj.value["速度Z"], value: 8, type: "SZ", unit: "mm/s" },
];

const columns = [
  {
    title: langObj.value["时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["值"],
    dataIndex: "value",
  },
  {
    title: langObj.value["操作"],
    dataIndex: "opt",
  },
];

let dataList: any = ref([]);

const props: any = defineProps({
  sensorInfo: null,
});
let sensorInfo: any = ref();
sensorInfo.value = props.sensorInfo;
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let searchModel: any = ref({
  skip: 1,
  take: 20,
  total: 0,
});
let machine_date: any = ref("");
watch(
  () => props.sensorInfo,
  (newValue, oldValue) => {
    sensorInfo.value = props.sensorInfo;
    init();
  }
);

onMounted(() => {
  init();
});

const init = () => {
  console.log(sensorInfo.value,"sensorInfo.value")
  if (!sensorInfo.value || !sensorInfo.value.id) return;
  chartA.value && chartA.value.clear();
  myChartB.value && myChartB.value.clear();
  myChartC.value && myChartC.value.clear();

  getMachineInfo();
};

const getMachineInfo = async () => {
  console.log("sensorInfo.value", sensorInfo.value.machineId);

  let config = {
    params: {
      id: sensorInfo.value.machineId,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  // machine_date
  let result = await request.get(
    "/api/external/machines/workstatus/info",
    config
  );
  // console.log("result22222", result);
  if (result.data && result.data.workStatus && result.data.workStatus.date) {
    machine_date.value = result.data.workStatus.date;
  } else {
    machine_date.value = transformDate(new Date());
  }

  getTrendList();
  getTrendListAll();
};

// 获取趋势图数据(默认十天)
const getTrendList = async () => {
  let begin = "";
  let end = "";
  if (date.value && date.value.length === 2) {
    begin = transformDate2(date.value[0], 1);
    end = transformDate2(date.value[1], 2);
  } else {
    end = transformDate(new Date());
    begin = transformDate(
      new Date(
        new Date().getTime() - 1000 * 60 * 60 * 24 * 10
      )
    );
  }

  let config: any = {
    params: {
      machineId: sensorInfo.value.machineId,
      sensorId: sensorInfo.value.id,
      // begin: transformDate(new Date('2021/01/01 00:00:00')),
      // end: transformDate(new Date()),
      begin,
      end,
      type: typeList.find((p: any) => p.value === type.value)?.type,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/external/singles", config);

  let xData: any = [],
    yData: any = [];
  if (result && result.data) {
    result.data.list.map((d: any) => {
      xData.push(d.date);
      yData.push(d.value || 0);
    });
  }

  visible.value = false;
  visible.value = true;
  setTimeout(() => {
    initChartA({ xData, yData });
  }, 200);
};

//获取所有时间点数据
const getTrendListAll = async () => {
  let begin = "";
  let end = "";
  if (date.value && date.value.length === 2) {
    begin = transformDate2(date.value[0], 1);
    end = transformDate2(date.value[1], 2);
  } else {
    end = transformDate(new Date());
    begin = transformDate(
      new Date(
        new Date().getTime() - 1000 * 60 * 60 * 24 * 10
      )
    );
  }

  let config: any = {
    params: {
      machineId: sensorInfo.value.machineId,
      sensorId: sensorInfo.value.id,
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      begin,
      end,
      type: typeList.find((p: any) => p.value === type.value)?.type,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/external/singles", config);

  if (result && result.data) {
    dataList.value = result.data.list;
    searchModel.value.total = result.data.count;
  }
};

const handleTableChange = (pagination: any) => {
  // 处理分页数据
  searchModel.value.skip = pagination.current;
  searchModel.value.take = pagination.pageSize;
  getTrendListAll();
};

let chartA: any = ref(null);
let optionA: any = ref(null);
let download = ref(false);
const details = (val: any) => {
  return {
    onClick: async (event: any) => {
      setTimeout(() => {
        if (download.value) return;
        if (chartA.value && optionA.value) {
          optionA.value.series[0].markLine = {
            data: [
              {
                name: val.date,
                xAxis: val.date,
                label: {
                  show: true,
                  color: "#fff",
                  formatter: "{b}",
                },
                lineStyle: {
                  color: "red",
                },
              },
            ],
            silent: false,
          };

          chartA.value && chartA.value.setOption(optionA.value);
        }

        markDate.value = val.date;
        getOtherrChartData();
      }, 200);
    },
  };
};

// 趋势图
const initChartA = (data: any) => {
  let { xData, yData } = data;
  xData.reverse();
  yData.reverse();
  const ec = echarts as any;
  if (!document.getElementById("chart1A")) return;
  let myChartA = ec.init(document.getElementById("chart1A"));

  let option = {
    color: ["#00FFF4"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      },
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "filter",
        bottom: "0",
      },
      {
        id: "dataZoomY",
        type: "slider",
        yAxisIndex: [0],
        filterMode: "filter",
        left: "30",
      },
    ],
    xAxis: {
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        showMaxLabel: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3,
        },
      },
      data: xData,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        data: yData,
        type: "line",
      },
    ],
  };

  optionA.value = option;

  if (date && xData.length) {
    markDate.value = xData[xData.length - 1];
    option.series[0].markLine = {
      data: [
        {
          name: markDate.value,
          xAxis: markDate.value,
          label: {
            show: true,
            color: "#fff",
            formatter: "{b}",
          },
          lineStyle: {
            color: "red",
          },
        },
      ],
      silent: false,
    };
    getOtherrChartData();
  }

  myChartA && myChartA.setOption(option);
  chartA.value = myChartA;

  myChartA.getZr().off("click");
  myChartA.getZr().on("click", (params: { offsetX: any; offsetY: any }) => {
    var pointInPixel = [params.offsetX, params.offsetY];
    if (myChartA.containPixel("grid", pointInPixel)) {
      /*此处添加具体执行代码*/
      var pointInGrid = myChartA.convertFromPixel(
        { seriesIndex: 0 },
        pointInPixel
      );
      //X轴序号
      var xIndex = pointInGrid[0];
      //获取当前图表的option
      var op = myChartA.getOption();
      //获得图表中我们想要的数据
      let nowDate = op.xAxis[0].data[xIndex];
      markDate.value = nowDate;

      option.series[0].markLine = {
        data: [
          {
            name: nowDate,
            xAxis: nowDate,
            label: {
              show: true,
              color: "#fff",
              formatter: "{b}",
            },
            lineStyle: {
              color: "red",
            },
          },
        ],
        silent: false,
      };

      myChartA && myChartA.setOption(option);
      getOtherrChartData();
    }
  });
};

// 获取时域图幅值谱图数据
let trms:any = ref(0);
const getOtherrChartData = async () => {
  myChartB.value && myChartB.value.clear();
  myChartC.value && myChartC.value.clear();
  trms.value = 0;
  let t = typeList.find((p: any) => p.value === type.value)?.type;
  if (!t) return;

  let config1 = {
    params: {
      sensorId: sensorInfo.value.id,
      date: markDate.value,
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  // let result1 = await request.get("/api/scores/train-expert/info", config1);
  // if (result1 && result1.data) {
  //   trms.value = (Number(result1.data.Trms) || result1.data.Trms === 0) ? Number(result1.data.Trms).toFixed(2) : 0;
  // }
  let config: any = {
    params: {
      sensorId: sensorInfo.value.id,
      date: markDate.value,
      // date: '2023/09/14 17:00:00',
      type: t,
      latest: true,
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/external/singles/info", config);
  if (!result || !result.data || !result.data.url) {
    return;
  }
  trms.value = (Number(result.data.value) || result.data.value === 0) ? Number(result.data.value).toFixed(2) : 0;
  localStorage.setItem("chartInfo", JSON.stringify(result.data));
  let url = result.data.url;
  //幅值
  let resultTIME: any = await request.post("/draw/api/time-sort", { url: [url] });
  let resultFFT: any = await request.post("/draw/api/fft-sort", { url: [url] });

  let timeData = getChartDataTool(resultTIME?.data)
  let fftData = getChartDataTool(resultFFT?.data)

  zoomB.value = {
    start: 0 ,
    end: 100 ,
  }
  zoomC.value = {
    start: 0 ,
    end: 100 ,
  }

  initChartBC(
    timeData,
    "chart1B",
    "s",
    type.value >= 1 && type.value <= 5 ? "m/s²" : "mm/s"
  );
  initChartBC(
    fftData,
    "chart1C",
    "hz",
    type.value >= 1 && type.value <= 5 ? "m/s²" : "mm/s"
  );
  //unit
};
const typeB = ref(2);
const typeC = ref(1);
const optionB:any = ref({});
const optionC:any = ref({});
let zoomB:any = ref({start: 0, end : 100})
let zoomC:any = ref({start: 0, end : 100})

const clearMark = (type: any) => {
  if (type == "B") {
    optionB.value.series[0].markPoint.data = [];
    optionB.value.series[0].markLine.data = [];
    console.log(optionB.value);
    myChartB.value && myChartB.value.setOption(optionB.value);
  } else {
    optionC.value.series[0].markPoint.data = [];
    optionC.value.series[0].markLine.data = [];
    console.log(optionC.value);
    myChartC.value && myChartC.value.setOption(optionC.value);
  }
};

const initChartBC = (data: any, id: any, xUnit: any, yUnit: any) => {
  let { axisX, axisY } = data;

  const ec = echarts as any;
  if (!document.getElementById(id)) return;

  let myChart = ec.init(document.getElementById(id));
  myChart && myChart.clear();

  let option = {
    color: ["#00FFF4"],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    toolbox: {
      trigger: "axis",
    },
    dataZoom: [
      { 
        type: "inside",
        start: 0,
        end: 100
      },
      {
        id: "dataZoomX",
        type: "slider",
        xAxisIndex: [0],
        filterMode: "filter",
        bottom: "0",
      },
      {
        id: "dataZoomY",
        type: "slider",
        yAxisIndex: [0],
        filterMode: "filter",
        left: "30",
      },
    ],
    xAxis: {
      name: xUnit,
      type: "category",
      axisTick: {
        alignWithLabel: true,
      },
      axisLabel: {
        showMaxLabel: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          opacity: 0.3,
        },
      },
      data: axisX,
    },
    yAxis: {
      name: yUnit,
      type: "value",
    },
    series: [
      {
        data: axisY,
        type: "line",
        markPoint: {
          data: [],
          itemStyle: {
            color: "#ffffff", // 背景颜色为白色
            borderColor: "#aaa", // 边框颜色为黑色
            borderWidth: 1, // 边框宽度为1像素
          },
          symbolSize: [95, 32], //
          label: {
            color: "#000000", // 文字颜色为黑色
            fontSize: 14,
            lineHeight: 16,
          },
          symbol: "rect", // 形状设置为长方形
          symbolOffset: [0, -40],
        },
        markLine: {
          data: [],
        },
      },
    ],
  };

  myChart && myChart.setOption(option);

  myChart.on('datazoom', function(params:any) {
    console.log('params', params);
    // 在这里可以处理缩放事件，params 包含了缩放的具体信息
    if(id == "chart1B") {
      zoomB.value = {
        start: params?.batch[0]?.start || 0 ,
        end: params?.batch[0]?.end || 0 ,
      }
    } else{
      zoomC.value = {
        start: params?.batch[0]?.start || 0 ,
        end: params?.batch[0]?.end || 0 ,
      }
    }



  });

  myChart.off("click");
  if (id == "chart1B") {
    optionB.value = option;
    myChartB.value = myChart;
    myChart.on("click", "series", (params: any) => {
      console.log(params, "pointInPixel");
      optionB.value.series[0].markPoint.data.push({
        value:
          "X:" +
          Number(params.name).toFixed(4) +
          "\nY:" +
          Number(params.value).toFixed(4),
        xAxis: params.dataIndex,
        yAxis: params.value,
      });
      if (typeB.value == 1) {
        console.log(optionB.value.series[0].markLine.data.length);
        if (!optionB.value.series[0].markLine.data.length) {
          for (var ii = 1; ii <= 10; ii++) {
            optionB.value.series[0].markLine.data.push({
              name:
                ii +
                "倍" +
                "\nX:" +
                Number(Number(params.name) * ii).toFixed(4),
              // "\nY:" +
              // Number(params.value).toFixed(4),
              xAxis: params.dataIndex * ii,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "rgb(173, 38, 64)" },
            });
          }
        }
      } else {
        if (!optionB.value.series[0].markLine.data.length) {
          optionB.value.series[0].markLine.data.push({
            name: "\nX:" + Number(Number(params.name)).toFixed(4),
            // "\nY:" +
            // Number(params.value).toFixed(4),
            xAxis: params.dataIndex,
            label: { show: true, color: "#ADADAD", formatter: "{b}" },
            lineStyle: { color: "rgb(173, 38, 64)" },
          });
        } else if (optionB.value.series[0].markLine.data.length == 1) {
          var x1 = params.dataIndex;
          var x2 = optionB.value.series[0].markLine.data[0].xAxis;
          var cz = Math.abs(x1 - x2);
          var dataCZ = Math.abs(Number(optionB.value.xAxis.data[x1]) - Number(optionB.value.xAxis.data[x2]));
          optionB.value.series[0].markLine.data = []
          for (var ii = -5; ii <= 5; ii++) {
             //查询当前值

              optionB.value.series[0].markLine.data.push({
                name:  (1/dataCZ).toFixed(3)+"HZ\nX:" + Number(Number(optionB.value.xAxis.data[x2 + ii * cz])).toFixed(4),
                // "\nY:" +
                // Number(params.value).toFixed(4),
                xAxis: x2 + ii * cz,
                label: { show: true, color: "#ADADAD", formatter: "{b}" },
                lineStyle: { color: "rgb(173, 38, 64)" },
              });
          }
        }
      }
      optionB.value.dataZoom[0].start = zoomB.value.start
      optionB.value.dataZoom[0].end = zoomB.value.end
      myChart && myChart.clear()
      myChart && myChart.setOption(optionB.value);
    });
  } else {
    optionC.value = option;
    myChartC.value = myChart;
    myChart.on("click", "series", (params: any) => {
      console.log(params, "pointInPixel");
      optionC.value.series[0].markPoint.data.push({
        value:
          "X:" +
          Number(params.name).toFixed(4) +
          "\nY:" +
          Number(params.value).toFixed(4),
        xAxis: params.dataIndex,
        yAxis: params.value,
      });
      if (typeC.value == 1) {
        console.log(optionC.value.series[0].markLine.data.length);
        if (!optionC.value.series[0].markLine.data.length) {
          for (var ii = 1; ii <= 10; ii++) {
            optionC.value.series[0].markLine.data.push({
              name:
                ii +
                "倍" +
                "\nX:" +
                Number(Number(params.name) * ii).toFixed(4),
              // "\nY:" +
              // Number(params.value).toFixed(4),
              xAxis: params.dataIndex * ii,
              label: { show: true, color: "#ADADAD", formatter: "{b}" },
              lineStyle: { color: "rgb(173, 38, 64)" },
            });
          }
        }
      } else {
        if (!optionC.value.series[0].markLine.data.length) {
            optionC.value.series[0].markLine.data.push({
            name: "\nX:" + Number(Number(params.name)).toFixed(4),
            // "\nY:" +
            // Number(params.value).toFixed(4),
            xAxis: params.dataIndex,
            label: { show: true, color: "#ADADAD", formatter: "{b}" },
            lineStyle: { color: "rgb(173, 38, 64)" },
          });
        } else if (optionC.value.series[0].markLine.data.length == 1) {
          var x1 = params.dataIndex;
          var x2 = optionC.value.series[0].markLine.data[0].xAxis;
          var cz = Math.abs(x1 - x2);
          var dataCZ = Math.abs(Number(optionC.value.xAxis.data[x1]) - Number(optionC.value.xAxis.data[x2]));
          optionC.value.series[0].markLine.data = []
          for (var ii = -5; ii <= 5; ii++) {
                optionC.value.series[0].markLine.data.push({
                name:  (dataCZ).toFixed(3)+"HZ\nX:" + Number(Number(optionC.value.xAxis.data[x2 + ii * cz])).toFixed(4),
                // "\nY:" +
                // Number(params.value).toFixed(4),
                xAxis: x2 + ii * cz,
                label: { show: true, color: "#ADADAD", formatter: "{b}" },
                lineStyle: { color: "rgb(173, 38, 64)" },
              });
          }
        }
      }
      optionC.value.dataZoom[0].start = zoomC.value.start
      optionC.value.dataZoom[0].end = zoomC.value.end
      myChart && myChart.clear()
      myChart && myChart.setOption(optionC.value);
    });
  }
};

const getRowClassName = (record: any, index: any) => {
  //
  return index % 2 === 0 ? "even-row" : "odd-row";
};

const downFile = (ev: any) => {
  if (ev.url) {
    let url = ev.url
    url = url.replace('http://', 'https://')
    window.open(url);
  } else {
    message.warning(langObj.value["暂无下载文件"]+"!");
  }
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 60;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 60;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};
</script>

<style lang="less" scoped>
/deep/ .ant-picker {
  background-color: #07249933 !important ;
}

.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
  width: 250px;
}

.btn1 {
  padding: 3px 10px;
}

/deep/ .ant-table {
  color: #fff;
}
/deep/ .ant-table-thead .ant-table-cell {
  background-color: #020f46;
  color: #fff;
}

/deep/ table tbody tr:hover > td {
  background-color: #020f46 !important;
}

/deep/ .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #020f46 !important;
}

/deep/ .ant-table-tbody > tr > td {
  transition: 0s;
}

/deep/ .ant-table-tbody > tr.ant-table-row-selected > td {
  background: transparent;
  border-color: #fff;
}

/deep/ .ant-table-empty .ant-table-tbody > tr.ant-table-placeholder {
  background: #020f46;
}

/deep/ .ant-empty-description {
  color: #fff;
}
</style>
