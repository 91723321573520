<template>
  <div :style="showExpertCareOptions?{}:{'padding':'30px 0'}"
       class="bule_list_page">
    <a-layout-content style="padding: 0 20px; height: 100%">
      <a-row type="flex" style="height: 100%">
        <a-col :span="5" :order="1" style="height: 100%">
          <div class="w-64 px-2 overflow-y-auto scroll-box h-screen code-box" style="height: 100%">
            <a-input-search v-model:value="filterKeyWord" style="margin-bottom: 8px"
                            placeholder="输入区域或设备名称筛选"/>
            <a-tree class="draggable-tree" draggable block-node :autoExpandParent="autoExpandParent"
                    :expanded-keys="expandedKeys" :tree-data="treeData" @expand="onExpand" @select="selectTree">
              <template #title="{ title }">
                <span v-if="title.indexOf(filterKeyWord) > -1">
                  {{ title.substr(0, title.indexOf(filterKeyWord)) }}
                  <span style="color: #f50">{{ filterKeyWord }}</span>
                  {{
                    title.substr(
                        title.indexOf(filterKeyWord) + filterKeyWord.length
                    )
                  }}
                </span>
                <span v-else>{{ title }}</span>
              </template>
            </a-tree>
          </div>
        </a-col>
        <a-col :span="19" :order="2" style="padding-bottom: 20px;">
          <a-button type="primary" @click="add" style="margin-bottom: 15px">
            <question-circle-outlined/>
            说明
          </a-button>
          <a-form-item class="mt-2" v-if="sensorsSource && sensorsSource.length>0 && showExpertCareOptions"
                       :label="getLanguage('是否专家看护', language)" name="alarmType">
            <a-radio-group name="alarmType" v-model:value="expertCare">
              <a-radio :value="true"> {{ getLanguage('是', language) }}</a-radio>
              <a-radio :value="false"> {{ getLanguage('否', language) }}</a-radio>
            </a-radio-group>
          </a-form-item>
          <div v-for="(item) in sensorsSource" :key="item.sensorId">
            <alarm-item :sensor="item" :key="item.sensorId" :machineId="currentMachine.key"
                        :expert-care="expertCare" @search="search"></alarm-item>
          </div>
          <div v-if="!sensorsSource || !sensorsSource.length">
            <a-alert message="未选择设备或没有配置测点" type="warning" show-icon/>
          </div>
        </a-col>
      </a-row>
    </a-layout-content>
    <a-modal :width="850" :maskClosable="false" v-model:visible="modalState.visible" :title="modalState.title"
             :confirm-loading="modalState.confirmLoading" :footer="null">
      <div style="font-size: 16px; line-height: 2; color: #fff">
        基于国际、国家或行业内既定标准，实现对设备运行状态进行监测。同时，可纵向参考设备历史运行数据，横向比较同类设备运行数据，确定各报警等级的阈值。<br/>

        但依据标准设定的绝对合理的阈值很难获取，设定过高的报警阈值会造成设备故障<span
          style="font-weight: 600; font-size: 18px">漏报</span>的风险，而阈值过低又会来带大量的<span
          style="font-weight: 600; font-size: 18px">误报警</span>，给现场处理造成极大的压力；此外，仅按照标准规定中的几种指标设定的阈值报警所能发现的故障模式有限。设置和修改阈值也面临必需手动调整与工作量大等问题。
        <br/>
        根据各类国际、国家、行业标准，根据对目前所有设备类型，参照相应标准，做如下统计：<br/><br/>
      </div>
      <a-table :columns="aiColumns" :data-source="standardData" :pagination="false" size="small" bordered>
      </a-table>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import request from "../../common/request";
import { computed, onMounted } from "vue";
import { ref, watch, reactive } from "vue";
import { v4 as uuidv4 } from "uuid";
import AlarmItem from "./common/AlarmItem.vue";
/**操作日志记录 */
import { operateLogs } from "@/common/logs";
import { QuotaAlarmItem } from "./common/AlarmItem.vue";
import { getLanguage } from "@/common/translate";

operateLogs("访问单值报警规则配置");

let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
}
getLang()

/**操作日志记录 */

interface Props {
  hideExpertCareOptions?: boolean // 是否隐藏专家看护选项，诊断管理中需要显示，报警管理中需要隐藏
}

const props = withDefaults(defineProps<Props>(), {
  hideExpertCareOptions: false,
})

let showExpertCareOptions = computed<boolean>(() => {
  return !props.hideExpertCareOptions
});

const modalState = reactive({
  visible: false,
  title: "说明",
  confirmLoading: false,
  roleName: "",
  meun: [],
});
const sensorsSource = ref<any>([]);
const expertCare = ref<boolean>(null);

// 新增
const add = () => {
  modalState.visible = true;
};
// 查询
const search = async () => {

  const sConfig = {
    params: { machineId: currentMachine.value.key },
    headers: {
      requestId: uuidv4(),
    },
  };
  var sResult = await request.get("/api/sensors", sConfig);
  sensorsSource.value = [];
  if (sResult) {
    sensorsSource.value = sResult.data;
  }
  const config = {
    params: { machineId: currentMachine.value.key },
    headers: {
      requestId: uuidv4(),
    },
  };
  const DefaultRangeCount = 12;
  const DefaultLimitCount = 4;
  const DefaultIntervalDays = 7;
  const DefaultIntervalTimes = 1;
  const result = await request.get("/api/sensors/quota-alarm", config);
  if (showExpertCareOptions.value) {
    if (result.data.length > 0 && result.data[0].expertCare != null) {
      expertCare.value = result.data[0].expertCare;
    } else {
      expertCare.value = true;
    }
    console.log(expertCare.value)
  } else {
    expertCare.value = null;
  }
  sensorsSource.value.forEach((item: any, index: any) => {
    item.table = []

    item.dataType && item.dataType.forEach((element: any) => {
      let data = result.data.find((t: any) => t.dataType == element && t.sensorId == item.id)
      if (data && data.sensorId) {
        item.table.push(data)
      } else {
        let configItem = {
          _id: null,
          sensorId: item.id,
          sensorPlace: item.sensorPlace,
          machineId: item.machineId,
          dataType: element,
          enable: false,
          expertCare: expertCare.value,
          level1Threshold: null,
          level1RangeCount: DefaultRangeCount,
          level1LimitCount: DefaultLimitCount,
          level1IntervalDays: DefaultIntervalDays,
          level1IntervalTimes: DefaultIntervalTimes,
          level2Threshold: null,
          level2RangeCount: DefaultRangeCount,
          level2LimitCount: DefaultLimitCount,
          level2IntervalDays: DefaultIntervalDays,
          level2IntervalTimes: DefaultIntervalTimes,
          level3Threshold: null,
          level3RangeCount: DefaultRangeCount,
          level3LimitCount: DefaultLimitCount,
          level3IntervalDays: DefaultIntervalDays,
          level3IntervalTimes: DefaultIntervalTimes,
          level4Threshold: null,
          level4IntervalDays: DefaultIntervalDays,
          level4IntervalTimes: DefaultIntervalTimes
        } as QuotaAlarmItem
        item.table.push(configItem)
      }
    });
  })
};

/* 左侧区域-设备 数据加载 */
// 在组件加载完成后执行一些操作
let currentMachine = ref<any>();
const selectTree = (key: any, val: any) => {
  if (val.node.type == "machine") {
    currentMachine.value = val.node;
    search();
  }
};

let filterKeyWord = ref<any>();
let treeData = ref<any>();

const onExpand = (keys: string[]) => {
  expandedKeys.value = keys;
  autoExpandParent.value = false;
};
const expandedKeys = ref<(string | number)[]>([]);
const autoExpandParent = ref<boolean>(true);

watch(filterKeyWord, () => {
  expandedKeys.value = [];
  sonKey(treeData.value);
  autoExpandParent.value = true;
});
const sonKey = (item: any) => {
  item.forEach((element: any) => {
    if (element.title.indexOf(filterKeyWord.value) > -1) {
      // expandedKeys.value.push(key);
      expandedKeys.value.push(element.key);
    }
    if (element.children && element.children.length) {
      sonKey(element.children);
    }
  });
};
const getParentKey = (
    key: string | number,
    tree: any
): string | number | undefined => {
  let parentKey;
  for (let i = 0; i < tree.length; i++) {
    const node = tree[i];
    if (node.children) {
      if (
          node.children.some((item: { key: string | number }) => item.key === key)
      ) {
        parentKey = node.key;
      } else if (getParentKey(key, node.children)) {
        parentKey = getParentKey(key, node.children);
      }
    }
  }
  return parentKey;
};


//***角色车间***//
onMounted(() => {
  //loadMember()
  var requestId = uuidv4();
  loadFactory(requestId);
});

let factoryList = ref<any>();
let allFactory: any = ref([])
const loadFactory = (requestId: any) => {
  const config = {
    params: {},
    headers: {
      requestId: requestId,
    },
  };
  request.get("/api/factories", config).then((res: any) => {
    if (res) {
      allFactory.value = res.data;
      // 2级工厂
      var list = res.data.map((t: any) => ({
        key: t._id,
        title: t.factoryName,
        value: t._id,
        label: t.factoryName,
        parentId: t.parentId,
        id: t._id,
        type: "factory",
        children: t.groups.map((group: any) => ({
          type: "group",
          value: group.sonID,
          label: group.name,
          title: group.name,
          key: group.sonID,
        })),
      }));
      let factory: any = [];
      let parentFs = list.filter((p: any) => !p.parentId)
      parentFs.map((d: any) => {
        let sonFactory = list.filter((p: any) => p.parentId === d.id)
        if (sonFactory && sonFactory.length) {
          d.children = sonFactory
        }
        factory.push(d);
      })

      factoryList.value = factory;
      loadMachine(requestId);
    }
  });
};
const loadMachine = async (requestId: any) => {
  let factoryIds = allFactory.value.map((p: any) => p._id)
  let res = await request.post("/api/machines/all", { factoryIds: factoryIds })
  factoryList.value.forEach((element: any) => {
    if (res) {
      element.children.forEach((sonFact: any) => {
        if (sonFact.type == "factory" && sonFact.children) {
          sonFact.children.forEach((groupFact: any) => {
            if (groupFact.type == "group") {
              groupFact.children = res.data
                  .filter((t: any) => t.groupId == groupFact.value)
                  .map((t: any) => ({
                    type: "machine",
                    ...t,
                    value: t.id,
                    label: t.machineName,
                    title: t.machineName,
                    key: t.id,
                  }));
            }
          });
        } else if (sonFact.type == "group") {
          sonFact.children = res.data
              .filter((t: any) => t.groupId == sonFact.value)
              .map((t: any) => ({
                type: "machine",
                ...t,
                value: t.id,
                label: t.machineName,
                title: t.machineName,
                key: t.id,
              }));
        }
      });
    }
  });
  setTimeout(() => {
    treeData.value = factoryList.value;
  }, 100);
};

const aiColumns = [
  {
    title: "设备类型",
    dataIndex: "type",
    align: "center",
  },
  {
    title: "采用标准",
    dataIndex: "standard",
    align: "center",
  },
];
const standardData = [
  {
    type: "电机类",
    standard: "ISO",
  },
  {
    type: "泵类",
    standard: "ISO",
  },
  {
    type: "泵类",
    standard: "API",
  },
  {
    type: "往复类",
    standard: "ISO",
  },
  {
    type: "风机类",
    standard: "API",
  },
  {
    type: "齿轮类",
    standard: "ISO",
  },
  {
    type: "螺杆式",
    standard: "API",
  },
  {
    type: "汽轮机",
    standard: "ISO",
  },
  {
    type: "通用设备",
    standard: "ISO",
  },
];
</script>
<style scoped>
.code-box {
  position: relative;
  display: inline-block;
  width: 90%;
  margin: 0 0 16px;
  border: 1px solid #0d53b7cc;
  border-radius: 2px;
  transition: all 0.2s;
  padding: 15px;
  max-height: 100%;
}

:deep(.ant-card-head) {
  padding-left: 0;
}
</style>
