<template>
    <div class="w-full">
        <div>
            <a-form
                :model="searchModel"
                name="horizontal_login"
                layout="inline"
                autocomplete="off"
            >
                <a-form-item
                    :label="langObj['设备区域']"
                    name="groupId"
                >
                    <a-select
                        v-model:value="searchModel.groupId"
                        style="width: 220px"
                        @change="selectGroup"
                        :placeholder="langObj['选择设备区域']"
                    >
                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                    :label="langObj['设备']"
                    name="status"
                    >
                    <a-select
                        v-model:value="searchModel.machineId"
                        :placeholder="langObj['选择设备']"
                        style="width: 220px"
                    >
                    <a-select-option v-for="(item, index) in machineList" :key="index" :value="item.id"> {{ item.name }} </a-select-option>
                    </a-select>
                </a-form-item>

                <a-form-item
                    :label="langObj['更新时间']"
                    name="status"
                >
                    <a-range-picker v-model:value="searchModel.date"  :disabled-date="disabledDate" @calendarChange="onCalendarChange"/>
                </a-form-item>
                <a-form-item
                    :label="langObj['严重程度']"
                    name="grade"
                    >
                    <a-select
                        v-model:value="searchModel.grade"
                        style="width: 220px"
                        :placeholder="langObj['选择严重程度']"
                    >
                        <a-select-option value="0"> {{langObj['可用']}} </a-select-option>
                        <a-select-option value="1"> {{ langObj['警戒']}} </a-select-option>
                        <a-select-option value="2"> {{langObj['故障']}} </a-select-option>
                    </a-select>
                </a-form-item>
                <!-- <a-form-item
                    label="故障模式"
                    name="status"
                    >
                    <a-select
                        v-model:value="searchModel.fault"
                        style="width: 220px"
                    >
                        <a-select-option value=""> {{ '全设备测点' }} </a-select-option>
                    </a-select>
                </a-form-item> -->

                <a-form-item
                    :label="langObj['搜索']"
                    name="status"
                    >
                    <a-input style="width: 220px"  :placeholder="langObj['请输入关键词搜索']" v-model:value="searchModel.keyword"/>
                </a-form-item>

                <a-form-item>
                    <div class="flex items-center">
                        <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4">{{langObj['重置']}}</div>

                        <div @click="search" class="btn px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499;">{{langObj['查询']}}</div>
                       
                    </div>
                </a-form-item>
                

            </a-form>
        </div>

        <div class="box mt-4" style="width: 100%;">
            <a-table 
            style="width: 100%;" 
            size="small" 
            :row-class-name="getRowClassName" 
            :columns="columns" 
            :data-source="data"
            :pagination="pagination"
            @change="handleTableChange"
            >
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.dataIndex === 'gradeText'">
                        <div class="w-full flex items-center justify-items-center">
                            <div style="width: 12px; height: 12px; border-radius: 50%;margin-right: 5px;" :style="{'background':  gradeList.find(p => p.value === text)?.color}"></div>
                            <span>{{langObj[gradeList.find(p => p.value === text)?.label ] }} </span>
                        </div>
                    </template>

                    <template v-if="column.dataIndex === 'faultEvents'">
                        <span>{{langObj[text] || text}} </span>
                    </template>

                    <template v-if="column.dataIndex === 'feedbackStatus'">
                        <span> {{ statusList.find(p => p.value === text)?.label || getLanguage('未反馈') }} </span>
                    </template>

                    <template v-if="column.dataIndex === 'act'">
                        <a v-if="!record.feedbackStatus || record.feedbackStatus === 4" @click="feedback(record)" style="color: #00FFF4 !important">{{ getLanguage('报告反馈', language) }}</a>
                        <a v-if="record.feedbackStatus === 1" @click="viewFeedback(record)" style="color: #00FFF4 !important">{{ getLanguage('查看报告反馈', language) }}</a>
                        <a v-if="record.feedbackStatus === 3" @click="viewFeedback2(record)" style="color: #00FFF4 !important">{{ getLanguage('查看报告反馈', language) }}</a>
                        <a v-if="record.feedbackStatus === 2" @click="surefeedback(record)" style="color: #00FFF4 !important">{{ getLanguage('确认报告反馈', language) }}</a>

                        <a-divider type="vertical" />
                        <a @click="details(record)" style="color: #00FFF4 !important">{{langObj['查看详情']}}</a>
                    </template>
                </template>
               
            </a-table>
        </div>



        <a-drawer v-model:visible="visible" class="custom-class" size="large" :closable="false"  placement="right">
            <template #title>
            </template>
            <DiagnosticReportDetails ref="mrDref" :reportId="reportId" @cancel="cancel"></DiagnosticReportDetails>
        </a-drawer>


        <!-- 报告反馈 -->
        <a-modal
            v-model:visible="visibleFeedback"
            :closable="false"
            :footer="null"
            :bodyStyle="{ background: '#020F46' }"
            style="top: 30%"
            width="1200px"
            :maskClosable="false"
        >
            <div style="color: #fff">
                <div class="w-full flex">
                    <div class="flex-1"></div>
                    <div class="cursor-pointer" @click="visibleFeedback = false">
                        <close-outlined style="font-size: 20px" />
                    </div>
                </div>

                <div class="text-center w-full" style="font-size: 24px"> {{ getLanguage('报告反馈', language) }} </div>

                <div style="font-size: 18px;">{{feedbackForm.title}}</div>
                <div class="mt-2 mb-2">{{ getLanguage('选中报告', language) }}：</div>
                
                <a-table 
                style="width: 100%;" 
                size="small" 
                :row-class-name="getRowClassName" 
                :columns="columnsFeedback" 
                :data-source="feedbackForm.reports"
                :pagination="false"
                >
                    <template #bodyCell="{ column, text, record }">
                        <template v-if="column.dataIndex === 'faultEvents'">
                            <span>{{langObj[text] || text}} </span>
                        </template>
                    </template>
                
                </a-table>

                <a-form style="margin-top: 20px" :model="feedbackForm" autocomplete="off" 
                    :label-col="{ style: { width: '80px' } }">
                    <a-form-item :label="getLanguage('报告反馈', language)" required autocomplete="off" auto-complete="off">
                        <a-select v-model:value="feedbackForm.degree" :disabled="feedbackForm.id" >
                            <a-select-option v-for="(item, index) in feedbackGradeList" :key="index" :value="item.value">{{ item.label}}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item :label="getLanguage('反馈原因', language)" name="reason" required>
                        <a-textarea v-model:value="feedbackForm.reason" :rows="4" :readonly="feedbackForm.id" />
                    </a-form-item>
                </a-form>

                <div class="w-full flex justify-center" v-if="!feedbackForm.id">
                    <div
                        style="
                        background-color: #072499;
                        padding: 5px 10px;
                        width: 100px;
                        font-size: 20px;
                        text-align: center;
                        cursor: pointer;
                        "
                        @click="submitFeedback()"
                    >
                        {{langObj['提交']}}
                    </div>
                </div>


            </div>

        </a-modal>

        <!-- 确认报告反馈 -->
        <a-modal
            v-model:visible="visibleSure"
            :closable="false"
            :footer="null"
            :bodyStyle="{ background: '#020F46' }"
            style="top: 30%"
            width="1200px"
            :maskClosable="false"
        >
            <div style="color: #fff">
                <div class="w-full flex">
                    <div class="flex-1"></div>
                    <div class="cursor-pointer" @click="visibleSure = false">
                        <close-outlined style="font-size: 20px" />
                    </div>
                </div>

                <div class="text-center w-full" style="font-size: 24px"> 
                    {{ feedbackSureForm.title }} 
                </div>

                <div class="my-2">{{ getLanguage('诊断报告') }}</div>
                
                <a-table 
                style="width: 100%;" 
                size="small" 
                :row-class-name="getRowClassName" 
                :columns="columnsFeedbackSure" 
                :data-source="feedbackSureForm.reports"
                :pagination="false"
                >
                    <template #bodyCell="{ column, text, record }">
                        <template v-if="column.dataIndex === 'faultEvents'">
                            <span>{{langObj[text] || text}} </span>
                        </template>
                        <template v-if="column.dataIndex === 'degree'">
                            <span>{{feedbackGradeList.find((p:any) => p.value === text)?.label}} </span>
                        </template>
                    </template>
                
                </a-table>


                <div class="mt-4" v-for="(item, index) in sensorFaults" :key="index">
                    <div>{{ item.title }}</div>

                    <div class="flex justify-center ml-2 mt-4">
                        <div
                            class="btn2 px-3 py-1"
                            :class="{ 'text-white': item.type == '1' }"
                            :style="{
                            background:
                            item.type == '1' ? '#072498' : '',
                            }"
                            style="border-radius: 5px 0 0 5px"
                            @click="(item.type = '1'), selectSensorFault(index)"
                        >
                        {{ getLanguage('近一周') }}
                        </div>
                        <div
                            class="btn2 px-3 py-1"
                            :class="{ 'text-white': item.type == '2' }"
                            :style="{
                            background:
                            item.type == '2' ? '#072498' : '',
                            }"
                            @click="(item.type = '2'), selectSensorFault(index)"
                        >
                        {{ getLanguage('近一月') }}
                        </div>
                        <div
                            class="btn2 px-3 py-1"
                            :class="{ 'text-white': item.type == '3' }"
                            :style="{
                            background:
                            item.type == '3' ? '#072498' : '',
                            }"
                            @click="(item.type = '3'), selectSensorFault(index)"
                        >
                        {{ getLanguage('近两月') }}
                        </div>
                        <div
                            class="btn2 px-3 py-1"
                            :style="{
                            background:
                            item.type == '0' ? '#072498' : '',
                            }"
                            :class="{ 'text-white': item.type == '0' }"
                            @click="item.type = '0'"
                            style="border-radius: 0 5px 5px 0"
                        >
                        {{ getLanguage('自定义') }}
                        </div>

                        <div class="ml-2" v-if="item.type == '0'">
                            <a-range-picker
                                v-model:value="item.date"
                            />
                        </div>

                        <div @click="selectSensorFault(index)" class="btn3 ml-2">
                            {{ getLanguage('查询') }}
                        </div>

                    </div>

                    <div>
                        <div :id="'reportfeedback'+index" style="height: 340px" class="w-full"></div>
                    </div>


                    <div v-if="!feedbackSureForm.isView" class="w-full flex justify-center mt-4">
                        <div
                            style="
                            background-color: #072499;
                            padding: 5px 10px;
                            width: 100px;
                            font-size: 20px;
                            text-align: center;
                            cursor: pointer;
                            "
                            @click="sureFeedback()"
                        >
                            {{ getLanguage('确认调整') }}
                        </div>
                        
                        <div
                            class="ml-8"
                            style="
                            background-color: #072499;
                            padding: 5px 10px;
                            width: 100px;
                            font-size: 20px;
                            text-align: center;
                            cursor: pointer;
                            "
                            @click="cancelFeedback()"
                        >
                            {{ getLanguage('取消调整') }}
                        </div>
                    </div>







                </div>







            </div>

        </a-modal>



    </div>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import { transformDate, transformDate2, getGroupListByFactory, getRealFactoryIdList, getFactoryName } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import DiagnosticReportDetails from "./common/DiagnosticReportDetails2.vue";
import { Dayjs } from 'dayjs';
import { langList } from "../../common/lang";
import { getGroupName } from "../../common/tools";
import { getLanguage } from "../../common/translate";
import { message } from "ant-design-vue";
import * as echarts from "echarts";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();

const columns = [
    {
        title: langObj.value['更新时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['设备区域'],
        dataIndex: 'groupName',
    },
    {
        title: langObj.value['设备名称'],
        dataIndex: 'machineName',
    },
    {
        title: langObj.value['严重程度'],
        dataIndex: 'gradeText',
    },
    {
        title: langObj.value['故障模式'],
        dataIndex: 'faultEvents',
    },
    {
        title: langObj.value['诊断描述'],
        dataIndex: 'content',
    },
    {
        title: getLanguage('反馈状态'),
        dataIndex: 'feedbackStatus',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]

let gradeList = [
  { value: -1, label: "健康", color: '#61c08f' },
  { value: 0, label: "可用", color: '#d2de49' },
  { value: 1, label: "警戒", color: '#cd5f3b' },
  { value: 2, label: "故障", color: '#921e37' },
];

const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));

let searchModel:any = ref({
    groupId: null,
    machineId: null,
    keyword: '',
    date: null,
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    grade:null,
    order: -1
})
let data:any = ref([])
let groupList:any = ref([])
let machineList:any = ref([])
let visible = ref(false)
let reportId = ref('')
let mrDref = ref()

const getRowClassName = (record:any, index:any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'odd-row' : 'even-row';
}

onMounted(() => {
    init()
})

const init = () => {
    getGroupList()
    search()
}

// 获取区域列表
const getGroupList = () => {
    groupList.value = getGroupListByFactory()
}

// 选择区域
const selectGroup = (ev:any) => {
    let memberInfo = JSON.parse( localStorage.getItem('memberInfo') || '')
    searchModel.value.machineId = ''
    if(ev) {
        machineList.value = memberInfo.machines.filter((p:any) => p.groupId === ev)
    } else {
        machineList.value = []
    }
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const reset = () => {
    searchModel.value = {
        ...searchModel.value,
        grade:null,
        groupId: null,
        machineId: null,
        keyword: '',
        date: null,
    }
}

// 获取数据
const getData = async() => {
    let config: any = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            type: [1],
            keyword: searchModel.value.keyword,
            sort: searchModel.value.sort,
            order: searchModel.value.order,
            machineId: searchModel.value.machineId,
            factoryIds: getRealFactoryIdList(),
            grade:searchModel.value.grade,
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)

    if(result && result.status === 200) {
        let member = JSON.parse(localStorage.getItem('memberInfo') || '')
        result.data.list.forEach((element: any) => {
            if (element.expertReport) {
            element.expertReport.faultCause = element.expertReport.faultCause
                ? element.expertReport.faultCause.replace(/\n/g, "<br>")
                : "";
            element.gradeText = element.expertReport.grade

            let faultEvents = ''
            element.expertReport.diagnosisDetails.forEach((item2: any, index2:any) => {
                var grade = gradeList.find((t) => t.value == item2.grade);
                item2.gradeText = grade ? grade.label : "";
                faultEvents = faultEvents + item2.faultEvent
            });

            element.faultEvents = faultEvents
            }
            if (element.machines && element.machines[0]) {
            element.machineName = element.machines[0].machineName;
            // element.groupName = member.groups.find((p:any) => p.id === element.machines[0].groupId)?.name
            element.groupName = getGroupName(element.machines[0]?.groupId)
            }
        });

        data.value = result.data.list
        searchModel.value.total = result.data.count
    }
}

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if(Object.keys(sorter).length) { 
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field
    searchModel.value.order = order
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current
  searchModel.value.take = pagination.pageSize
  getData();
};

const details = (ev:any) => {
    visible.value = true
    reportId.value = ev.id
    setTimeout(() => {
        mrDref.value.show(ev.id)
    }, 500);
}

const cancel = (ev:any) => {
    if(ev) {
        visible.value = false
        reportId.value = ''
    }
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};


// 报告反馈
const visibleFeedback = ref<any>(false);
const columnsFeedback = [
    {
        title: langObj.value['报告时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['严重程度'],
        dataIndex: 'gradeText',
    },
    {
        title: langObj.value['故障模式'],
        dataIndex: 'faultEvents',
    },
    {
        title: langObj.value['诊断描述'],
        dataIndex: 'content',
    },
    
]
let statusList = [
    {label: getLanguage('已反馈') , value: 1},
    {label: getLanguage('已调整') , value: 2},
    {label: getLanguage('已确认') , value: 3},
    {label: getLanguage('已关闭') , value: 4}
]
let feedbackGradeList = [
    {label: getLanguage('准确', language.value), value: 0},
    {label: getLanguage('轻微', language.value), value: 1},
    {label: getLanguage('略轻微', language.value), value: 2},
    {label: getLanguage('严重', language.value), value: 3},
    {label: getLanguage('略严重', language.value), value: 4},
]
let feedbackForm:any = ref({
    
})
const feedback = (ev:any) => {
    console.log('ev', ev)
    feedbackForm.value = {
        title: `${getFactoryName(ev.factoryId)}/${ev.groupName}/${ev.machineName}`,
        factoryId: ev.factoryId,
        machineId: ev.machineIds[0],
        groupId: ev.machines[0]?.groupId,
        reportId: ev.id,
        date: ev.date,
        status: 1,
        degree: null,
        reason: null,
        reports: [
            {
                date: ev.date,
                gradeText: gradeList.find(p => p.value === ev.gradeText)?.label,
                faultEvents: ev.faultEvents,
                content: ev.content,
            }
        ]
    }

    visibleFeedback.value = true
}

const submitFeedback = async() => {
    console.log('feedbackForm.value', feedbackForm.value)
    let result = await request.post('/api/report-feedbacks', feedbackForm.value)
    console.log('result', result)
    if(result?.data) {
        message.success('操作成功!')
        visibleFeedback.value = false
        getData()
    } else {
        message.warning('操作失败!')
    }
}

// 查看报告反馈
const viewFeedback = async(ev:any) => {
    console.log('ev', ev)
    if(!ev.feedbackId) {
        return message.warning('暂无报告反馈')
    }
    let config: any = {
        params: {
            id: ev.feedbackId
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/report-feedbacks/info', config)
    console.log('result', result)

    feedbackForm.value = {
        id: ev.feedbackId,
        title: `${getFactoryName(ev.factoryId)}/${ev.groupName}/${ev.machineName}`,
        factoryId: ev.factoryId,
        machineId: ev.machineIds[0],
        reportId: ev.id,
        date: ev.date,
        status: 1,
        degree: result?.data?.degree,
        reason: result?.data?.reason,
        reports: [
            {
                date: ev.date,
                gradeText: gradeList.find(p => p.value === ev.gradeText)?.label,
                faultEvents: ev.faultEvents,
                content: ev.content,
            }
        ]
    }

    visibleFeedback.value = true
}

// 确认报告反馈
let visibleSure:any = ref(false)
const columnsFeedbackSure = [
    {
        title: langObj.value['报告时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['严重程度'],
        dataIndex: 'gradeText',
    },
    {
        title: langObj.value['故障模式'],
        dataIndex: 'faultEvents',
    },
    {
        title: langObj.value['诊断描述'],
        dataIndex: 'content',
    },
    {
        title: getLanguage('客户反馈'),
        dataIndex: 'degree',
    },
    {
        title: getLanguage('客户反馈原因'),
        dataIndex: 'reason',
    },
    
]
let feedbackSureForm:any = ref({})
let sensorFaults:any = ref([])
const surefeedback = async(ev:any) => {

    let config: any = {
        params: {
            id: ev.feedbackId
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/report-feedbacks/info', config)


    feedbackSureForm.value = {
        feedbackId: ev.feedbackId,
        title: `${getFactoryName(ev.factoryId)}/${ev.groupName}/${ev.machineName}`,
        factoryId: ev.factoryId,
        machineId: ev.machineIds[0],
        reportId: ev.id,
        reports: [
            {
                date: ev.date,
                gradeText: getLanguage(gradeList.find(p => p.value === ev.gradeText)?.label),
                faultEvents: ev.faultEvents,
                content: ev.content,
                degree: result?.data?.degree,
                reason: result?.data?.reason,
            }
        ]
    }

    sensorFaults.value = result?.data?.sensorFaults || []


    sensorFaults.value.map((d:any, i:any) => {
        d.title = d.sensorPlace + '-' + d.faultName
        d.type = '1'
        d.date = null
        getchartData(d,i)
    })

    visibleSure.value = true
}


const selectSensorFault = (index:any) => {
    let data = sensorFaults.value[index]
    console.log('data', data)
    getchartData(data, index)
}

const getchartData = async(data:any, index:any) => {
    console.log('data', data)
    let startDate = "";
    let endDate: any = "";
    if (data.type == 0) {
        if (
            data.date &&
            data.date.length === 2
        ) {
        startDate = transformDate(data.date[0]);
        endDate = transformDate(data.date[1]);
        } else {
            message.warn("请选择时间");
            return;
        }
    } else {
        endDate = new Date();
        let num = 7;
        if (data.type == 2) {
        num = 30;
        }
        if (data.type == 3) {
        num = 60;
        }
        startDate = transformDate(new Date(new Date(transformDate(endDate)).getTime() - 1000 * 60 * 60 * 24 * num));
    }
    let search = {
        machineId: feedbackForm.value.machineId,
        // machineId: "649a40c3a12ce3eeaadc6a1e",
        sensorId: data.sensorId,
        tarFunc: data.faultFunction,
        funcTag: data.scoreName,
        paramArray: data.originThresholds,
        dateArray: [transformDate2(startDate, 1), transformDate2(endDate, 2)]
        // dateArray: ["2023/12/01 00:00:00", "2023/12/30 23:59:59"]
    }

    let result1 = await request.post('/docs/score/trim-threshold', search)
    // return result?.data?.result || []
    search.paramArray = data.adjustThresholds
    let result2 = await request.post('/docs/score/trim-threshold', search)
    let data1 = result1?.data?.result || []
    let data2 = result2?.data?.result || []

    let xData = data1.map((p:any) => p.date) 
    let chartData = {
        xData,
        yDatas: [
            {
                title: getLanguage('调整前趋势'),
                yData: data1.map((p:any) => p[data.scoreName])
            },
            {
                title: getLanguage('调整后趋势'),
                yData: data2.map((p:any) => p[data.scoreName])
            },
        ]
    }

    sensorFaults.value[index].chartData = chartData
    initChartData(chartData, 'reportfeedback'+index)
}


const initChartData = (data:any, id:any) => {
    let {xData, yDatas} = data
    const ec = echarts as any;
    if (!document.getElementById(id)) return;
    let myChart = ec.init(document.getElementById(id));

    let option: any = null;
    let LengData: any[] = [],
    SelectedData = {},
    YaData,
    SeriesData,
    DataInfo: any[] = [],
    colors = [
        "rgb(87,147,243)",
        "rgb(255,165,0)",
        "rgb(0,255,0)",
        "rgb(255,51,51)",
        "rgb(0,255,255)",
        "rgb(0,128,0)",
        "rgb(32, 157, 179)",
        "rgb(120, 32, 179)",
        "rgb(214, 55, 188)",
    ],
    chartSelectlenged:any = {}

    yDatas.map((item: any, index: any) => {
        LengData.push(item.title);
        chartSelectlenged[item.title] = true
        DataInfo.push({
            name: item.title,
            type: "line",
            data: item.yData,
            smooth: false,
            symbolSize: 3,
            connectNulls: true,
        });
    });

    function Init(sel: { [x: string]: any }, dataInfo: any[]) {
        SelectedData = sel || {};
        (YaData = []), (SeriesData = []);
        let Datas = JSON.parse(JSON.stringify(dataInfo));
        for (let n = 0; n < LengData.length; n++) {

        // 如果该图例状态为false时，则跳过 不push
        if (sel[LengData[n]]) {
            YaData.push({
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",
                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                lineStyle: {
                color: "#fff",
                },
            },
            });
        } else {
            Datas[n].data = [];
        }
        Datas[n].yAxisIndex = 0;
        SeriesData.push(Datas[n]);
        }
        if (YaData.length == 0) {
        YaData = [{ type: "value" }];
        }
        option = {
        color: colors,
        tooltip: {
            hideDelay: 100,
            transitionDuration: 0,
            animation: false,
            trigger: "axis",
            axisPointer: {
            type: "cross",
            },
        },
        grid: {
            right: "5%",
            left: "7%",
        },
        toolbox: {
            trigger: "axis",
        },
        dataZoom: [{ type: "inside" }],
        legend: {
            data: LengData,
            selected: SelectedData,
            textStyle: {
            color: "#fff",
            },
        },
        xAxis: [
            {
            show: true,
            type: "category",
            axisTick: {
                alignWithLabel: true,
            },
            data: xData,
            splitLine: {
                show: true,
                lineStyle: {
                opacity: 0.3,
                },
            },
            axisLabel: {
                color: "#fff",
                fontSize: 16,
                showMaxLabel: true,
            },
            },
        ],
        yAxis: [
            {
            type: "value",
            scale: true,
            min: 0,
            max: 100,
            splitNumber: 5,
            splitLine: {
                lineStyle: {
                color: [
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgb(117, 116, 116)",
                    "rgba(250, 60, 88, 1)",
                    "rgba(254, 178, 7, 1)",
                    "rgb(117, 116, 116)",
                ],
                },
            },
            position: "left",
            offset: "-10",
            axisLine: {
                color: "#fff",
            },
            },
        ],
        series: SeriesData,
        };
    }
    Init(chartSelectlenged, DataInfo);

    if (option && typeof option === "object") {
        myChart && myChart.clear();
        myChart && myChart.setOption(option, true);
    }

    myChart
    .off("legendselectchanged")
    .on("legendselectchanged", function (params: { selected: any }) {
      // 得到当前的图例显示隐藏状态分别有哪些
      chartSelectlenged = params.selected;
      Init(chartSelectlenged, DataInfo);
      if (option && typeof option === "object") {
        myChart && myChart?.clear();
        myChart && myChart?.setOption(option, true);
      }
    });



}

const sureFeedback = async() => {
    console.log('feedbackSureForm.value', feedbackSureForm.value?.feedbackId)
    let submit = {
        id: feedbackSureForm.value?.feedbackId
    }
    let result = await request.post('/api/report-feedbacks/confirm', submit)
    console.log('result', result)
    if(result?.status == 200) {
        message.success('操作成功！')
        visibleSure.value = false
        getData()
    } else {
        message.warning('操作失败！')
    }

}

const cancelFeedback = async() => {
    console.log('feedbackSureForm.value', feedbackSureForm.value?.feedbackId)
    let submit = {
        id: feedbackSureForm.value?.feedbackId
    }
    let result = await request.post('/api/report-feedbacks/close', submit)
    console.log('result', result)
    if(result?.status == 200) {
        message.success('操作成功！')
        visibleSure.value = false
        getData()
    } else {
        message.warning('操作失败！')
    }

}


// 查看报告反馈
const viewFeedback2 = async(ev:any) => {
    let config: any = {
        params: {
            id: ev.feedbackId
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    let result = await request.get('/api/report-feedbacks/info', config)

    console.log('result', result)

    feedbackSureForm.value = {
        isView: true,
        feedbackId: ev.feedbackId,
        title: `${getFactoryName(ev.factoryId)}/${ev.groupName}/${ev.machineName}`,
        factoryId: ev.factoryId,
        machineId: ev.machineIds[0],
        reportId: ev.id,

        
        reports: [
            {
                date: ev.date,
                gradeText: getLanguage(gradeList.find(p => p.value === ev.gradeText)?.label),
                faultEvents: ev.faultEvents,
                content: ev.content,
                degree: result?.data?.degree,
                reason: result?.data?.reason,
            }
        ]

    }

    sensorFaults.value = result?.data?.sensorFaults || []

    console.log('sensorFaults.value', sensorFaults.value)

    sensorFaults.value.map((d:any, i:any) => {
        d.title = d.sensorPlace + '-' + d.faultName
        getchartData(d,i)
    })

    visibleSure.value = true
}


</script>


<style lang="less" scoped>
.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}
.btn {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.fff {
    color: #fff
}

/deep/ .ant-table-tbody > tr > td {
    border-bottom: 0px solid #fff
}

/deep/ .ant-picker-input > input {
    color: #fff;
}

/deep/ .ant-picker-clear span {
    background: #000 !important;
}

/deep/ .ant-drawer-close span svg {
    color: #000 !important;
}
// /deep/ .anticon-close svg {
//     color: #000 !important;
// }



</style>


