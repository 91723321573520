<template>
  <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div
      style="
        padding: 16px 16px;
        height: calc(100vh - 100px);
        background-color: rgb(0, 10, 50);
        overflow-y: scroll;
        padding-bottom: 30px;
        background-color: #000a32;
        color: #fff;
      "
    >
      <div class="box">
        <div class="w-full" style="border-bottom: 1px solid #495985">
          <div class="flex items-center px-5" style="font-size: 18px">
            <div
              class="py-3 cursor-pointer"
              :class="{ 'txt-select': menuSelect === 1 }"
              @click="selectMenu(1, null)"
            >
              {{ langObj["全部"] }}({{ counts.count0 }})
            </div>
            <div
              class="py-3 cursor-pointer ml-24"
              :class="{ 'txt-select': menuSelect === 2 }"
              @click="selectMenu(2, 1)"
            >
              {{ langObj["未处理"] }}({{ counts.count1 }})
            </div>
            <div
              class="py-3 cursor-pointer ml-24"
              :class="{ 'txt-select': menuSelect === 3 }"
              @click="selectMenu(3, 2)"
            >
              {{ langObj["已处理"] }}({{ counts.count2 }})
            </div>
            <div
              class="py-3 cursor-pointer ml-24"
              :class="{ 'txt-select': menuSelect === 4 }"
              @click="selectMenu(4, 3)"
            >
              {{ langObj["已关闭"] }}({{ counts.count3 }})
            </div>
          </div>
        </div>

        <div class="w-full p-5">
          <div class="w-full">
            <a-form
              :model="searchModel"
              name="horizontal_login"
              layout="inline"
              autocomplete="off"
            >
              <a-form-item :label="langObj['设备区域']" name="groupId">
                <a-select
                  v-model:value="searchModel.groupId"
                  style="width: 220px"
                  :placeholder="langObj['请选择设备区域']"
                  @change="selectGroup"
                >
                  <a-select-option
                    v-for="(item, index) in groupList"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="langObj['设备']" name="status">
                <a-select
                  v-model:value="searchModel.machineId"
                  style="width: 220px"
                  :placeholder="langObj['请选择设备']"
                  allowClear
                >
                  <a-select-option
                    v-for="(item, index) in machineList"
                    :key="index"
                    :value="item.id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="langObj['报警时间']" name="status">
                <a-range-picker
                  v-model:value="searchModel.date"
                  :disabled-date="disabledDate"
                  @calendarChange="onCalendarChange"
                />
              </a-form-item>

              <a-form-item :label="langObj['报警类型']" name="status">
                <a-select
                  v-model:value="searchModel.type"
                  style="width: 220px"
                  allowClear
                  :placeholder="langObj['请选择报警类型']"
                >
                  <a-select-option
                    v-for="(item, index) in typeList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ langObj[item.label] }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="langObj['严重程度']" name="status">
                <a-select
                  v-model:value="searchModel.condition"
                  style="width: 220px"
                  allowClear
                  :placeholder="langObj['请选择严重程度']"
                >
                  <a-select-option
                    v-for="(item, index) in conditionLists"
                    :key="index"
                    :value="item.value"
                  >
                    {{ langObj[item.label] }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item :label="langObj['是否专家认证']" name="status">
                <a-select
                  v-model:value="searchModel.expertCertification"
                  style="width: 220px"
                  allowClear
                  :placeholder="langObj['是否专家认证']"
                >
                  <a-select-option
                    v-for="(item, index) in expertCertificationList"
                    :key="index"
                    :value="item.value"
                  >
                    {{ langObj[item.label] }}
                  </a-select-option>
                </a-select>
              </a-form-item>

              <a-form-item>
                <div class="flex items-center">
                  <div
                    @click="reset"
                    class="btn px-5 py-1 fff cursor-pointer ml-4"
                  >
                    {{ langObj["重置"] }}
                  </div>
                  <div
                    @click="search"
                    class="btn px-5 py-1 fff cursor-pointer ml-4"
                    style="background-color: #072499"
                  >
                    {{ langObj["查询"] }}
                  </div>
                  <div
                    @click="downLoad"
                    class="btn px-5 py-1 fff cursor-pointer ml-4"
                    style="background-color: #072499"
                  >
                    {{ langObj["下载报警"] }}
                  </div>
                  <!-- <div class="btn px-5 py-1 fff cursor-pointer ml-4" style="background-color: #072499;">新建检修</div> -->
                  <div
                    v-if="isEditable"
                    @click="closeWarning"
                    class="btn px-5 py-1 fff cursor-pointer ml-4"
                    style="background-color: #072499"
                  >
                    {{ langObj["关闭报警"] }}
                  </div>
                </div>
              </a-form-item>
            </a-form>
          </div>

          <div class="box mt-4" style="width: 100%">
            <a-table
              style="width: 100%"
              size="small"
              :row-class-name="getRowClassName"
              :columns="warningColumns"
              :data-source="data"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              :pagination="pagination"
              @change="handleTableChange"
              rowKey="id"
            >
              <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'type'">
                  <div class="flex items-center">
                    <div v-if="text === 1">
                      <img
                        style="width: 20px"
                        src="@/assets/zhenduan.png"
                        alt=""
                      />
                    </div>
                    <div v-if="text === 2">
                      <img
                        style="width: 20px"
                        src="@/assets/weixiu.png"
                        alt=""
                      />
                    </div>
                    <div v-if="text === 3">
                      <img
                        style="width: 20px"
                        src="@/assets/jiancha.png"
                        alt=""
                      />
                    </div>
                    <div v-if="text === 4">
                      <img
                        style="width: 20px"
                        src="@/assets/menxian.png"
                        alt=""
                      />
                    </div>
                    <div class="ml-2">
                      {{
                        langObj[typeList.find((p) => p.value === text)?.label]
                      }}
                    </div>
                  </div>
                </template>
                <template v-if="column.dataIndex === 'expertCertification'">
                  {{ text ? langObj["是"] : langObj["否"] }}
                </template>

                <template v-if="column.dataIndex === 'condition'">
                  <div class="flex items-center">
                    <div
                      style="width: 12px; height: 12px; border-radius: 50%"
                      :style="{
                        background: conditionList.find((p) => p.value === text)
                          ?.color,
                      }"
                    ></div>
                    <div class="ml-2">
                      {{
                        langObj[
                          conditionList.find((p) => p.value === text)?.label
                        ]
                      }}
                    </div>
                  </div>
                </template>

                <template v-if="column.dataIndex === 'handleStatus'">
                  <div @click="viewClose(record)" v-if="text === 3">
                    <a>{{ langObj["已关闭"] }}</a>
                  </div>
                  <div v-if="text !== 3">
                    {{
                      statusList.find((p) => p.value === text)
                        ? langObj[
                            statusList.find((p) => p.value === text)?.label
                          ]
                        : langObj["未处理"]
                    }}
                  </div>
                </template>

                <template v-if="column.dataIndex === 'act'">
                  <a v-if="isAdmin && record.type == 1" @click="edit(record)"
                    >编辑</a
                  >
                  <a-divider
                    v-if="isAdmin && record.type == 1"
                    type="vertical"
                  />
                  <a
                    @click="viewReport(record)"
                    v-if="record.reportIds && record.reportIds.length"
                    >{{ langObj["查看报告"] }}</a
                  >
                </template>
              </template>
            </a-table>
          </div>
        </div>
      </div>

      <!-- 编辑工单 -->
      <a-modal
        v-model:visible="editVisible"
        :closable="false"
        :footer="null"
        :bodyStyle="{ background: '#020F46' }"
        style="top: 30%"
        width="1200px"
        :maskClosable="false"
      >
        <div style="color: #fff">
          <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="cursor-pointer" @click="editVisible = false">
              <close-outlined style="font-size: 20px" />
            </div>
          </div>
          <div class="text-center w-full" style="font-size: 24px">
            {{ langObj["编辑"] }}
          </div>

          <a-form
            ref="formRef"
            name="advanced_search"
            class="ant-advanced-search-form"
            style="margin-top: 20px"
            :model="editForm"
          >
            <a-form-item name="condition" :label="'稳定状态'" required>
              <a-select v-model:value="editForm.condition">
                <a-select-option
                  v-for="(item, index) in conditionList2"
                  :key="index"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item name="diagnosticNotes" :label="'报警内容'" required>
              <a-textarea
                v-model:value="editForm.diagnosticNotes"
                placeholder=""
                :rows="4"
              />
            </a-form-item>
          </a-form>

          <div class="w-full flex justify-center">
            <div
              @click="submitEdit"
              style="
                background-color: #072499;
                padding: 5px 10px;
                width: 100px;
                font-size: 20px;
                text-align: center;
                cursor: pointer;
              "
            >
              {{ langObj["提交"] }}
            </div>
          </div>
        </div>
      </a-modal>

      <!-- 关闭报警 -->
      <a-modal
        v-model:visible="visibledel"
        :closable="false"
        :footer="null"
        :bodyStyle="{ background: '#020F46' }"
        style="top: 30%"
        width="1200px"
        :maskClosable="false"
      >
        <div style="color: #fff">
          <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="cursor-pointer" @click="visibledel = false">
              <close-outlined style="font-size: 20px" />
            </div>
          </div>
          <div class="text-center w-full" style="font-size: 24px">
            {{ langObj["关闭报警"] }}
          </div>

          <a-form
            ref="formRef"
            name="advanced_search"
            class="ant-advanced-search-form"
            style="margin-top: 20px"
            :model="formClose"
          >
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item
                  name="closeCause"
                  :label="langObj['关闭原因']"
                  required
                >
                  <a-textarea
                    v-model:value="formClose.closeCause"
                    placeholder=""
                    :rows="4"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>

          <div class="w-full flex justify-center">
            <div
              @click="submitClose"
              style="
                background-color: #072499;
                padding: 5px 10px;
                width: 100px;
                font-size: 20px;
                text-align: center;
                cursor: pointer;
              "
            >
              {{ langObj["提交"] }}
            </div>
          </div>
        </div>
      </a-modal>

      <a-modal
        v-model:visible="visibledel2"
        :closable="false"
        :footer="null"
        :bodyStyle="{ background: '#020F46' }"
        style="top: 30%"
        width="1200px"
        :maskClosable="false"
      >
        <div style="color: #fff">
          <div class="w-full flex">
            <div class="flex-1"></div>
            <div class="cursor-pointer" @click="visibledel2 = false">
              <close-outlined style="font-size: 20px; color: #fff !important" />
            </div>
          </div>
          <div class="text-center w-full" style="font-size: 24px">
            {{ langObj["关闭报警"] }}
          </div>

          <a-form
            ref="formRef"
            name="advanced_search"
            class="ant-advanced-search-form"
            style="margin-top: 20px"
            :model="formClose"
          >
            <a-row :gutter="24">
              <a-col :span="24">
                <a-form-item name="closeCause" label="关闭原因" required>
                  <a-textarea
                    v-model:value="formClose.closeCause"
                    placeholder=""
                    :rows="4"
                    readonly
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-form>
        </div>
      </a-modal>

      <a-modal
        :width="1400"
        v-model:visible="downLoadVisible"
        :title="langObj['下载']"
        :maskClosable="false"
        :footer="false"
      >
        <DownLoadComponent
          @downLoadCancel="downLoadCancel"
          :type="2"
          :dataList="downLoadList"
        >
        </DownLoadComponent>
      </a-modal>

      <a-drawer
        v-model:visible="visibleDR"
        class="custom-class"
        size="large"
        :closable="false"
        placement="right"
      >
        <template #title> </template>
        <DiagnosticReportDetails
          ref="mrDref"
          :reportId="reportDRId"
          @cancel="cancelDR"
        >
        </DiagnosticReportDetails>
      </a-drawer>
    </div>
  </a-config-provider>
</template>


<script lang="ts" setup>
import {
  computed,
  defineComponent,
  onMounted,
  onUnmounted,
  reactive,
  ref,
  watch,
} from "vue";
import { useRouter } from "vue-router";
import request from "../../common/request";
import {
  transformDate,
  transformDate2,
  getGroupListByFactory,
  getRealFactoryIdList,
} from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import DownLoadComponent from "../components/downLoad.vue";
import { Dayjs } from "dayjs";
import { langList } from "../../common/lang";
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import DiagnosticReportDetails from "../report4/common/DiagnosticReportDetails2.vue";

const router = useRouter();
const getLang = () => {
  language.value = localStorage.getItem("language") || "Chinese";
  langObj.value = langList[language.value];
};
let typeList = [
  { label: "智能诊断", value: 1 },
  // {label: '维修记录', value: 2},
  // {label: '设备检查', value: 3},
  { label: "门限报警", value: 4 },
];
let langObj: any = ref({});
let language: any = ref("Chinese");
getLang();

let conditionList = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用", value: 1, color: "#d2de49" },
  { label: "可用", value: 2, color: "#d2de49" },
  { label: "警戒", value: 3, color: "#cd5f3b" },
  { label: "警戒", value: 4, color: "#cd5f3b" },
  { label: "警戒", value: 5, color: "#cd5f3b" },
  { label: "故障", value: 6, color: "#921e37" },
  { label: "故障", value: 7, color: "#921e37" },
];
let conditionList2 = [
  { label: "健康", value: 0, color: "#61c08f" },
  { label: "可用上", value: 1, color: "#d2de49" },
  { label: "可用下", value: 2, color: "#d2de49" },
  { label: "警戒上", value: 3, color: "#cd5f3b" },
  { label: "警戒中", value: 4, color: "#cd5f3b" },
  { label: "警戒下", value: 5, color: "#cd5f3b" },
  { label: "故障上", value: 6, color: "#921e37" },
  { label: "故障下", value: 7, color: "#921e37" },
];
let conditionLists = [
  { label: "健康", value: 1, real: [0] },
  { label: "可用", value: 2, real: [1, 2] },
  { label: "警戒", value: 3, real: [3, 4, 5] },
  { label: "故障", value: 4, real: [6, 7] },
];

let statusList = [
  { label: "未处理", value: 1 },
  { label: "已处理", value: 2 },
  { label: "已关闭", value: 3 },
];
let expertCertificationList = [
  { value: null, label: "不限" },
  {
    value: true,
    label: "是",
  },
  { value: false, label: "否" },
];
let warningColumns = [
  {
    title: langObj.value["报警时间"],
    dataIndex: "date",
  },
  {
    title: langObj.value["报警类型"],
    dataIndex: "type",
  },
  {
    title: langObj.value["严重程度"],
    dataIndex: "condition",
  },
  {
    title: langObj.value["设备"],
    dataIndex: "machineName",
  },
  {
    title: langObj.value["报警内容"],
    dataIndex: "diagnosticNotes",
  },
  {
    title: langObj.value["联系人"],
    dataIndex: "contactName",
  },
  {
    title: langObj.value["处理状态"],
    dataIndex: "handleStatus",
  },
  {
    title: langObj.value["是否专家认证"],
    dataIndex: "expertCertification",
  },

  {
    title: langObj.value["操作"],
    dataIndex: "act",
  },
];
const pagination = computed(() => ({
  total: searchModel.value.total,
  current: searchModel.value.skip,
  pageSize: searchModel.value.take,
}));
let data: any = ref([]);
let menuSelect: any = ref(1);
let searchModel: any = ref({
  groupId: null,
  machineId: null,
  date: null,
  skip: 1,
  take: 20,
  condition: [],
  handleStatus: null,
  expertCertification: null,
});
let selectedRowKeys: any = ref();
let groupList: any = ref([]);
let machineList: any = ref([]);
let visibledel = ref(false);
let formClose: any = ref({
  closeCause: "",
});
let isEditable: any = ref(false);
let isAdmin: any = ref(false);
let editVisible: any = ref(false);
let editForm: any = ref({});

onMounted(() => {
  isEditable.value = true;
  getAdmin();
  getCount();
  getGroupList();
  search();
  getLang();
});

const getAdmin = () => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "{}");
  isAdmin.value = memberInfo?.admin;
};

let counts: any = ref({
  count0: 0,
  count1: 0,
  count2: 0,
  count3: 0,
});
// 获取计数统计
const getCount = async () => {
  let config: any = {
    params: {
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  let result = await request.get("/api/workflows/count/pc", config);
  counts.value = result.data;
};

// 获取区域列表
const getGroupList = () => {
  groupList.value = getGroupListByFactory();
};

// 选择区域
const selectGroup = (ev: any) => {
  let memberInfo = JSON.parse(localStorage.getItem("memberInfo") || "");
  searchModel.value.machineId = "";
  if (ev) {
    machineList.value = memberInfo.machines.filter(
      (p: any) => p.groupId === ev
    );
  } else {
    machineList.value = [];
  }
};

const search = () => {
  searchModel.value.skip = 1;
  getData();
};

const reset = () => {
  searchModel.value = {
    ...searchModel.value,
    groupId: null,
    machineId: null,
    date: null,
    type: null,
    condition: null,
  };
};

const getData = async () => {
  let config: any = {
    params: {
      skip: searchModel.value.skip,
      take: searchModel.value.take,
      machineId: searchModel.value.machineId,
      type: searchModel.value.type,
      handleStatus: searchModel.value.handleStatus,
      expertCertification: searchModel.value.expertCertification,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };

  if (!searchModel.value.type) {
    config.params.typeList = [1, 4];
  }

  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(searchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }

  if (searchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === searchModel.value.condition
    )?.real;
  }

  let result = await request.get("/api/workflows", config);

  if (result) {
    data.value = result.data.list;
    searchModel.value.total = result.data.total;
  }
};

const handleTableChange = (pagination: any, filters: any, sorter: any) => {
  // 处理分页数据
  if (Object.keys(sorter).length) {
    var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
    searchModel.value.sort = sorter.field;
    searchModel.value.order = order;
  }
  // 处理分页数据
  searchModel.value.skip = pagination.current;
  searchModel.value.take = pagination.pageSize;
  getData();
};

// 编辑工单
const edit = (ev: any) => {
  editForm.value = {
    id: ev.id,
    diagnosticNotes: ev.diagnosticNotes,
    condition: ev.condition,
  };
  editVisible.value = true;
};

// 提交编辑
const submitEdit = async () => {
  let result = await request.put("/api/workflows", editForm.value);
  if (result?.data) {
    message.success("操作成功");
    editVisible.value = false;
    getData();
  }
};

//复选框选择
const onSelectChange = (ev: any) => {
  selectedRowKeys.value = ev;
};

// 关闭警报
const closeWarning = () => {
  if (!selectedRowKeys.value) {
    message.warning(langObj.value["请选择报警"]);
    return;
  }

  let list = data.value.filter((p: any) =>
    selectedRowKeys.value.includes(p.id)
  );

  for (let d of list) {
    if (d.handleStatus && (d.handleStatus === 2 || d.handleStatus === 3)) {
      message.warning(langObj.value["请选择未处理的报警"]);
      return;
    }
  }

  formClose.value.closeCause = "";
  visibledel.value = true;
};

const submitClose = async () => {
  if (!formClose.value.closeCause) {
    message.warning(langObj.value["请填写关闭原因"]);
    return;
  }

  let form = {
    closeCause: formClose.value.closeCause,
    list: [],
  };

  let list = data.value.filter((p: any) =>
    selectedRowKeys.value.includes(p.id)
  );

  if (list && list.length) {
    form.list = list.map((d: any) => ({
      date: d.date,
      condition: d.condition,
      diagnosticNotes: d.diagnosticNotes,
      id: d.id,
    }));
  }

  let result = await request.post("/api/workflows/close", form);
  if (result && result.status == 200) {
    message.success(langObj.value["关闭成功"]);
    visibledel.value = false;
    getData();
  } else {
    message.warning(langObj.value["操作失败"]);
  }
};

const selectMenu = (ev: any, value: any) => {
  menuSelect.value = ev;
  searchModel.value.handleStatus = value;
  searchModel.value.skip = 1;
  getData();
};

// 查看已关闭
let visibledel2 = ref(false);
const viewClose = (ev: any) => {
  formClose.value.closeCause = ev.closeCause;
  visibledel2.value = true;
};

//查看报告
let visibleDR: any = ref(false);
let reportDRId: any = ref("");
let mrDref: any = ref();
const viewReport = (ev: any) => {
  if (ev.reportIds.length) {
    visibleDR.value = true;
    reportDRId.value = ev.reportIds[0];
    setTimeout(() => {
      mrDref.value.show(reportDRId.value);
    }, 500);
  } else {
    message.warning("暂无报告");
  }
  // router.push('/report/list')
};

const cancelDR = () => {
  visibleDR.value = false;
};

const getRowClassName = (record: any, index: any) => {
  // 根据索引设置不同的类名
  return index % 2 === 0 ? "even-row" : "odd-row";
};

// 下载报警
let downLoadVisible = ref(false);
let downLoadList: any = ref([]);
const downLoad = async () => {
  let config: any = {
    params: {
      skip: 1,
      take: 100000,
      machineId: searchModel.value.machineId,
      type: searchModel.value.type,
      handleStatus: searchModel.value.handleStatus,
      expertCertification: searchModel.value.expertCertification,
      factoryIds: getRealFactoryIdList(),
    },
    headers: {
      requestId: uuidv4(),
    },
  };
  if (!searchModel.value.type) {
    config.params.typeList = [1, 4];
  }
  if (searchModel.value.date && searchModel.value.date.length === 2) {
    config.params.begin = transformDate2(
      new Date(searchModel.value.date[0]),
      1
    );
    config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
  }

  if (searchModel.value.condition) {
    config.params.conditionList = conditionLists.find(
      (p: any) => p.value === searchModel.value.condition
    )?.real;
  }

  let result = await request.get("/api/workflows", config);
  let list: any = [];
  if (result) {
    list = result.data.list;
  }
  downLoadList.value = list;
  downLoadVisible.value = true;
};

const downLoadCancel = () => {
  downLoadVisible.value = false;
};

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
  if (!dates.value || (dates.value as any).length === 0) {
    return false;
  }
  const tooLate = dates.value[0] && current.diff(dates.value[0], "days") > 30;
  const tooEarly = dates.value[1] && dates.value[1].diff(current, "days") > 30;
  return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
  dates.value = val;
};
</script>



<style lang="less" scoped>
.box {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.txt-select {
  border-bottom: 2px solid #00fff4;
}

.btn {
  border: 1px solid #0d53b7cc;
  background: #07249933;
}

.fff {
  color: #fff;
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody > tr.ant-table-placeholder:hover > td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody > tr > td {
  border-bottom: 0px solid #fff;
}

/deep/ .ant-picker-input > input {
  color: #fff;
}

/deep/ .ant-picker-clear span {
  background: #000 !important;
}

/deep/ .ant-drawer-close span svg {
  color: #000 !important;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}
</style>
