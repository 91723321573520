<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { langList } from "@/common/lang";
import { message, Upload } from "ant-design-vue";
import { UploadOutlined } from "@ant-design/icons-vue"
import { UploadFile } from "ant-design-vue/es";
import { utils, read } from 'xlsx';
import { getFactoryName, getGroupListByFactory } from "@/common/tools";
import { v4 as uuidv4 } from "uuid";
import request from "@/common/request";

let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
  language.value = localStorage.getItem('language') || 'Chinese'
  langObj.value = langList[language.value]
}
getLang()


let props = defineProps<{
  machineTypes: any[] //{FirstCategory: '电机', SecondCategory: '高压电机', ThirdCategory: '高压异步电机', Maturity: 3, Complexity: 2, …}
}>()
let emits = defineEmits(["success"])

let isFileSelected = ref(false);

let factoryId = localStorage.getItem("factory_id");
let factoryName = getFactoryName(factoryId)
let groups = getGroupListByFactory();
//{id: '1687830223495', name: '冶炼车间', factoryId: '649a3ee1154bc03e85f25ff7', parentId: null}

onMounted(async () => {
  await getInspectionOptions()
})

let inspectionOptions: any[] = [];
/**
 * 查询智能诊断配置选项,包含转速范围
 */
const getInspectionOptions = async () => {
  let config: any = {
    params: {},
    headers: {
      requestId: uuidv4(),
    },
  };
  let result = await request.get('/api/external/inspection/threshold', config)
  if (result?.data) {
    inspectionOptions = result.data;
//{_id: '6678ca20f3d0f01fc523dcf9', firstCategory: '离心泵', secondCategory: '卧式离心泵', thirdCategory: '小于300kw', speedRange: '100-400rpm', …}
  }
}

const downloadTemplate = () => {
  window.location.href = "https://source.freqx.com/docs/temp.xlsx"
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'fabric-qr-code.pdf');
  document.body.appendChild(link);
  link.click();
  return;
}

// excel上传
let fileList = ref<any>([]);

const beforeUpload = (file: UploadFile) => {
  const isExcel = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  if (!isExcel) {
    message.error('请上传xlsx后缀的文件');
    return Upload.LIST_IGNORE;
  }


  return true
};

let previewTableRows = ref<any[]>([]);
let importErrors = ref<string[]>([])
let pushImportError = (rowIndex: number, err: string) => {
  importErrors.value.push(`行${rowIndex + 2}: ${err}`);
}

let isImportDataValid = computed<boolean>(() => {
  return !importErrors.value || importErrors.value.length === 0 && previewTableRows.value.length > 0
})
let machineDtoList: any[] = [];
let isUploading = ref(false);
let isImporting = ref(false);
let uploadExcel = async (options: any) => {
  const { onSuccess, file } = options;

  isUploading.value = true;
  importErrors.value = []
  previewTableRows.value = []
  let buffer = await (file as File).arrayBuffer()
  let workBook = read(buffer)
  let ws = workBook.Sheets[workBook.SheetNames[0]]
  let rows = utils.sheet_to_json(ws) as any[]

  let machineDtoMap = new Map<string, any>();
  let tableRows: any[] = []
  for (let i = 0; i < rows.length; i++) {
    if (importErrors.value.length >= 10) {
      continue
    }
    const row = rows[i];
    //["区域", "设备名称", "设备编号", "巡检序号", "监测类别", "设备位号", "设备类型", "设备厂家", "设备型号", "设备SN号","设备功率", "设备ID", "设备执行人", "传感器SN", "安装位置", "测点类型", "功率", "转频提取", "智能诊断配置", "转速范围"];

    let label = {
      groupName: row["区域"]?.toString()?.trim(),
      modeLabel: row["监测类别"]?.toString()?.trim(),
      ysshExecutor: row["设备执行人"]?.toString()?.trim(),
      ysshId: row["设备ID"]?.toString()?.trim(),
      machineTypeLabel: row["设备类型"]?.toString()?.trim(),
      sensorMachineTypeLabel: row["测点类型"]?.toString()?.trim(),
      inspectionSubLabel: row["智能诊断配置"]?.toString()?.trim(),
      speedRangeLabel: row["转速范围"]?.toString()?.trim()
    }

    if (!label.groupName) {
      pushImportError(i, "区域不能为空")
      continue;
    }

    if (label.groupName === "必填") {
      continue; // 防呆
    }

    let group = groups.find((p: any) => p.name === label.groupName)
    if (!group) {
      pushImportError(i, "区域不存在")
      continue;
    }

    let mode = [];
    if (label.modeLabel === "在线监测") {
      mode.push(1)
    } else if (label.modeLabel === "巡检") {
      mode.push(2)
    } else {
      pushImportError(i, "监测类别填写错误")
      continue
    }

    let machineTypeId: string = "";
    if (label.machineTypeLabel) {
      machineTypeId = parseMachineTypeId(label.machineTypeLabel);
      if (!machineTypeId) {
        pushImportError(i, "未找到匹配的设备类型")
        continue
      }
    }

    let machine = {
      factoryId: factoryId,
      parentFactoryId: group.parentId,
      machineCode: row["设备编号"]?.toString()?.trim(),
      groupId: group.id,
      machineName: row["设备名称"]?.toString()?.trim(),
      position: null, // 设备位置
      supplier: row["设备厂家"]?.toString()?.trim(),
      model: row["设备型号"]?.toString()?.trim(),
      machineTypeId: machineTypeId || null,
      sn: row["设备SN号"]?.toString()?.trim(),
      sort: Number(row["巡检序号"] || 1) || 1,
      experiment: false,
      params: {
        ysshExecutor: label.ysshExecutor,
        ysshId: label.ysshId
      },
      bitNumber: row["设备位号"]?.toString()?.trim(),
      machinePower: row["设备功率"]?.toString()?.trim(),
      mode: mode, // 监测类别
      sensors: []
    }

    if (!machine.machineName) {
      pushImportError(i, "设备名称不能为空")
      continue
    }
    if (!machine.machineCode) {
      pushImportError(i, "设备编号不能为空")
      continue
    }
    if (!machine.sort) {
      pushImportError(i, "巡检序号不能为空")
      continue
    }
    machine.sort = Number(machine.sort)

    let sensorMachineTypeId: string = "";
    if (label.sensorMachineTypeLabel) {
      sensorMachineTypeId = parseMachineTypeId(label.sensorMachineTypeLabel);
      if (!sensorMachineTypeId) {
        pushImportError(i, "未找到匹配的测点类型")
        continue
      }
    }

    let inspectionSubId: string = "";
    let inspectionSubs: string = "";
    let defaultSpeed: string = "";
    if (label.inspectionSubLabel) {
      let inspectionSubSplits = label.inspectionSubLabel.split("/").filter((t: string) => t);
      if (inspectionSubSplits.length === 2) {
        inspectionSubSplits.push('')
      }
      if (inspectionSubSplits.length !== 3) {
        pushImportError(i, "未找到匹配的智能诊断配置")
        continue
      }
      let inspection = inspectionOptions.find((p: any) => p.firstCategory === inspectionSubSplits[0] && p.secondCategory === inspectionSubSplits[1] && p.thirdCategory === inspectionSubSplits[2] || '/');
      if (!inspection) {
        pushImportError(i, "未找到匹配的智能诊断配置")
        continue
      }
      inspectionSubId = inspection._id
      inspectionSubs = inspectionSubSplits;

      if (label.speedRangeLabel) {
        let speedRange = inspectionOptions.find((p: any) => p.speedRange == label.speedRangeLabel && p.firstCategory === inspectionSubSplits[0] && p.secondCategory === inspectionSubSplits[1] && p.thirdCategory === inspectionSubSplits[2] || "/");
        if (speedRange) {
          defaultSpeed = speedRange._id
        } else {
          pushImportError(i, "未找到匹配的转速范围")
          continue
        }
      }
    }

    // "转速范围"
    let sensor = {
      sensorSn: row["传感器SN"]?.toString()?.trim(),
      sensorPlace: row["安装位置"]?.toString()?.trim(),
      machineName: machine.machineName,
      machineId: "",
      machineTypeId: sensorMachineTypeId, // 测点类型 
      national: row["功率"]?.toString()?.trim(),
      disable: false,
      inspectionSubId: inspectionSubId, //智能诊断配置 
      inspectionSubs: inspectionSubs, // 智能诊断配置
      defaultSpeed: defaultSpeed // 转速范围
    }

    if (!sensor.sensorPlace) {
      pushImportError(i, "安装位置不能为空")
      continue
    }

    tableRows.push(Object.assign(label, sensor, machine))
    let machineDto = machineDtoMap.get(machine.machineCode)
    if (!machineDto) {
      machineDto = Object.assign({}, machine)
      machineDtoMap.set(machine.machineCode, machineDto)
    }

    if (sensor.sensorSn && machineDto.sensors.findIndex((t: any) => {return t.sensorSn === sensor.sensorSn}) > -1) {
      pushImportError(i, "测点编号重复")
      continue
    }

    if (machineDto.sensors.findIndex((t: any) => {return t.sensorPlace === sensor.sensorPlace}) > -1) {
      pushImportError(i, "测点安装位置重复")
      continue
    }
    machineDto.sensors.push(sensor)
  }
  previewTableRows.value = tableRows;
  machineDtoList = [...machineDtoMap.values()]

  isUploading.value = false;
  onSuccess({ url: "", status: "done" });
};

let doImport = async () => {
  if (!isImportDataValid.value || machineDtoList.length === 0) return;

  isImporting.value = true
  try {
    let res: any = await request.post("/api/machines/import?__ignoreError=true", {
      factoryId,
      machines: machineDtoList
    });
    if (res && res.status === 200) {
      message.success("导入成功");
      machineDtoList.length = 0;
      previewTableRows.value.length = 0;
      emits("success")
    } else {
      message.error("导入失败");
      importErrors.value.push(res.message)
    }
  } catch (err) {
    message.error("导入失败");
  } finally {
    isImporting.value = false;
  }
}

function parseMachineTypeId(label: string): string {
  let machineTypeId: string = "";
  if (label) {
    let machineTypeSplits = label.split("/").filter((t: string) => t);
    if (machineTypeSplits.length !== 3) {
      return machineTypeId;
    }
    let machineType = props.machineTypes.find((p: any) => p.FirstCategory === machineTypeSplits[0] && p.SecondCategory === machineTypeSplits[1] && p.ThirdCategory === machineTypeSplits[2]);
    if (!machineType) {
      return machineTypeId;
    }
    machineTypeId = machineType.id
  }
  return machineTypeId
}

//["区域", "设备名称", "设备编号", "巡检序号", "监测类别", "设备位号", "设备类型", "设备厂家", "设备型号", "设备SN号", "设备功率", "设备ID", "设备执行人"],
const previewColumns = [
  { title: langObj.value["区域"], dataIndex: "groupName", fixed: 'left', },
  { title: langObj.value["设备名称"], dataIndex: "machineName", fixed: 'left', },
  { title: langObj.value["设备编号"], dataIndex: "machineCode", fixed: 'left', },
  { title: langObj.value["巡检序号"], dataIndex: "sort" },
  { title: langObj.value["监测类别"], dataIndex: "modeLabel" },
  { title: langObj.value["设备位号"], dataIndex: "bitNumber" },
  { title: langObj.value["设备类型"], dataIndex: "machineTypeLabel", ellipsis: true },
  { title: langObj.value["设备厂家"], dataIndex: "supplier" },
  { title: langObj.value["设备型号"], dataIndex: "model" },
  { title: langObj.value["设备SN号"], dataIndex: "sn" },
  { title: langObj.value["设备功率"], dataIndex: "machinePower" },
  { title: langObj.value["设备ID"], dataIndex: "ysshId" },
  { title: langObj.value["设备执行人"], dataIndex: "ysshExecutor" },
  // "传感器SN", "安装位置", "测点类型", "功率", "转频提取", "智能诊断配置", "转速范围"],
  { title: langObj.value["传感器SN"], dataIndex: "sensorSn" },
  { title: langObj.value["安装位置"], dataIndex: "sensorPlace" },
  { title: langObj.value["测点类型"] || "测点类型", dataIndex: "sensorMachineTypeLabel", ellipsis: true },
  { title: langObj.value["功率"], dataIndex: "national", ellipsis: true },
  { title: langObj.value["智能诊断配置"] || '智能诊断配置', dataIndex: "inspectionSubLabel" },
  { title: langObj.value["转速范围"], dataIndex: "speedRangeLabel" }
];

</script>

<template>
  <div v-if="isFileSelected">
    <!-- 导入预览/导入确认 -->
    <span>文件已选择</span>
  </div>
  <!-- 文件上传/模板下载 -->
  <a-row type="flex">
    <a-col :flex="1"></a-col>
    <a-col :flex="1" style="text-align: center">
      <a-upload :file-list="fileList" :before-upload="beforeUpload" :custom-request="uploadExcel">
        <a-button type="primary" :loading="isUploading">
          <upload-outlined></upload-outlined>
          {{ langObj["上传"] || "上传" }}Excel
        </a-button>
      </a-upload>

      <p class="mt-4 text-white">仅支持xlsx格式文件, 点击
        <a href="https://source.freqx.com/docs/temp.xlsx" download="abc">下载Excel模板</a>
      </p>
    </a-col>
    <a-col :flex="1"></a-col>
  </a-row>
  <a-row type="flex" v-if="importErrors.length">
    <a-col :flex="1"></a-col>
    <a-col :flex="1">
      <ul>
        <li v-for="(err, index) in importErrors" :key="index" class="text-red-600">{{ err }}</li>
      </ul>
    </a-col>
    <a-col :flex="1"></a-col>
  </a-row>

  <template v-if="previewTableRows.length > 0">
    <a-row type="flex">
      <a-col>
        <a-table style="width: 100%" size="small" :columns="previewColumns" :data-source="previewTableRows"
                 :pagination="true" :scroll="{ x: 2500 }" sticky></a-table>
      </a-col>
    </a-row>

    <a-row>
      <a-col :flex="1"></a-col>
      <a-col :flex="1" style="text-align: center">
        <a-button type="primary" :disabled="!isImportDataValid" @click="doImport" :loading="isImporting">{{
            langObj["确认导入"] || "确认导入"
          }}
        </a-button>
      </a-col>
      <a-col :flex="1"></a-col>
    </a-row>
  </template>

</template>

<style scoped lang="less">

</style>
