<template>
  <div>
    <a-card :bordered="false">
      <template #title>
        <div class="cardHead">
          <node-index-outlined/>
          <span style="position: relative; top: 4px; left: 5px">{{
              dataSource.sensorPlace
            }}</span>
        </div>
      </template>
      <template #extra>

        <a-radio-group size="default">
          <a-radio-button value="c" @click="editSave()">保存</a-radio-button>
        </a-radio-group>
      </template>
    </a-card>
    <div class="main" style="max-height: 1450px; overflow-y: scroll">
      <a-table bordered :columns="columns" :data-source="dataSource.table" :pagination="false">
        <template #emptyText><span class="text-white">请先组合数据类型并应用模型</span></template>
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.dataIndex === 'enable'">
            <a-switch v-model:checked="record.enable"
                      checked-children="开"
                      un-checked-children="关"/>
          </template>
          <template v-if="column.dataIndex === 'dataTypesName'">
            <span>{{ record.dataTypeNames?.join('+') }}</span>
          </template>
          <template v-if="column.dataIndex === 'level1Threshold'">
            <a-input-number v-model:value="record.level1RangeCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            笔内累计
            <a-input-number v-model:value="record.level1LimitCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            笔，
            触发报警
            <a-input-number v-model:value="record.level1IntervalDays" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            天
            <a-input-number v-model:value="record.level1IntervalTimes" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            次
          </template>
          <template v-if="column.dataIndex === 'level2Threshold'">
            <a-input-number v-model:value="record.level2RangeCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            笔内累计
            <a-input-number v-model:value="record.level2LimitCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            笔，
            触发报警
            <a-input-number v-model:value="record.level2IntervalDays" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            天
            <a-input-number v-model:value="record.level2IntervalTimes" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            次
          </template>
          <template v-if="column.dataIndex === 'level3Threshold'">
            <a-input-number v-model:value="record.level3RangeCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            笔内累计
            <a-input-number v-model:value="record.level3LimitCount" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            笔，
            触发报警
            <a-input-number v-model:value="record.level3IntervalDays" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            天
            <a-input-number v-model:value="record.level3IntervalTimes" :controls="false"
                            style="width: 45px" size="small" :min="1"/>
            次
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script lang="ts" setup>
import request from "../../../common/request";
import { computed, onMounted } from "vue";
import { ref, watch } from "vue";
import { v4 as uuidv4 } from "uuid";
import { message } from "ant-design-vue";
import { NodeIndexOutlined } from "@ant-design/icons-vue"
/**操作日志记录 */
import { operateLogs } from "@/common/logs";

const DefaultRangeCount = 12;
const DefaultLimitCount = 4;
const DefaultIntervalDays = 7;
const DefaultIntervalTimes = 1;

class QuotaGroupAlarmItem {
  _id: string | null;
  sensorId: string = "";
  sensorPlace: string = "";
  machineId: string = "";
  dataTypes: number[] = [];
  dataTypeNames: string[] = [];
  flag: string = ""
  enable: boolean = false;
  level1RangeCount: number = DefaultRangeCount;
  level1LimitCount: number = DefaultLimitCount;
  level1IntervalDays: number = DefaultIntervalDays;
  level1IntervalTimes: number = DefaultIntervalTimes;
  level2RangeCount: number = DefaultRangeCount;
  level2LimitCount: number = DefaultLimitCount;
  level2IntervalDays: number = DefaultIntervalDays;
  level2IntervalTimes: number = DefaultIntervalTimes
  level3RangeCount: number = DefaultRangeCount;
  level3LimitCount: number = DefaultLimitCount;
  level3IntervalDays: number = DefaultIntervalDays;
  level3IntervalTimes: number = DefaultIntervalTimes;
}


interface Props {
  sensor: any,
  machineId: string,
  expertCare?: boolean | null
}

let hasConfigs = computed(() => {
  return dataSource.value && dataSource.value.table && dataSource.value.table.length > 0;
})

const props = defineProps<Props>()
const emit = defineEmits(['search']);
watch(
    () => props.sensor,
    (newVal, oldVal) => {
      dataSource.value = props.sensor;
    },
);
onMounted(() => {
  console.log(props)
})
const dataSource = ref();

dataSource.value = props.sensor;

const columns = [
  {
    title: "数据类型",
    dataIndex: "dataTypesName",
  },
  {
    title: "开启报警",
    dataIndex: "enable",
  },
  {
    title: "可用报警",
    dataIndex: "level1Threshold",
  },
  {
    title: "警戒报警",
    dataIndex: "level2Threshold",
  },
  {
    title: "故障报警",
    dataIndex: "level3Threshold",
  }
];
const editSave = () => {
  let items: any = dataSource.value.table;
  let flag = true;
  let msg = "";
  for (let i = 0; i < items.length; i++) {
    let item: QuotaGroupAlarmItem = items[i];
    if (item.enable &&
        (!item.level1RangeCount || !item.level2RangeCount || !item.level3RangeCount ||
            !item.level1LimitCount || !item.level2LimitCount || !item.level3LimitCount ||
            !item.level1IntervalDays || !item.level2IntervalDays || !item.level3IntervalDays ||
            !item.level1IntervalTimes || !item.level2IntervalTimes || !item.level3IntervalTimes)) {
      msg = "开启报警的笔数、报警间隔必须配置";
      flag = false;
      break;
    }
  }
  if (!flag) {
    message.error(msg);
    return;
  }
  const config = {
    headers: {
      requestId: uuidv4(),
    },
  };

  console.log(props.expertCare)
  request.put("/api/sensors/quota-group-alarm", {
    expertCare: props.expertCare,
    machineId: props.machineId,
    sensorId: props.sensor.id,
    list: items
  }, config).then((res) => {
    if (res) {
      message.success("操作成功");
      emit("search");
      operateLogs("保存组合值报警规则，传感器：" + items[0].sensorPlace);
    }
  });
};

</script>
<style lang="less" scoped>
:deep(.ant-card-head) {
  padding-left: 0;
}

.cardHead {
  color: #1890ff;
  /* border-left:3px solid  #1890ff; */
  padding-left: 0;
  font-size: 18px;
}

.main {
  margin-top: 30px;
}

:deep(.ant-card-head) {
  background: #000A32 !important;
  border-bottom: 1px solid #0d53b7cc !important;
}

:deep(.ant-table-thead .ant-table-cell) {
  background: #03155C !important;
  border-right: 0 !important;
  color: white !important;
}

:deep(.ant-radio-button-wrapper) {
  background: #000A32 !important;
  color: #fff !important;
  border: 1px solid #0d53b7cc !important;
}
</style>
