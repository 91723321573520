<template>
    <a-config-provider :locale="language === 'English' ? enUS : zhCN">
    <div class="w-full">
        <div>
            <a-form :model="searchModel" name="horizontal_login" layout="inline" autocomplete="off">
                <a-form-item :label="langObj['报告时间']" name="status">
                    <a-range-picker v-model:value="searchModel.date" :disabled-date="disabledDate"
                        @calendarChange="onCalendarChange" />
                </a-form-item>


                <a-form-item>
                    <div class="flex items-center">
                        <div @click="reset" class="btn px-5 py-1 fff cursor-pointer ml-4">{{ langObj['重置'] }}</div>

                        <div @click="search" class="btn px-5 py-1 fff cursor-pointer ml-4"
                            style="background-color: #072499;">{{ langObj['查询'] }}</div>

                    </div>
                </a-form-item>


            </a-form>
        </div>

        <div class="box mt-4" style="width: 100%;">
            <a-table style="width: 100%;" size="small" :row-class-name="getRowClassName" :columns="columns"
                :data-source="data" :pagination="pagination" @change="handleTableChange">
                <template #bodyCell="{ column, text, record }">
                    <template v-if="column.dataIndex === 'cycle'">
                        <span>{{ getDate(record.date) }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'cycle2'">
                        <span>{{ getDate2(record.date, record.type) }}</span>
                    </template>
                    <template v-if="column.dataIndex === 'type'">
                        <span>{{ text == 3 ? langObj['周报'] : langObj['月报'] }}</span>
                    </template>

                    <template v-if="column.dataIndex === 'act'">
                        <a @click="details(record)" style="color: #00FFF4 !important">{{ langObj['查看详情'] }}</a>
                    </template>
                </template>
            </a-table>
        </div>

        <a-modal v-model:visible="visibleReport" :closable="true" okText="生成PDF" :bodyStyle="{ background: '#020F46' }"
            style="top: 30%" width="700px" :maskClosable="false" @ok="pdfFunc">
            <div id="pdfCompany" style="color: #fff;background: rgb(2, 15, 70);">
                <div class="text-center w-full mb-4" style="font-size: 24px">
                    {{ langObj["巡检周报"] }}
                </div>
                <div class="w-full flex mb-4 items-center">
                    <div class="flex-1 text-center">{{ weekReport.title }}</div>
                    <ShareAltOutlined @click="share" />
                </div>
                <div class="w-full text-center mb-2">{{ langObj['报告周期'] }}：{{ getDate(weekReport.date) || "--" }}</div>
                <div class="w-full text-center mb-2">{{ langObj['分析周期'] }}：{{ getDateCycle(weekReport.date) || "--" }}
                </div>
                <div class="w-full text-center mb-4">{{ langObj['报告时间'] }}：{{ weekReport.date || "--" }}</div>
                <div class="w-full text-center mb-2">{{ langObj['本周巡检设备数'] }}</div>
                <div id="chartA" class="mb-2" style="width: 100%;height: 200px;"></div>
                <div class="w-full text-center mb-4">{{ langObj['本周巡检设备健康总览'] }}</div>
                <div class="flex items-center justify-center mb-4">
                    <div id="chartB" style="width: 200px;height: 200px;"></div>
                    <div class="ml-12 mr-5">
                        <div class="flex items-center cursor-pointer">
                            <div style="width:14px; height:14px; background-color: #61c08f"></div>
                            <div class="ml-2"> {{ langObj['健康'] }}：</div>
                            <div>{{ conditionNum.n1 }}{{ getLanguage("台", language) }}</div>
                        </div>
                        <div class="flex items-center cursor-pointer mt-3">
                            <div style="width:14px; height:14px; background-color: #d2de49"></div>
                            <div class="ml-2"> {{ langObj['可用'] }}：</div>
                            <div>{{ conditionNum.n2 }}{{ getLanguage("台", language) }}</div>
                        </div>
                        <div class="flex items-center cursor-pointer mt-3">
                            <div style="width:14px; height:14px; background-color: #cd5f3b"></div>
                            <div class="ml-2"> {{ langObj['警戒'] }}：</div>
                            <div>{{ conditionNum.n3 }}{{ getLanguage("台", language) }}</div>
                        </div>
                        <div class="flex items-center cursor-pointer mt-3">
                            <div style="width:14px; height:14px; background-color: #921e37"></div>
                            <div class="ml-2"> {{ langObj['故障'] }}：</div>
                            <div>{{ conditionNum.n4 }}{{ getLanguage("台", language) }}</div>
                        </div>
                    </div>
                </div>


                <div class="w-full text-center mb-2">{{ langObj['设备详情'] }}</div>

                <div class="w-full mb-2">
                    <a-select v-model:value="searchModel.groupId" style="display:block;width: 200px;margin: 0 auto;"
                        allowClear :placeholder="langObj['请选择区域']">
                        <a-select-option v-for="(item, index) in groupList" :key="index" :value="item.id">
                            {{ item.name }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="mb-2" v-for="(item, index) in weekReport.inspectionReport" :id="'chartC' + index"
                    :key="index" style="width: 100%;height: 200px;">

                </div>

            </div>
        </a-modal>

        <a-modal :width="850" :maskClosable="false" v-model:visible="visible" :footer="null"
            :title="langObj['周报转发：复制链接转发，用户可在电脑端或手机端查看']">
            <div class="w-full flex items-center" style="color: #fff; font-size: 16px;">
                <div>{{ langObj['pc端链接'] }}: </div>
                <div class="ml-2" id="pc">{{ urlpc }}</div>
                <div class="flex-1"></div>
                <div class="cursor-pointer" @click="copyUrl('pc')">
                    <CopyOutlined />
                </div>
            </div>
            <div class="w-full flex items-center" style="color: #fff; font-size: 16px;">
                <div>{{ langObj['手机端链接'] }}: </div>
                <div class="ml-2" id="mb">{{ urlmb }}</div>
                <div class="flex-1"></div>
                <div class="cursor-pointer" @click="copyUrl('mb')">
                    <CopyOutlined />
                </div>
            </div>
        </a-modal>
    </div>
    </a-config-provider>
</template>

<script lang="ts" setup>
import { computed, defineComponent, onMounted, onUnmounted, reactive, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import * as echarts from 'echarts';
import request from "../../common/request";
import { message } from "ant-design-vue";
import { transformDate, transformDate2, transformDate3, getRealFactoryIdList } from "../../common/tools";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from 'dayjs';
import { langList } from "../../common/lang";
import { getLanguage } from "../../common/translate";
import { getFactoryName, getGroupListByFactory } from "../../common/tools";
import htmlToPdf from '../../common/htmlToPdf';
import enUS from "ant-design-vue/es/locale/en_US";
import zhCN from "ant-design-vue/es/locale/zh_CN";
let langObj: any = ref({})
let language: any = ref('Chinese')
const getLang = () => {
    language.value = localStorage.getItem('language') || 'Chinese'
    langObj.value = langList[language.value]
}
getLang();
let visibleReport = ref(false);
let visible = ref(false)
let urlpc: any = ref('')
let urlmb: any = ref('')
const route = useRoute()
let loadingFlag = ref(false)
const share = () => {
    urlpc.value = location.host + '/#/week-report/' + route.params.id + '?lang=' + language.value
    urlmb.value = location.host + '/#/week-report-mobile/' + route.params.id + '?lang=' + language.value
    visible.value = true
}
// 容器id="pdfCompany"
const pdfFunc = () => {
    loadingFlag.value = true;	// 动画加载事件
    // 调用htmlToPdf工具函数
    htmlToPdf.getPdf('周报',loadingFlag.value, 'a4' );
    // 定时器模拟按钮loading动画的时间
    setTimeout(() => {
        loadingFlag.value = false;
    }, 1000);
};


//复制功能
const copyUrl = (rf: any) => {
    let node = document.getElementById(rf)
    if (window.getSelection() && node) {
        window.getSelection()?.selectAllChildren(node);
    }
    let copy = document.execCommand("Copy");
    if (copy) {
        document.execCommand("Copy");
        message.success('复制完成')
    } else {
        message.warning('由于浏览器版本问题，复制失败，请手动复制~')
    }
}
const router = useRouter();
const pagination = computed(() => ({
    total: searchModel.value.total,
    current: searchModel.value.skip,
    pageSize: searchModel.value.take,
}));
let searchModel: any = ref({
    date: null,
    type: null,
    total: 0,
    skip: 1,
    take: 10,
    sort: 'date',
    order: -1,
    groupId: null
})
let conditionNum: any = ref({
    n1: 1,
    n2: 2,
    n3: 3,
    n4: 0,
});
let groupList: any = ref([]);
let weekReport: any = ref({
    inspectionReport: []
});
let weekReports: any = ref([]);

// 获取区域列表
const getGroupList = () => {
    groupList.value = getGroupListByFactory();
};
const columns = [
    {
        title: langObj.value['报告周期'],
        dataIndex: 'cycle',
    },
    {
        title: langObj.value['报告标题'],
        dataIndex: 'title',
    },

    {
        title: langObj.value['健康设备'] + (language.value === 'Chinese' ? '（台）' : '（unit）'),
        dataIndex: 'count1',
    },
    {
        title: langObj.value['可用设备'] + (language.value === 'Chinese' ? '（台）' : '（unit）'),
        dataIndex: 'count2',
    },
    {
        title: langObj.value['警戒设备'] + (language.value === 'Chinese' ? '（台）' : '（unit）'),
        dataIndex: 'count3',
    },
    {
        title: langObj.value['故障设备'] + (language.value === 'Chinese' ? '（台）' : '（unit）'),
        dataIndex: 'count4',
    },
    {
        title: langObj.value['分析周期'],
        dataIndex: 'cycle2',
    },
    {
        title: langObj.value['报告时间'],
        dataIndex: 'date',
    },
    {
        title: langObj.value['操作'],
        dataIndex: 'act',
    },
]

let data: any = ref([])

onMounted(() => {
    init()
})

const init = () => {

    search()
}

const search = () => {
    searchModel.value.skip = 1
    getData()
}

const reset = () => {
    searchModel.value = {
        ...searchModel.value,
        date: null,
        type: null,
    }
    search()
}
const getReportInfo = async (id: any) => {
    let config: any = {
        params: {
            id: id,
        },
        headers: {
            requestId: uuidv4(),
        },
    };
    let result = await request.get('/api/reports/info', config);
    groupList.value = [{
        name: '全部',
        sonID: '',
    }]
    if (result && result.data) {
        weekReport.value = result.data;

        if(language.value === 'English') {
            weekReport.value.title = weekReport.value.title.replace('周报', 'week report')
        }


        let groups = result.data.inspectionReport?.map((d: any) => ({
            sonID: d.groupId,
            name: d.groupName
        }))
        let groupIds = [...new Set(groups?.map((d: any) => d.sonID))]
        groupIds?.map((d: any) => {
            groupList.value.push({
                name: groups.find((p: any) => p.sonID === d)?.name,
                sonID: d
            })
        })

        setTimeout(() => {
            // weekReport.value.inspectionReport = []
            getMachineNum()
            getMachineSattaus();
            if (weekReport.value && weekReport.value.inspectionReport) {
                weekReports.value = JSON.parse(JSON.stringify(weekReport.value?.inspectionReport || []))
                weekReports.value.map((d: any, i: any) => {
                    getMachineTrend(d, 'chartC' + i)
                })
            }
            
        }, 500);


    }

};


// 本周巡检设备数
const getMachineNum = () => {
    console.log(weekReport.value.inspectionReport)
    if (!weekReport.value.inspectionReport) {
        return false
    }
    let inspectionReport = JSON.parse(JSON.stringify(weekReport.value.inspectionReport))
    let list = []
    inspectionReport.map((d: any) => {
        list = list.concat(d.trend)
    })
    list = list.sort((a: any, b: any) => a.date - b.date)
    let dates = [...new Set(list.map((d: any) => d.date).map((p: any) => p.substring(5, 10)))]
    let yData = []
    dates.map((d: any) => {
        yData.push(list.filter((p: any) => p.date.includes(d)).length)
    })
    let xData = dates
    if (!document.getElementById('chartA')) return
    let myChart = echarts.init(document.getElementById('chartA') as HTMLDivElement);



    let option = {
        grid: {
            left: '12%',
            right: '5%',
            top: '15%',
            bottom: '15%'
        },
        xAxis: {
            type: 'category',
            data: xData
        },
        yAxis: {
            type: 'value'
        },
        series: [{
            data: yData,
            type: 'line'
        }]
    }
    myChart.setOption(option)

    // this.$refs.chartA.init(echarts, (chart) => {
    // 	this.$refs.chartA.setOption(option);
    // });
};
// 本周健康设备总览
const getMachineSattaus = () => {
    if (!weekReport.value.inspectionReport) {
        return false
    }
    let inspectionReport = JSON.parse(JSON.stringify(weekReport.value.inspectionReport))
    conditionNum.value.n1 = inspectionReport.filter((p: any) => p.condition == 0)?.length
    conditionNum.value.n2 = inspectionReport.filter((p: any) => p.condition >= 1 && p.condition <= 2)?.length
    conditionNum.value.n3 = inspectionReport.filter((p: any) => p.condition >= 3 && p.condition <= 5)?.length
    conditionNum.value.n4 = inspectionReport.filter((p: any) => p.condition >= 6 && p.condition <= 7)?.length
    if (!document.getElementById('chartB')) return
    let myChart = echarts.init(document.getElementById('chartB') as HTMLDivElement);

    let DData = [{
        value: conditionNum.value.n1,
        name: '健康设备数：' + conditionNum.value.n1
    },
    {
        value: conditionNum.value.n2,
        name: '可用设备数：' + conditionNum.value.n2
    },
    {
        value: conditionNum.value.n3,
        name: '警戒设备数：' + conditionNum.value.n3
    },
    {
        value: conditionNum.value.n4,
        name: '故障设备数：' + conditionNum.value.n4
    },
    ]
    let option = {
        title: {
            text: "",
            left: "center",
        },
        tooltip: {
            show: false,
            trigger: "item",
            formatter: "{b}: {c} ({d}%)",
        },
        legend: {
            show: false,
        },
        graphic: {
            type: "text",
            left: "center",
            top: "center",
            style: {
                text: "总设备数:" + (conditionNum.value.n1 + conditionNum.value.n2 + conditionNum.value.n3 + conditionNum.value.n4),
                textAlign: "center",
                fontSize: 12,
                fill: "#fff",
            },
            tooltip: {
                show: false,
            },
        },
        series: [{
            name: "",
            type: "pie",
            radius: ["65%", "90%"],
            center: ["50%", "50%"],
            avoidLabelOverlap: false,
            label: {
                show: false,
                position: "center",
            },
            emphasis: {
                label: {
                    show: false,
                    fontSize: 12,
                    fontWeight: "bold",
                    formatter: "{b}\n{c} ({d}%)",
                },
            },
            labelLine: {
                show: false,
            },
            data: DData,
        },],
        color: ['#61c08f', '#d2de49', '#cd5f3b', '#921e37']
    };
    myChart.setOption(option)
    // this.$refs.chartB.init(echarts, (chart) => {
    //     this.$refs.chartB.setOption(option);
    // });
};

// 周报设备详情
const getMachineTrend = (data: any, id: any) => {
    let xData = []
    let yData = []
    if (!document.getElementById(id)) return
    let myChart = echarts.init(document.getElementById(id) as HTMLDivElement);

    data?.trend?.map((d: any) => {
        xData.push(d.date.substring(5, 16))
        let condition = d.condition || 0
        if (condition == 0) {
            yData.push(0)
        }
        if (condition >= 1 && condition <= 2) {
            yData.push(1)
        }
        if (condition >= 3 && condition <= 5) {
            yData.push(2)
        }
        if (condition >= 6 && condition <= 7) {
            yData.push(3)
        }
    })

    let option = {
        grid: {
            left: '12%',
            right: '5%',
            top: '15%',
            bottom: '15%'
        },
        xAxis: {
            type: 'category',
            data: xData,
            axisLine: {
                show: false
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10
                },
            }
        },
        yAxis: {
            type: 'value',
            min: 0, //給个最小值
            max: 3, //给个最大值
            splitNumber: 4, //必须加将坐标轴分割成指定数量的段数
            splitLine: {
                lineStyle: {
                    type: "dashed", //虚线
                    color: ['#921e37', '#cd5f3b', '#d2de49', '#61c08f']
                },
            },
            axisLabel: {
                textStyle: {
                    fontSize: 10
                },
                formatter: function (value) {
                    if (value == 3) {
                        return `{a|${'健康'}}`
                    }
                    if (value == 2) {
                        return `{b|${'可用'}}`
                    }
                    if (value == 1) {
                        return `{c|${'警戒'}}`
                    }
                    if (value == 0) {
                        return `{d|${'故障'}}`
                    }
                },
                rich: {
                    a: {
                        color: '#61c08f'
                    },
                    b: {
                        color: '#d2de49'
                    },
                    c: {
                        color: '#cd5f3b'
                    },
                    d: {
                        color: '#921e37'
                    },
                }
            },
        },
        series: [{
            data: yData,
            type: 'line'
        }]
    }

    // 画图
    myChart.setOption(option)
    // this.$refs[id][0].init(echarts, (chart) => {
    // 	this.$refs[id][0].setOption(option);
    // });
};





const getData = async () => {
    let type = searchModel.value.type ? [searchModel.value.type] : [3, 4]
    let config: any = {
        params: {
            skip: searchModel.value.skip,
            take: searchModel.value.take,
            sort: searchModel.value.sort,
            order: searchModel.value.order,
            factoryIds: getRealFactoryIdList(),
            type:[10]
        },
        headers: {
            requestId: uuidv4(),
        },
    };

    if (searchModel.value.date && searchModel.value.date.length === 2) {
        config.params.begin = transformDate2(new Date(searchModel.value.date[0]), 1);
        config.params.end = transformDate2(new Date(searchModel.value.date[1]), 2);
    }

    let result = await request.get('/api/external/reports', config)


    if (result && result.status === 200) {
        result.data.list.map((d: any) => {
            let count1 = 0, count2 = 0, count3 = 0, count4 = 0
            d.inspectionReport && d.inspectionReport.map((i: any) => {
                if (i.condition == 0) {
                    count1++
                }
                if (i.condition >=1 && i.condition<=2) {
                    count2++
                }
                if (i.condition >=3 && i.condition <=5) {
                    count3++
                }
                if (i.condition >=6 && i.condition <=7) {
                    count4++
                }
            })
            d.count1 = count1
            d.count2 = count2
            d.count3 = count3
            d.count4 = count4

            if (language.value === 'English') {
                d.title = d.title.replace('周报', 'weekly report')
            }
        })


        data.value = result.data.list
        searchModel.value.total = result.data.count
    }
}

const details = (ev: any) => {
    // router.push('/report/mwDetail/' + ev.id)
    visibleReport.value = true;
    getGroupList()
    getReportInfo(ev.id)
}



const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // 处理分页数据
    if (Object.keys(sorter).length) {
        var order = sorter.order ? (sorter.order == "descend" ? -1 : 1) : 0;
        searchModel.value.sort = sorter.field
        searchModel.value.order = order
    }
    // 处理分页数据
    searchModel.value.skip = pagination.current
    searchModel.value.take = pagination.pageSize
    getData();
};

const getDate = (date: any) => {
    date = new Date(date)
    let year = date.getFullYear()
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)
    let w = date.getDay(),
        d = date.getDate();
    if (w == 0) {
        w = 7;
    }

    if (language.value == 'Chinese') {
        return year + '年' + month + '月' + ' ' + '第' + Math.ceil((d + 6 - w) / 7) + '周'
    } else {
        let monList = [
            { label: 'January', value: '01' },
            { label: 'February', value: '02' },
            { label: 'March', value: '03' },
            { label: 'April', value: '04' },
            { label: 'May', value: '05' },
            { label: 'June', value: '06' },
            { label: 'July', value: '07' },
            { label: 'August', value: '08' },
            { label: 'January', value: '09' },
            { label: 'October', value: '10' },
            { label: 'November', value: '11' },
            { label: 'December', value: '12' },
        ]
        return monList.find((p: any) => p.value == month)?.label + ' ' + year + ',' + 'Week' + Math.ceil((d + 6 - w) / 7)
    }


}
const getDateCycle = (date: any) => {
    let time = new Date(date)
    let startDate = time.getTime() - 1000 * 60 * 60 * 24 * 7
    return `${getSimpleDate(startDate)} - ${getSimpleDate(time)}`
};
const getSimpleDate = (date: any) => {
    let time = new Date(date)
    let year = time.getFullYear();
    let mon = (time.getMonth() + 1) <= 9 ? '0' + (time.getMonth() + 1) : time.getMonth() + 1;
    let day = time.getDate() <= 9 ? '0' + time.getDate() : time.getDate();
    return year + '/' + mon + '/' + day
};
const getDate2 = (ev: any, type: any) => {
    let day = type === 3 ? 7 : 30
    let date = new Date(ev).getTime() - (1000 * 60 * 60 * 24 * day)
    let begin = transformDate3(new Date(date))
    return begin + ' ~ ' + transformDate3(ev)
}

const getRowClassName = (record: any, index: any) => {
    // 根据索引设置不同的类名
    return index % 2 === 0 ? 'even-row' : 'odd-row';
}

const dates = ref<any>();

const disabledDate = (current: Dayjs) => {
    if (!dates.value || (dates.value as any).length === 0) {
        return false;
    }
    const tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 30;
    const tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 30;
    return tooEarly || tooLate;
};

const onCalendarChange = (val: any) => {
    dates.value = val;
};


</script>


<style lang="less" scoped>
.box {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.btn {
    border: 1px solid #0d53b7cc;
    background: #07249933;
}

.fff {
    color: #fff
}

/deep/ .ant-table {
  background: #020f46;
  color: #fff;
}

/deep/ .ant-table-tbody>tr.ant-table-placeholder:hover>td {
  background: transparent;
}

/deep/ .ant-empty-normal {
  color: #fff;
}

/deep/ .ant-descriptions-item-content {
  color: #fff;
}

/deep/ .ant-table-tbody>tr>td {
  border-bottom: 0px solid #fff;
}

/deep/ .ant-select-clear span {
  background-color: #000;
}

/deep/ .ant-upload.ant-upload-select-picture-card {
  background: #021766;
}

/deep/ .ant-select-multiple .ant-select-selection-item {
  background: #07249933 !important;
}

/deep/ .ant-table-tbody>tr>td {
    border-bottom: 0px solid #fff
}

/deep/ .ant-picker-input>input {
    color: #fff;
}

/deep/ .ant-picker-clear span {
    background: #000 !important;
}

/deep/ .ant-drawer-close span svg {
    color: #000 !important;
}

/deep/ .anticon-close svg {
    color: #000 !important;
}

/deep/ .ant-select-clear span {
    background-color: #000;
}
</style>
